/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Box, IconButton, Skeleton, Typography,
} from '@mui/material';
import ButtonApp from 'Shared/Button';
import { toast } from 'react-toastify';
import { saveManualFraisCalculer, ventilation } from 'Components/Import/importServices';
import DialogCmp from 'Shared/Dialog';
import HelpIcon from '@mui/icons-material/Help';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Input from 'Shared/Input/v2';
import useForm from 'Shared/Hooks/useForm';
import { useStylesInvoiceDetails } from './style';

const InvoiceDetails = ({
  invoices, loading, totalFrais, totalTH, folderId, folderName, callbackInvoices,
}) => {
  const classes = useStylesInvoiceDetails();
  const [items, setItems] = useState([]);
  const [openValidation, setOpenValidation] = useState(false);
  const { values, onChange } = useForm({ isEdit: false, reference: null, fraisCalculer: 0 });

  useEffect(() => {
    if (invoices?.length > 0) {
      setItems(
        invoices.map((invoice) => ({
          ...invoice,
          totalFrais: typeof totalTH === 'number' && typeof invoice.totalHt === 'number'
            ? Math.round((100 * totalFrais * invoice.totalHt) / totalTH) / 100 : 0,
        })),
      );
    }
  }, [invoices]);

  const handleVentilation = async () => {
    const ventilationObj = items.map(({ reference, manualFraisCalculer, totalFrais: frais }) => {
      const realFrais = manualFraisCalculer || frais;
      return { reference, frais: realFrais };
    });
    try {
      const dataRep = await ventilation(folderId, ventilationObj);
      console.log(dataRep);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOpenValidation(false);
      callbackInvoices({ page: 0, size: 10 });
      toast.success('Les factures ont été ventilés avec succès');
    }
  };

  const onHandleEdit = (value, reference) => {
    onChange({ target: { name: 'isEdit', value: !values.isEdit } });
    if (!values.isEdit) {
      onChange({ target: { name: 'fraisCalculer', value } });
      onChange({ target: { name: 'reference', value: reference } });
    }
  };
  const onHandleSaveManual = async () => {
    try {
      await saveManualFraisCalculer(folderId, values.reference, values.fraisCalculer);
      const updatedInvoices = items.map((invoice) => {
        if (invoice.reference === values.reference) {
          return { ...invoice, manualFraisCalculer: values.fraisCalculer };
        }
        return invoice;
      });
      setItems(updatedInvoices);
      toast.success('Frais calculer sauvegarder avec succès');
    } catch (error) {
      toast.error(error.message);
    } finally {
      onHandleEdit();
    }
  };
  return (
    <Box sx={{
      justifyContent: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openValidation}
        handleClose={() => setOpenValidation(false)}
        onHandleSubmit={handleVentilation}
        labelKo="Non"
        labelOk="Oui"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box sx={{
            borderRadius: '50%',
            width: '65px',
            height: '65px',
            backgroundColor: '#00B894',
            margin: 'auto',
            color: '#FFF',
            display: 'flex',
          }}
          >
            <HelpIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography sx={{
            marginTop: '20px',
            textAlign: 'center',
            font: 'normal 600 18px Montserrat',
          }}
          >
            Êtes-vous sûr ventilé les frais pour ce document
            {' '}
            {folderName}
            {' '}
            ?
          </Typography>
        </Box>
      </DialogCmp>
      <Box className={classes.header}>
        <Typography className={classes.title}>FACTURE</Typography>
        <Typography className={classes.title}>MONTANT</Typography>
        <Typography className={classes.title}>FRAIS CALCULER</Typography>
      </Box>
      <Box className={classes.body}>
        {loading
          ? (
            <>
              <Skeleton animation="wave" variant="rounded" sx={{ width: '100%' }} height={40} />
              <Skeleton animation="wave" variant="rounded" sx={{ mt: '5px', width: '100%' }} height={40} />
              <Skeleton animation="wave" variant="rounded" sx={{ mt: '5px', width: '100%' }} height={40} />
            </>
          )
          : items.map((invoice, index) => (
            <Box className={classes.invoice} key={`${index}-detail-invoice-ref-${invoice.reference}`}>
              <Typography className={classes.invoiceText}>{invoice.reference || 'vide'}</Typography>
              <Typography className={classes.invoiceText}>{invoice.totalHt ? `${invoice.totalHt} Dh` : '0 Dh'}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 / 3 }}>
                {values.isEdit && values.reference === invoice.reference ? (
                  <>
                    <Input
                      value={values.fraisCalculer}
                      onChange={onChange}
                      margin="dense"
                      type="number"
                      style={{
                        width: '90px', border: '1px solid #C5C5C5', marginRight: 0, marginTop: 0, marginBottom: 0,
                      }}
                      name="fraisCalculer"
                    />
                    <IconButton
                      onClick={onHandleSaveManual}
                      className={[classes.btnIcon, classes.btnIcon2]}
                      size="large"
                    >
                      <SaveIcon size="small" />
                    </IconButton>
                    <IconButton
                      onClick={onHandleEdit}
                      className={[classes.btnIcon, classes.btnIcon3]}
                      size="large"
                    >
                      <CloseIcon size="small" />
                    </IconButton>
                  </>
                )
                  : (
                    <>
                      <Typography className={classes.invoiceText} style={{ flex: 'auto', textAlign: 'right' }}>
                        {`${invoice.manualFraisCalculer || invoice.totalFrais} Dh`}
                      </Typography>
                      <IconButton
                        size="large"
                        onClick={() => onHandleEdit(
                          invoice.manualFraisCalculer || invoice.totalFrais, invoice.reference,
                        )}
                        disabled={values.isEdit}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
              </Box>
            </Box>
          ))}
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <ButtonApp label="VENTILLÉ" onClick={() => setOpenValidation(true)} style={{ marginTop: 10, width: 110 }} />
      </Box>
    </Box>
  );
};

export default InvoiceDetails;
