/* eslint-disable max-len */
import {
  Box,
  Typography,
} from '@mui/material';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import { getClients } from 'Components/Recovery/RecoveryServices';
import RecoveryView from 'Components/Recovery/RecoveryView';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import { useSelector } from 'react-redux';
import { makeSelectCities, makeSelectZones } from 'selectors';
import { defaultMessage } from '../PaymentServices';
import { columns } from './constants';

const Clients = () => {
  const {
    state: {
      referencial: { clients: clientList },
    },
  } = useContext(ReferencialContext);
  const [selectedClient, setSelectedClient] = useState(null);
  const [openClient, setOpenClient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState({ content: [] });
  const [values, setValues] = useState({});
  const cities = useSelector(makeSelectCities());
  const zones = useSelector(makeSelectZones());
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const getClientList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getClients(sort, {
          city: values.city,
          code: values?.client?.code,
          zone: values?.zone,
        });
        setClientData(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setClientData, setLoading, values],
  );
  const handleSelectClient = (client) => () => {
    setSelectedClient(client);
    setOpenClient(true);
    // history.push(`/client/${client.code}`);
  };
  const onCloseClient = () => {
    setOpenClient(false);
    setSelectedClient(null);
  };
  const clients = useMemo(
    () => clientData.content.map((client) => ({
      ...client,
      id: client.code,
      click: handleSelectClient(client),
    })),
    [clientData.content],
  );

  return (
    <Box sx={{ padding: '38px 16px 25px 25px' }}>
      {loading && <GlobalLoader size="100vh" />}
      <Typography sx={{
        font: 'normal normal 600 30px Montserrat',
        color: '#000000',
        mb: 2,
      }}
      >
        Gestion des clients
      </Typography>
      {openClient && <RecoveryView handleClose={onCloseClient} clientCode={selectedClient?.code} />}
      <Box
        style={{
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          background: '#E9EBED',
          borderRadius: '12px',
          marginBottom: 16,
        }}
      >
        <AutoCompleteCmp
          options={clientList || []}
          isPrimaryTheme
          label="Client"
          onChange={handleInputChange('client')}
          value={values.client}
          style={{ maxWidth: 250 }}
          size="small"
          getOptionLabel={(option) => (option.code ? `${option.code}: ${option.name}` : '')}
          filterOptions={(options, v) => options.filter(
            (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                    || option.code?.toLowerCase().startsWith(v.inputValue.toLowerCase()),
          )}
        />
        <AutoCompleteCmp
          options={zones || []}
          onChange={handleInputChange('zone')}
          size="small"
          label="Zone"
          isPrimaryTheme
          style={{ maxWidth: 250 }}
          getOptionLabel={(option) => option}
          filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          value={values.zone}
        />
        <AutoCompleteCmp
          options={cities || []}
          onChange={handleInputChange('city')}
          size="small"
          label="Ville"
          isPrimaryTheme
          style={{ maxWidth: 250 }}
          getOptionLabel={(option) => option}
          filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          value={values.city}
        />
      </Box>
      <EnhancedTable
        getData={getClientList}
        rows={clients}
        headCells={columns}
        count={clientData.totalElements}
        rowsPerPageOptions={[20, 30, 40]}
      />
    </Box>
  );
};

export default Clients;
