import { Box, Paper, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import UserContext from 'Shared/UserContext';
// import BlockedOrders from '../BlockedOrders';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import CustomColumn from 'Shared/CustomColumn';
import useMultiSelect from 'Shared/Hooks/useMultiSelect';
import useStyles from './style';
import RecoveryList from '../RecoveryList/RecoveryList';
import { clientCustomColumns } from '../RecoveryList/constants';

const RecoveryListTabs = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const [openAddClient, setOpenAddClient] = useState(false);
  const { selectedItem, onSelectOne, extendedColumns } = useMultiSelect('id', clientCustomColumns);
  const isRECOUVREMENT = ['RECOUVREMENT'].includes(user.role);
  const classes = useStyles();
  const onHandleAddClient = (nextState) => {
    if (nextState === undefined) {
      setOpenAddClient(!openAddClient);
    } else {
      setOpenAddClient(nextState);
    }
  };
  return (
    <Paper elevation={0} classes={{ root: classes.paper }}>
      <Box sx={{ display: 'flex', mb: 2.5 }}>
        <Typography sx={{ font: 'normal 600 24px Montserrat', mr: 'auto' }}>
          Liste Des Clients
        </Typography>
        <RenderOnDemandThenHide visible={isRECOUVREMENT}>
          <CustomColumn
            customColumns={clientCustomColumns}
            setSelectedCustomColumns={onSelectOne}
            selectedCustomColumns={selectedItem}
          />
          <ButtonApp
            label="Ajouter"
            startIcon={<AddIcon />}
            onClick={() => onHandleAddClient(!openAddClient)}
            style={{ marginLeft: 10 }}
          />
        </RenderOnDemandThenHide>
      </Box>
      <RecoveryList
        isRECOUVREMENT={isRECOUVREMENT}
        extendedColumns={extendedColumns}
        openClient={openAddClient}
        onCloseClientView={onHandleAddClient}
      />
    </Paper>
  );
  /*
  return (
      {tab === 1 && !isCommercialOrSales && !isSAV && <BlockedOrders />}
  ); */
};

export default RecoveryListTabs;
