import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { calculateDueDate, minDate } from 'Utils/ulilities';

export const TYPES = ['FA',
  'MF',
  'SPECIALE',
];
export const BLOCKING = ['Manuel',
  'Automatique',
];
export const CONTACTS = ['Fixe',
  'Mobile',
  'Email',
  'Whatsapp',
];
export const NOTESTYPE = ['Commentaire',
  'Attachement',
];

export const TABS_ID = {
  REG: 0, NO_REG: 1, IMP: 2, IN_REG: 3,
};

const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '16px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 14px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  marginLeft: 5,
};

export const columnsRegDetails = [
  {
    id: 'date',
    label: 'DATE FACTURE',
    format: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  {
    id: 'commandeId',
    label: 'NUMÉRO FACTURE',
  },
  {
    id: 'amount',
    label: 'Montant TTC',
  },
  {
    id: 'dateEcheance',
    label: 'DATE D’ÉCHÉANCE',
    format: (date) => (date ? format(calculateDueDate(date), 'dd/MM/yyyy') : '-'),
  },
];
export const columnsReg = [
  {
    id: 'monthId',
    label: 'MOIS',
  },
  {
    id: 'amount',
    label: 'Montant',
  },
  {
    id: 'dateEcheance',
    label: 'DATE D’ÉCHÉANCE',
    format: (date) => (date ? format(calculateDueDate(date), 'dd/MM/yyyy') : '-'),
  },
];

export const columnsImpDetails = [
  {
    id: 'date',
    label: 'DATE FACTURE',
    format: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  {
    id: 'commandeId',
    label: 'NUMÉRO FACTURE',
  },
  {
    id: 'amount',
    label: 'Montant TTC',
  },
];
export const columnsImp = [
  {
    id: 'details',
    label: 'DATE RECEPTION',
    format: (value = []) => (value.length > 0 ? format(minDate(value, 'date'), 'dd/MM/yyyy') : ''),
  },
  {
    id: 'details',
    label: 'MODE PAIEMENT',
    format: (value = []) => (value.length > 0 ? value[0].type : ''),
  },
  {
    id: 'amount',
    label: 'MONTANT',
  },
  {
    id: 'rgDateEcheance',
    label: 'DATE ECHEANCE',
    format: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : '-'),
  },
  {
    id: 'rgImpaye',
    label: 'DATE IMPAYE',
    format: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : '-'),
  },
];

export const columnsINDetails = [
  {
    id: 'date',
    label: 'DATE FACTURE',
    format: (date) => format(new Date(date), 'dd/MM/yyyy'),
  },
  {
    id: 'commandeId',
    label: 'NUMÉRO FACTURE',
  },
  {
    id: 'amount',
    label: 'Montant TTC',
  },
];
export const columnsIN = [
  {
    id: 'details',
    label: 'DATE RECEPTION',
    format: (value = []) => (value.length > 0 ? format(minDate(value, 'date'), 'dd/MM/yyyy') : ''),
  },
  {
    id: 'details',
    label: 'MODE PAIEMENT',
    format: (value = []) => (value.length > 0 ? value[0].type : ''),
  },
  {
    id: 'reference',
    label: 'N° PIECE',
  },
  {
    id: 'amount',
    label: 'MONTANT',
  },
  {
    id: 'dateEcheance',
    label: 'DATE ECHEANCE',
    format: (date) => (date ? format(new Date(date), 'dd/MM/yyyy') : '-'),
  },
];

export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
];
