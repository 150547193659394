import React from 'react';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';

const Identification = ({
  client, editMode, onChange, onClose,
}) => {
  if (editMode) {
    return (
      <Edit fields={Fields} client={client} onChange={onChange} onClose={onClose} />);
  }
  return (<View fields={Fields} client={client} />);
};

export default Identification;
