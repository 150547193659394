import React from 'react';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { format } from 'date-fns';
import Input from 'Shared/Input/v2';
import EditSaveActions from 'Shared/Button/EditSaveActions';
import { roundPrice } from 'Utils/ulilities';

export const PAYEMENT_STATUSES = [
  { code: 'NON_ATTACHE', label: 'Non attachée' },
  { code: 'PARTIELLEMENT_ATTACHE', label: 'partiellement attachée' },
  { code: 'ATTACHE', label: 'Totalement attachée' },
];
export const getLibelleName = (libelle) => {
  const label = PAYEMENT_STATUSES.find((ps) => ps.code === libelle);
  return label?.label || '-';
};
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#e4e7ea',
  borderBottom: 'none',
  textTransform: 'uppercase',
};

const TitleStyle2 = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '20px 8px',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = (onHandleActions) => [
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>
        {value ? `${value.code} - ${value.name}` : '--'}
      </Typography>
    ),
    // order: 'client.code',
  },
  {
    id: 'date',
    label: 'DATE DU RÈGLEMENT',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
    // order: 'receptionDate',
  },
  {
    id: 'status',
    label: 'LIBELLÉ',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{getLibelleName(value)}</Typography>,
  },
  {
    id: 'bank',
    label: 'Banque',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'MODE DE RÈGLEMENT',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'reference',
    label: 'NUMÉRO DE PIÈCE',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    // order: true,
  },
  {
    id: 'dueDate',
    label: 'DATE D’ÉCHÉANCE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
    // order: 'receptionDate',
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{`${value} Dh`}</Typography>,
  },
  {
    id: 'dateImpaye',
    label: 'DATE D’IMPAYÉ',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
    // order: 'receptionDate',
  },
  {
    id: 'sold',
    label: 'SOLD',
    style: TitleStyle,
    render: ({ row }) => <Typography sx={defaultColumnStyle}>{`${row.amount - (row.attachedAmount || 0)}  Dh`}</Typography>,
  },
  {
    id: 'codeJournal',
    label: 'CODE JOURNAL',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <IconButton aria-label="actions" size="large" onClick={onHandleActions(row)}>
        <MoreHorizIcon />
      </IconButton>
    ),
  },
];

export const columnsDetails = (
  onHandleActions, isEdit, nextPaidAmount, selectedId, onChange, onClose, onSavePaidAmount,
) => [
  {
    id: 'commande',
    label: 'DATE',
    style: TitleStyle2,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>
        {value?.date && format(new Date(value.date), 'dd/MM/yyyy')}
      </Typography>
    ),
  },
  {
    id: 'commande',
    label: 'NUMÉRO FACTURE',
    style: TitleStyle2,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value?.reference}</Typography>
    ),
  },
  {
    id: 'solde',
    label: 'SOLDE',
    style: TitleStyle2,
    render: ({ row }) => (
      <Typography sx={defaultColumnStyle}>
        {`${roundPrice((row.amount || 0) - (row.commande?.paidAmount || 0))}Dhs`}
      </Typography>
    ),
  },
  {
    id: 'commande',
    label: 'MONTANT PAYÉ',
    style: TitleStyle2,
    render: ({ row, value }) => {
      if (isEdit && row.id === selectedId) {
        return (
          <>
            <Input name="paidAmount" onChange={onChange} value={nextPaidAmount} />
            <EditSaveActions
              isEdit
              onHandleClose={onClose}
              onHandleSave={onSavePaidAmount}
            />
          </>
        );
      }
      return (<Typography sx={defaultColumnStyle}>{`${value?.paidAmount || 0}Dhs`}</Typography>);
    },
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle2,
    render: ({ row }) => (
      <IconButton aria-label="actions" size="large" onClick={onHandleActions(row)}>
        <MoreHorizIcon />
      </IconButton>
    ),
  },
];

export const columnsAttachOrders = (onChange, toAttach, selectedIds = []) => [
  {
    id: 'date',
    label: 'date facture',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{format(new Date(value), 'dd/MM/yyyy')}</Typography>
    ),
  },
  {
    id: 'reference',
    label: 'N° de facture',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Sold',
    style: TitleStyle,
    render: ({ row, value }) => (
      <Typography sx={defaultColumnStyle}>
        {roundPrice((value || 0) - (row.paidAmount || 0))}
      </Typography>
    ),
  },
  {
    id: 'paidAmount',
    label: 'montant réglé',
    style: TitleStyle,
    render: ({ row, value }) => (
      <Input
        disabled={!selectedIds.includes(row.id)}
        name={row.id}
        onChange={onChange}
        value={value}
        type="number"
        style={{ border: selectedIds.includes(row.id) && '1px solid #007BC6' }}
      />
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle2,
    render: ({ row }) => (
      <IconButton
        aria-label="actions"
        size="large"
        onClick={() => toAttach(row.id)}
        sx={{
          backgroundColor: '#FFF',
          borderRadius: 1.5,
          padding: 0.5,
          boxShadow: '0px 4px 10px #108CD629',
          marginLeft: 1.1,
          color: selectedIds.includes(row.id) ? '#007BC6' : '#00B894',
          border: '1px solid #00B894',
        }}
      >
        {selectedIds.includes(row.id) ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
    ),
  },
];
