import {
  Box, CardMedia, Chip, IconButton, Tooltip, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment } from 'react';
import OptionIcon from 'Images/option.svg';
import { toast } from 'react-toastify';
import DoneIcon from '@mui/icons-material/Done';
import Pointer from 'Images/pointer.svg';
import { TYPES_COMMANDES } from '../constants';
import { Info } from './Icons';

export const DOCUMENTS = {
  3: 'Bon de livraison',
  4: 'Bon de retour',
  6: 'Facture',
  7: 'Facture de retour',
};
export const downloadFromLink = (url, filename) => (event) => {
  event.stopPropagation();
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(() => {
      toast.error('Erreur Système');
    });
};
export const mapOrderStatus = {
  NON_ASSIGNEE: { label: 'Non assignée', class: 'NotAssignedStatus' },
  EN_ATTENTE_PICKAGE: { label: 'En attente', class: 'awaitingPicking' },
  EN_ATTENTE_VALIDATION: { label: 'Validation', class: 'awaitingValidation' },
  EN_COURS_PICKAGE: { label: 'En cours', class: 'pickingInprogress' },
  CLOTUREE: { label: 'Clôturée', class: 'closed' },
  BL_GENERE: { label: 'BL généré', class: 'invoiced' },
  MANQUE: { label: 'Manque', class: 'manque' },
  STOCK: { label: 'Stock', class: 'stock' },
  IMPRIME: { label: 'Imprimé', class: 'closed' },
  PIQUE: { label: 'Piqué', class: 'closed' },
};
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
const constructAnomalies = (row) => {
  const anomalies = [];
  if (row.quantityDifference) {
    anomalies.push('Les différences de quantité');
  }
  if (row.priceAndDiscountDifference) {
    anomalies.push('Les différences de prix');
    anomalies.push('Les différences de remise');
  }
  if (row.falseReference) {
    anomalies.push('Les fausse références');
  }
  if (row.expeditionModeChange || row.expeditionmodeChange) {
    anomalies.push('A4: Les différences de mode d\'expedition');
  }
  if (row.paymentModeChange) {
    anomalies.push('A5: Les différences de mode de paiement');
  }
  return anomalies;
};
export const TYPES = [
  { id: 1, label: 'BC', style: { color: '#FFF', backgroundColor: 'blue' } },
  { id: 3, label: 'BL', style: { color: '#FFF', backgroundColor: 'green' } },
  { id: 4, label: 'BR', style: { color: '#FFF', backgroundColor: 'red' } },
  { id: 6, label: 'FA', style: { color: '#FFF', backgroundColor: 'gray' } },
  { id: 7, label: 'FC', style: { color: '#FFF', backgroundColor: 'fuchsia' } },
];
export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'commercial',
    label: 'Commercial',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {format(new Date(value), 'dd/MM/yyyy hh:mm:ss')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => value && (
    <Chip
      label={TYPES.find((t) => t.id === value)?.label}
      style={TYPES.find((t) => t.id === value)?.style}
    />
    ),
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'city',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amountTTC',
    label: 'Montant TTC',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amountHT',
    label: 'Montant HT',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'statusP',
    label: 'Statut du règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
];
const getColorFromRowValue = (row) => {
  if (row.orange) return { color: '#FFA500' };
  if (row.red) return { color: 'red' };
  if (row.green) return { color: 'green' };
  if (row.noColor) return {};
  return {};
};
const parentRowStyle = {
  padding: '2px 5px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  height: 30,
};
export const columnsSAV = [
  {
    id: 'noAnomaly',
    label: '',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        {!value && (
          <IconButton
            variant="contained"
            size="small"
            style={{
              background:
                'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD650',
              borderRadius: 12,
              height: 32,
              width: 32,
            }}
            onClick={row.handleNoAnomaly}
          >
            <CardMedia
              component="img"
              src={Pointer}
              style={{ transform: 'rotate(-45deg)', height: 25, width: 20 }}
            />
          </IconButton>
        )}
        {value && (
          <IconButton
            variant="contained"
            size="small"
            style={{
              background: '#00B894 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD650',
              border: '1px solid #00B894',
              borderRadius: 12,
            }}
          >
            <DoneIcon style={{ color: 'white' }} />
          </IconButton>
        )}
      </Box>
    ),
  },
  {
    id: 'date',
    label: 'Date et heure',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography
        style={{ ...defaultColumnStyle, mr: '9px', ...getColorFromRowValue(row) }}
        display="inline"
      >
        {format(new Date(value), 'dd/MM/yyyy hh:mm:ss')}
      </Typography>
    ),
    order: 'date',
  },
  {
    id: 'type',
    label: 'Type de la pièce',
    style: TitleStyle,
    parentRowStyle: { ...parentRowStyle, width: 20 },
    render: ({ value }) => value && (
    <Chip
      label={(
        <div style={{ font: 'normal normal 600 14px/18px Montserrat' }}>
          {TYPES_COMMANDES.find((t) => t.id === value)?.label}
        </div>
          )}
      style={TYPES_COMMANDES.find((t) => t.id === value)?.style}
    />
    ),
    order: 'type',
  },
  {
    id: 'statusO',
    label: 'Statut OPS',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography
        className={row.statusClass}
        style={{ ...defaultColumnStyle, background: 'transparent' }}
      >
        {value || '-'}
      </Typography>
    ),
    order: 'orderStatus.status',
  },
  {
    id: 'currentReference',
    label: 'Numéro de la pièce',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography
        style={{
          ...defaultColumnStyle,
          ...getColorFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
    order: 'reference',
  },
  {
    id: 'client',
    label: 'Code et nom du client',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Tooltip title={value}>
        <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row), width: 90 }}>
          {value}
        </Typography>
      </Tooltip>
    ),
    order: 'client.name',
  },
  {
    id: 'expedition',
    label: 'Mode d’expédition',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
    order: 'expedition.name',
  },
  {
    id: 'numExpedition',
    label: 'Numéro d’expédition',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row, value }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),

    order: 'paymentMode.name',
  },
  {
    id: 'commercial',
    label: 'Commercial',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...defaultColumnStyle,
          ...getColorFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Montant TTC',
    style: TitleStyle,
    parentRowStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'restAmount',
    label: 'le reste non réglé',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {row.amountTTC - row.paidAmount}
      </Typography>
    ),
  },
  {
    id: 'reliquat',
    label: 'Reliquat',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {row.reliquat ? 'Oui' : 'Non'}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
        {(constructAnomalies(row).length > 0) && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#F86060',
                '& .MuiTooltip-arrow': {
                  color: '#F86060',
                },
              },
            },
          }}
          title={(
            <Typography
              sx={{
                font: 'normal normal 500 19px/30px Montserrat !important',
                color: '#FFFFFF',
              }}
            >
              {constructAnomalies(row)?.map((anomaly) => (
                <Fragment key={anomaly}>
                  {anomaly}
                  <br />
                </Fragment>
              ))}
            </Typography>
          )}
          arrow
          placement="top-end"
        >
          <Box>
            <Info />
          </Box>
        </Tooltip>
        )}
        {constructAnomalies(row).length <= 0 && <Info color="#BABABA" />}
        <Box
          style={{
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 30,
            width: 30,
            cursor: 'pointer',
          }}
          onClick={row.openPopOver(row)}
        >
          <CardMedia component="img" src={OptionIcon} style={{ width: 20 }} />
        </Box>

      </Box>
    ),
  },
];

const flagReliquatFromRowValue = (row) => {
  if (row.reliquat) return { color: 'blue' };
  return {};
};
export const columnOrderControlDetail = [
  {
    id: 'reference',
    label: 'Référence',
    style: { ...TitleStyle, width: '10%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
    order: 'reference',
  },
  {
    id: 'designation',
    label: 'Désignation',
    style: { ...TitleStyle, width: '30%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'pickedQuantity',
    label: 'QTE-PIQ (OPS)',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'waitingQuantity',
    label: 'QTE-ATT (OPS)',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'totalQuantity',
    label: 'QTE-TOT (OPS)',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'inspectedQuantity',
    label: 'QTE-INS (OPS)',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'statusOps',
    label: 'Statut (OPS)',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'qteBC',
    label: 'Qté BC',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'remiseBC',
    label: 'Remise BC',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'priceBC',
    label: 'Prix BC',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'qteBL',
    label: 'Qte BL',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'remiseBL',
    label: 'Remise BL',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'priceBL',
    label: 'Prix BL',
    style: { ...TitleStyle, width: '6%' },
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...flagReliquatFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
];
