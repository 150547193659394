import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from 'Shared/UserContext';
import { deleteNote } from '../ClientServices';
import { NOTESTYPE } from '../constants';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';
import NoteSAV from './NoteSAV';
import AddNoteSAV from './AddNoteSAV';

const Note = ({
  client, getClient, editMode = false, onChange, onClose,
}) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isSAV = user.role === 'SAV';
  const [editNote, setEditNote] = useState(null);

  useEffect(() => {
    if (!editMode) {
      getClient();
      setEditNote(null);
    }
  }, [editMode]);

  const onHandleEdit = (note) => {
    const { id, attachmentUrl, comment } = note;
    const noteType = attachmentUrl ? NOTESTYPE[1] : NOTESTYPE[0];
    setEditNote({
      noteId: id,
      file: attachmentUrl,
      comment,
      code: client.code,
      noteType,
    });
  };

  const onHandleDelete = async (noteId) => {
    await deleteNote(noteId);
    getClient();
  };

  return (
    <>
      {!isSAV && (
        <>
          {editMode && (
            <Edit
              fields={[]}
              client={editNote || client}
              currentMode="addNote"
              onChange={onChange}
              onClose={onClose}
            />
          )}
          <View
            fields={Fields}
            client={client}
            currentMode="addNote"
            handleDelete={onHandleDelete}
            handleEdit={onHandleEdit}
          />
        </>
      )}
      {isSAV && (
        <Box style={{
          overflow: 'auto',
          // height: '248px',
          // maxHeight: '248px',
          backgroundColor: '#FFF',
          '&::-webkit-scrollbar': {
            width: 10,
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
        >
          {client?.notes?.map((note) => (
            <NoteSAV note={note} />
          ))}
          <AddNoteSAV client={client} refreshClient={getClient} />
        </Box>
      )}
    </>
  );
};

export default Note;
