import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  notAssigned: {
    color: '#2188CC !important',
  },
  NotAssignedStatus: {
    background: '#EDF1F2',
    color: '#888888 !important',
  },
  awaitingPicking: {
    background: '#FFECCC',
    color: '#CE8500 !important',
  },
  awaitingValidation: {
    background: '#FFE0E0',
    color: '#E24D4D !important',
  },
  pickingInprogress: {
    background: '#E6E6F2',
    color: '#4A4AFF !important',
  },
  closed: {
    background: '#CDFFCD',
    color: '#007F00 !important',
  },
  invoiced: {
    background: '#F8E8FB',
    color: '#CE66DF !important',
  },
  manque: {
    background: '#F8E8FB',
    color: '#CE66DF !important',
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: 0,
    height: 0,
    borderLeft: '50px solid transparent',
    borderRight: '50px solid transparent',
    borderBottom: '100px solid blue',

    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderLeft: '46px solid transparent',
      borderRight: '46px solid transparent',
      borderBottom: '92px solid white', /* Inside color */
    },
  },
}));

export default useStyles;
