import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import { debugContextDevtool } from 'react-context-devtool';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Provider } from 'react-redux';
import App from './App';
import 'fonts/SF-Pro-Display-Regular.otf';
import 'fonts/FontsFree-Net-SFProDisplay-Bold.ttf';
import 'fonts/SF-Pro-Display-Medium.otf';
import './index.css';
import 'Utils/ulilities';
import store from './store';

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: { fontFamily: 'Montserrat' },
        h5: { fontFamily: 'Montserrat' },
      },
    },
    MuiButton: {
      styleOverrides: {
        label: {
        //  color: '#38485C',
          font: 'normal bold 18px Montserrat',
        },
        root: {
          // background: '#FFFFFF',
          // color: '#027BC6',
          fontFamily: 'Montserrat',
          //  borderRadius: 12,
          // textTransform: 'Capitalize',
        },
        contained: { boxShadow: '0px 20px 80px #00000011' },
      },
    },
    /* MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          height: 48,
          boxShadow: '0 1px 2px 0 rgba(9, 30, 66, 0.08)',
          background: '#FFF',
        },
        input: {
          padding: 5,
          paddingLeft: 13,
        },
      },
    }, */
  },
});

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <App />
          </Provider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  container,
);

// eslint-disable-next-line no-underscore-dangle
if (window.__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK) {
  debugContextDevtool(container, {
    disable: process.env.NODE_ENV === 'production',
  });
}
