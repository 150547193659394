import { useState } from 'react';

const useActionsRow = () => {
  const [openActions, setOpenActions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const onHandleActions = (row) => (e) => {
    setOpenActions(!openActions);
    setAnchorEl(e.currentTarget);
    if (row?.id) {
      setSelectedRow(row);
    }
  };

  return {
    onHandleActions,
    selectedRow,
    openActions,
    anchorEl,
  };
};

export default useActionsRow;
