import React, { useMemo } from 'react';
import RenderInput from 'Shared/RenderInput';
import { BANKS, PAYMENT_NATURES } from '../PaymentDocuments/Filters/constants';
import { PAYEMENT_STATUSES } from '../PaymentDocuments/constants';

const selectStyle = {
  marginTop: 8, marginBottom: 4, maxWidth: 190, display: 'inline-block', border: '1px solid #007BC6',
};
const sxDate = {
  width: '190px', border: '1px solid #007BC6', mt: '8px', mb: '4px',
};
const inputStyle = {
  marginTop: 8, marginBottom: 4, width: '190px', border: '1px solid #007BC6',
};
const Fields = [
  /* {
    type: 'autocomplete',
    id: 'client',
    label: 'Client',
    listName: 'clients',
    filter: (option, value) => option
      ?.code.toLowerCase().includes(value?.toLowerCase()) || option
      ?.name.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => (option ? `${option?.code}: ${option.name}` : option),
    style: selectStyle,
  }, */
  {
    type: 'date',
    id: 'receptionDate',
    label: 'Date',
    clearble: false,
    sx: sxDate,
  },
  {
    type: 'autocomplete',
    id: 'type',
    label: 'Nature du règlement',
    listName: 'paymentNatures',
    filter: (option, value) => option
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option,
    style: selectStyle,
  },
  {
    type: 'autocomplete',
    id: 'bank',
    label: 'Banque',
    listName: 'banks',
    filter: (option, value) => option
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option,
    style: selectStyle,
  },
  {
    type: 'autocomplete',
    id: 'status',
    label: 'Libellé',
    listName: 'paymentStatusList',
    filter: (option, value) => option.label
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option.label,
    style: selectStyle,
  },
  {
    type: 'autocomplete',
    id: 'nePasVerse',
    label: 'Flag',
    listName: 'verseList',
    filter: (option, value) => option
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option,
    style: selectStyle,
  },
  {
    type: 'date',
    id: 'dueDate',
    label: 'Echéance',
    clearble: false,
    sx: sxDate,
  },
  {
    id: 'amount',
    label: 'Montant à Régler',
    subType: 'number',
    type: 'input-v2',
    style: inputStyle,
  },
  {
    id: 'codeJournal',
    label: 'Code Journal',
    type: 'input-v2',
    style: inputStyle,
  },
];

const EditHeader = ({ payment, onChange }) => {
  const lists = useMemo(() => ({
    // clients: clients || [],
    paymentNatures: PAYMENT_NATURES,
    banks: BANKS,
    paymentStatusList: PAYEMENT_STATUSES,
    verseList: ['Oui', 'Non'],
  }),
  []);
  const onHandleChange = (name) => (event, value) => {
    onChange({ target: { name, value: event instanceof Date ? event : value } });
  };
  return (
    <>
      {Fields.map((field) => (
        <RenderInput
          key={field.id}
          input={(field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field)}
          value={payment[field.id]}
          handleChange={field.type === 'input-v2' ? onChange : onHandleChange(field.id)}
          fullWidth={false}
          name={field.id}
          label={field.label}
          label2={field.label2}
          style={field.style}
          sx={field.sx}
        />
      ))}
    </>
  );
};

export default EditHeader;
