/* eslint-disable max-len */
import {
  Box, Grid, IconButton, Popper, TextField, Typography, Autocomplete,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiltersUtils from 'Utils/FiltersUtils';
import RangeDate from 'Shared/RangeDate';
import { TYPES_COMMANDES } from 'Components/ControleOrders/constants';
import { CONTROL_LIST_LABELS, filtersName } from '../constants';
import { defaultMessage, getCommerciaux } from '../../PaymentServices';
import useStyles from './style';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);
const Filters = ({
  values, handleInputChange, handleRefresh, onSubmitSearch, type = true,
}) => {
  const {
    state: {
      referencial: {
        clients = [], expeditions, paymentModes,
      },
    },
  } = useContext(ReferencialContext);
  const ref = useRef();
  const cyties = useMemo(() => clients.map((c) => c.city).unique(), [clients]);
  const zones = useMemo(() => clients.map((c) => c.zone).unique(), [clients]);
  const [commercials, setCommercials] = useState([]);
  const [filtredCommercials, setFiltredCommercials] = useState([]);
  const [filtredClients, setFiltredClients] = useState([]);
  const [filtredCyties, setFiltredCyties] = useState([]);
  const [filtredZones, setFiltredZones] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeDate = (value, isStartDate) => {
    handleInputChange(isStartDate ? 'startDate' : 'endDate')({ target: { value } });
  };
  useEffect(() => {
    if (cyties && cyties.length) {
      setFiltredCyties((prev) => (!prev.length ? cyties : prev));
    }
  }, [cyties, setFiltredCyties]);
  useEffect(() => {
    if (zones && zones.length && !filtredZones.length) {
      setFiltredZones(zones);
    }
  }, [zones, setFiltredZones]);
  useEffect(() => {
    if (commercials && commercials.length) {
      setFiltredCommercials(commercials);
    }
  }, [commercials, setFiltredCommercials]);
  useEffect(() => {
    if (clients && clients.length && !filtredClients.length) {
      setFiltredClients((prev) => (!prev.length ? clients : prev));
    }
  }, [clients, setFiltredClients]);
  const onInputChange = (prop) => (event, value) => {
    handleInputChange(prop)(event, value);
    if (prop === 'client') {
      let cityList = value.length === 0 ? cyties : value?.map((v) => v.city);
      let zoneList = value.length === 0 ? zones : value?.map((v) => v.zone);
      if (values.commercial && !values.commercial?.length) {
        setFiltredCommercials(commercials.filter((c) => c.zone == null || zoneList.includes(c.zone)));
      } else if (!value.length) {
        cityList = filtredCyties;
        zoneList = filtredZones;
      } else {
        cityList = [...cityList, ...filtredCyties].unique();
        zoneList = [...zoneList, ...filtredZones].unique();
      }
      setFiltredCyties(cityList);
      setFiltredZones(zoneList);
    }
    if (prop === 'commercial') {
      let zoneList = value.length === 0 ? zones : value?.map((v) => v.zone);
      if (values.client && !values.client?.length) {
        const clientList = clients.filter((c) => zoneList.includes(c.zone));
        setFiltredClients(clientList);
        const cityList = clientList.map((c) => c.city).unique();
        setFiltredCyties(cityList);
      } else if (!value.length) {
        zoneList = filtredZones;
      } else {
        zoneList = [...zoneList, ...filtredZones].unique();
      }
      setFiltredZones(zoneList);
    }
    if (prop === 'city') {
      const cityList = value.length === 0 ? cyties : value;
      let zoneList = filtredZones;
      if (!values.client.length) {
        const clientList = clients.filter((c) => cityList.includes(c.city));
        setFiltredClients(clientList);
        zoneList = clientList.map((cl) => cl.zone).unique();
        handleInputChange('zone')(event, []);
        setFiltredZones(zoneList);
      }
      if (!values.commercial.length) {
        const commercialList = commercials.filter((c) => c.zone == null || zoneList.includes(c.zone));
        setFiltredCommercials(commercialList);
      }
    }
    if (prop === 'zone') {
      const zoneList = value.length === 0 ? zones : value;
      if (!values.client.length) {
        const clientList = clients.filter((c) => zoneList.includes(c.zone));
        setFiltredClients(clientList);
        setFiltredCyties(clientList.map((c) => c.city).unique());
        handleInputChange('city')(event, []);
      }
      if (!values.commercial.length) {
        setFiltredCommercials(commercials.filter((c) => c.zone == null || zoneList.includes(c.zone)));
      }
    }
  };
  useEffect(() => {
    if (values) {
      FiltersUtils.saveFilters(filtersName, values);
    }
  }, [values]);
  const getCommerciauxData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCommerciaux();
      setCommercials(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setCommercials]);
  useEffect(() => {
    getCommerciauxData();
  }, [getCommerciauxData]);
  const classes = useStyles();
  const onRefresh = () => {
    handleRefresh();
  };

  const rangeDates = {
    start: values.startDate != null ? new Date(values.startDate) : values.startDate,
    end: values.endDate != null ? new Date(values.endDate) : values.endDate,
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Box
        style={{
          flexGrow: 1,
          display: 'flex',
          columnGap: 10,
          alignItems: 'flex-start',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField
              label="N° de piece"
              variant="outlined"
              onChange={handleInputChange('numCommande')}
              value={values.numCommande}
              fullWidth
              size="small"
              classes={{ root: classes.filter }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="N° d’expédition"
              variant="outlined"
              onChange={handleInputChange('numExpedition')}
              value={values.numExpedition}
              fullWidth
              size="small"
              classes={{ root: classes.filter }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              PopperComponent={PopperMy}
              fullWidth
              classes={{ root: classes.filter }}
              options={filtredZones || []}
              onChange={onInputChange('zone')}
              value={values.zone}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option}
              filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue?.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Zone"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              PopperComponent={PopperMy}
              classes={{ root: classes.filter }}
              fullWidth
              options={filtredClients || []}
              onChange={onInputChange('client')}
              value={values.client}
              size="small"
              autoHighlight
              multiple
              getOptionLabel={(option) => `${option.code} : ${option.name}`}
              filterOptions={(options, v) => options.filter(
                (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                  || option.code?.toLowerCase().includes(v.inputValue.toLowerCase()),
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              PopperComponent={PopperMy}
              classes={{ root: classes.filter }}
              fullWidth
              options={filtredCommercials || []}
              onChange={onInputChange('commercial')}
              value={values.commercial}
              size="small"
              autoHighlight
              getOptionLabel={(option) => `${option.name}`}
              filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Commercial"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2} ref={ref}>
            <Autocomplete
              multiple
              PopperComponent={PopperMy}
              autoSelect
              fullWidth
              classes={{ root: classes.filter }}
              options={expeditions || []}
              onChange={handleInputChange('expedition')}
              value={values.expedition}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.name}
              filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expédition"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Typography
                  {...props}
                  style={{ width: ref?.current ? ref?.current?.clientWidth - 50 : 'unset' }}
                >
                  {option?.name}
                </Typography>
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              PopperComponent={PopperMy}
              autoSelect
              fullWidth
              classes={{ root: classes.filter }}
              options={paymentModes || []}
              onChange={handleInputChange('paymentMode')}
              value={values.paymentMode}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.name}
              filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mode de paiement"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              PopperComponent={PopperMy}
              fullWidth
              classes={{ root: classes.filter }}
              options={filtredCyties || []}
              onChange={onInputChange('city')}
              value={values.city}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option}
              filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue?.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ville"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          {type && (
          <Grid item xs={2}>
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              fullWidth
              classes={{ root: classes.filter }}
              options={TYPES_COMMANDES || []}
              onChange={handleInputChange('type')}
              value={values.type}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.label}
              filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type de pièce"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          )}
          <Grid item xs={2}>
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              fullWidth
              classes={{ root: classes.filter }}
              options={CONTROL_LIST_LABELS || []}
              onChange={handleInputChange('controlled')}
              value={values.controlled}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.label}
              filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Controle"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              classes={{ root: classes.filter }}
              fullWidth
              options={['Bouskoura', 'Derb Omar']}
              onChange={handleInputChange('deposit')}
              value={values.deposit}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option}
              filterOptions={(options, v) => options.filter((option) => option.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dépots"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Grid>
          <Grid item xs={2} container spacing={1}>
            <Grid item xs={12} style={{ paddingRight: 5, display: 'flex' }}>
              <RangeDate
                value={rangeDates}
                handleChange={onChangeDate}
                text={{ start: 'Début', end: 'Fin' }}
                noMarginRightEnd
                styleInput={{ marginRight: '5px' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton
            color="primary"
            onClick={onRefresh}
            style={{
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              borderRadius: 12,
              padding: 8,
            }}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
          {onSubmitSearch && (
          <IconButton
            color="primary"
            onClick={onSubmitSearch}
            style={{
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              borderRadius: 12,
              padding: 8,
              marginTop: 5,
            }}
            size="large"
          >
            <SearchIcon />
          </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Filters;
