import {
  Box,
} from '@mui/material';
import React, {
  useContext, useMemo,
} from 'react';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import { BANKS, Fields, PAYMENT_NATURES } from './constants';
import { PAYEMENT_STATUSES } from '../constants';

const Filters = ({ values, onChange }) => {
  const {
    state: {
      referencial: { clients },
    },
  } = useContext(ReferencialContext);
  const lists = useMemo(() => ({
    clients: clients || [],
    paymentNatures: PAYMENT_NATURES,
    banks: BANKS,
    paymentStatusList: PAYEMENT_STATUSES,
    verseList: ['Oui', 'Non'],
  }),
  [clients]);
  const handleInputChange = (prop) => (event, value) => {
    if (['date', 'echeanceStartDate'].includes(prop)) {
      // is start date
      if (value) {
        onChange({ ...values, [prop]: { ...values[prop], start: event } });
      } else {
        onChange({ ...values, [prop]: { ...values[prop], end: event } });
      }
    } else {
      onChange({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
    }
  };
  return (
    <Box sx={{
      mb: 4.5,
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        flex: 1,
        display: 'flex',
        background: '#E9EBED 0% 0% no-repeat padding-box',
        borderRadius: 1.5,
        opacity: 1,
        padding: '10px 7px',
      }}
      >
        {Fields.map((field) => (
          <RenderInput
            key={field.id}
            input={(field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field)}
            value={values[field.id]}
            handleChange={handleInputChange(field.id)}
            fullWidth={false}
            name={field.id}
            label={field.label}
            label2={field.label2}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Filters;
