import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  topHeader: { display: 'flex', mb: 2 },
  topHeaderTitle: { display: 'flex', alignItems: 'center' },
  noDoc: { font: 'normal 600 28px Montserrat', marginRight: 8 },
  typeDoc: { font: 'normal 600 16px Montserrat', color: '#007BC6', marginLeft: 32 },
}));

export default useStyles;
