import React from 'react';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 3px',
};

export const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  display: 'block',
  minWidth: 130,
};

export const columns = [{
  id: 'id',
  label: 'CODE',
  style: TitleStyle,
  render: ({ value }) => (
    <Typography style={defaultColumnStyle} display="inline">
      {value}
    </Typography>
  ),
},
{
  id: 'name',
  label: 'INTITULÉ',
  style: TitleStyle,
  render: ({ value }) => (
    <Typography style={defaultColumnStyle} display="inline">
      {value}
    </Typography>
  ),
},
{
  id: 'level2',
  label: 'NIVEAU 2',
  style: TitleStyle,
  render: ({ value }) => (
    <Typography style={defaultColumnStyle} display="inline">
      {value ? value.name : '-'}
    </Typography>
  ),
}, {
  id: 'level3',
  label: 'NIVEAU 3',
  style: TitleStyle,
  render: ({ value }) => (
    <Typography style={defaultColumnStyle} display="inline">
      {value ? value.name : '-'}
    </Typography>
  ),
}, {
  id: 'level4',
  label: 'NIVEAU 4',
  style: TitleStyle,
  render: ({ value }) => (
    <Typography style={defaultColumnStyle} display="inline">
      {value ? value.name : '-'}
    </Typography>
  ),
},
{
  id: 'action',
  label: '',
  style: TitleStyle,
  render: ({ row }) => (
    <IconButton onClick={(e) => row.onHandleAction(e, row)}>
      <MoreHorizIcon />
    </IconButton>
  ),
}];

export const places = [0, 1, 2, 3];
