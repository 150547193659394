/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const base = (state) => state.app;

export const makeSelectIsLoading = () => createSelector(
  [base], (traceability) => traceability.loading > 0,
);

export const makeSelectFamilies = () => createSelector(
  [base], (traceability) => traceability.families,
);

export const makeSelectBrands = () => createSelector(
  [base], (traceability) => traceability.brands,
);

export const makeSelectFamiliesByLevel = (level) => createSelector(
  [makeSelectFamilies()], ({ list }) => list
    ?.filter((family) => family.familyLevel === level && !family.disabled) || [],
);

export const makeSelectCities = () => createSelector(
  [base], (app) => app.cities,
);

export const makeSelectZones = () => createSelector(
  [base], (app) => app.zones,
);

export const makeSelectExpeditions = () => createSelector(
  [base], (app) => app.expeditions,
);

export const makeSelectPaymentModes = () => createSelector(
  [base], (app) => app.paymentModes,
);
