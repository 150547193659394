import React, { useRef } from 'react';

import {
  Paper,
  Box,
  Typography,
  Popper,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';

import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { TYPES_ACHAT_CODE } from '../constants';

const useStylesActions = makeStyles(() => ({
  action: {
    display: 'flex', padding: '10px 15px', cursor: 'pointer', margin: '3px 0',
  },
  actionLabel: {
    marginLeft: 5, color: '#38485C', font: 'normal 600 16px Montserrat', textWrap: 'nowrap',
  },
}));

const ConvertActions = ({
  isOpen, anchorEl, onClose, onConvertTo, type,
}) => {
  const refActions = useRef();
  useOnClickOutside(refActions, onClose);
  const classes = useStylesActions();
  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      ref={refActions}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            {type === TYPES_ACHAT_CODE.BC && (
            <Box onClick={() => onConvertTo(TYPES_ACHAT_CODE.BL)} className={classes.action}>
              <ChangeCircleOutlinedIcon />
              <Typography className={classes.actionLabel}>Transformé en BL</Typography>
            </Box>
            )}
            {type === TYPES_ACHAT_CODE.PL && (
            <Box onClick={() => onConvertTo(TYPES_ACHAT_CODE.FA)} className={classes.action}>
              <ChangeCircleOutlinedIcon />
              <Typography className={classes.actionLabel}>Transformé en FA</Typography>
            </Box>
            )}
            {type === TYPES_ACHAT_CODE.PL && (
            <Box onClick={() => onConvertTo(TYPES_ACHAT_CODE.BL)} className={classes.action}>
              <ChangeCircleOutlinedIcon />
              <Typography className={classes.actionLabel}>Transformé en BL</Typography>
            </Box>
            )}
            {type === TYPES_ACHAT_CODE.BL && (
            <Box onClick={() => onConvertTo(TYPES_ACHAT_CODE.FA)} className={classes.action}>
              <ChangeCircleOutlinedIcon />
              <Typography className={classes.actionLabel}>Transformé en FA</Typography>
            </Box>
            )}
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ConvertActions;
