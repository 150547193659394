import {
  Box, IconButton,
  Typography,
} from '@mui/material';
import React, {
  useEffect, useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { defaultMessage, getRegelementByClient } from '../ClientServices';
import useStyles from './style';
import FinancialBar from '../FinancialBar';
import FinancialTabs from '../FinancialBar/FinancialTabs';
import { TABS_ID } from '../constants';
import FinancialData from '../FinancialBar/FinancialData';

const ClientTabs = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [clientDashboard, setClientDashboard] = useState({});
  const [regList, setRegList] = useState({});
  const [selectedTab, setSelectedTab] = useState(TABS_ID.REG);
  const history = useHistory();
  const classes = useStyles();

  useEffect(async () => {
    if (![0, 1].includes(selectedTab)) {
      return;
    }
    try {
      setLoading(true);
      const {
        nonReglePm, nonRegleH, impayes, encoursList, ...dashboard
      } = await getRegelementByClient(id, false, selectedTab === TABS_ID.REG ? 'PM' : 'H');
      setClientDashboard(dashboard);
      setRegList({
        nonReglePm, nonRegleH, impayes, encoursList,
      });
    } catch (e) {
      toast.error(e.message || defaultMessage);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return (
    <Box sx={{ mr: 2 }}>
      {loading && <GlobalLoader size="100vh" />}
      <Box className={classes.topHeader}>
        <Box sx={{ flex: 0.35, alignSelf: 'center' }}>
          <Box className={classes.topHeaderTitle}>
            <IconButton onClick={() => history.push('/recovery')}>
              <ArrowBackIcon style={{ color: 'black' }} />
            </IconButton>
            <Typography className={classes.noDoc}>{`${clientDashboard.clientName || ''} - ${id}`}</Typography>
          </Box>
          <Typography className={classes.typeDoc}>
            SITUATION FINANCIÈRE
          </Typography>
        </Box>
        <Box sx={{ flex: 0.65 }}>
          <FinancialBar clientInfo={clientDashboard} />
        </Box>
      </Box>
      <FinancialTabs
        clientInfo={clientDashboard}
        selectedTab={selectedTab}
        onClickTab={setSelectedTab}
      />
      <FinancialData tab={selectedTab} regData={regList} clientCode={id} />
    </Box>
  );
};

export default ClientTabs;
