import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { HeaderData } from 'Components/Client/FinancialBar/FinancialData';
import DownloadIcon from '@mui/icons-material/Download';
import { generateKey } from 'Utils/ulilities';
import Filters from '../Filters';
import { DEFAULT_VALUES } from '../NewOrder/constants';
import {
  defaultMessage, downloadBC, downloadBL, downloadInvoice, getOrders,
} from '../OrdersService';
import { columns, mapOrderStatus } from './constants';
import useStyles from './style';
import './style.css';
import NewOrder from '../NewOrder';

const Info = ({ label, value }) => (
  <>
    <Typography sx={{ color: '#707070', fontWeight: 600 }}>{label}</Typography>
    <Typography sx={{ color: '#38485C', mb: 2, fontWeight: 600 }}>{value}</Typography>
  </>
);

const InfoHeader = ({ label, value }) => (
  <Box>
    <Typography sx={{ color: '#007BC6', fontWeight: 600 }}>
      {label}
    </Typography>
    <Typography sx={{ fontWeight: 600 }}>{value}</Typography>
  </Box>

);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'unset',
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const titles = [
  { label: 'RÉFÉRENCE', id: 'product', format: (p) => p?.reference },
  {
    label: 'DÉSIGNATION', id: 'product', format: (p) => p?.designation, width: 2,
  },
  { label: 'QUANTITÉ', id: 'quantity' },
  {
    label: 'PRIX UNITAIRE', id: 'prixUnitaire', format: (p) => `${p} Dh`, width: 1.5,
  },
  { label: 'REMISE', id: 'remise', format: (p) => `${p}%` },
  { label: 'MONTANT TTC', id: 'montantTtc', format: (p) => (<span style={{ color: '#007BC6' }}>{`${p} Dh`}</span>) },
];
/* const getAnomalies = (order) => {
  const quantity = order?.quantityDifference ? ' quantityDifference, ' : '';
  const price = order?.priceAndDiscountDifference ? ' priceAndDiscountDifference, ' : '';
  const payment = order?.paymentModeChange ? ' paymentModeChange, ' : '';
  const expedition = order?.expeditionModeChange
  || order?.expeditionmodeChange ? ' expeditionModeChange.' : '';
  return `${quantity}${price}${payment}${expedition}`;
}; */
export const OrderDetail = ({
  open, setOpen, order, printBC,
}) => (
  <Dialog
    onClose={() => setOpen(false)}
    aria-labelledby="customized-dialog-title"
    maxWidth="lg"
    fullWidth
    open={open}
  >
    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography sx={{ fontSize: '25px', fontWeight: 600 }}>{order?.reference}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {`${order?.client?.name || ''} - ${order?.client?.city || ''} - ${order?.client?.code || ''}`}
            </Typography>
          </Box>
          <Box sx={{
            background: '#007BC66B', borderRadius: '12px', p: 1.1, mr: '19%',
          }}
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: '30px', color: '#fff' }}>
              {order?.amount}
              <span style={{ fontSize: '15px' }}>TTC</span>
            </Typography>
          </Box>
        </Box>
        {/* order?.type === 1 && printBC && (
          <IconButton
            aria-label="visible"
            onClick={() => printBC(order?.reference)}
            size="large"
          >
            <VisibilityIcon />
          </IconButton>
        ) */}
        {/* order?.noAnomaly === false && (
          <Box xs={2} item>
            <Typography display="inline" style={{ fontWeight: 'bold' }}>
              Anomalies :
            </Typography>
            <Typography display="inline">{getAnomalies(order)}</Typography>
          </Box>
        ) */}
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 0.81 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2.5 }}>
            <InfoHeader label="Piquée par :" value={order?.picker} />
            <InfoHeader label="Facturée par :" value={order?.biller} />
            <InfoHeader
              label="Commercial :"
              value={order?.details?.length > 0
              && `${order.details[0].commercial?.firstName} ${order.details[0].commercial?.lastName}`}
            />
            <InfoHeader label="N de colis :" value={order?.colisage} />
          </Box>
          <HeaderData columns={titles} sx={{}} />
          {order?.details?.map((detail) => (
            <Box
              sx={{
                borderBottom: '1px solid #38485C1A',
                p: 2.1,
                display: 'flex',
              }}
            >
              {titles.map((cel) => (
                <Typography
                  sx={{ flex: cel.width || 1, color: '#38485C', fontWeight: 600 }}
                  key={generateKey('data', cel?.id)}
                >
                  {cel.format ? cel.format(detail[cel.id]) : detail[cel.id]}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
        <Box sx={{ flex: 0.19, ml: 1.8 }}>
          <Box sx={{ display: 'flex', mb: 0.5 }}>
            <IconButton
              onClick={() => printBC(order?.reference, [3, 4].includes(order?.type))}
              sx={{
                background: '#007BC6',
                borderRadius: 1.5,
                padding: 0.5,
                boxShadow: '0px 4px 10px #108CD650',
                color: '#FFF',
              }}
              size="large"
            >
              <DownloadIcon size="small" />
            </IconButton>
            <IconButton
              onClick={() => printBC(order?.reference, [3, 4].includes(order?.type), true)}
              sx={{
                background: '#3B485A',
                borderRadius: 1.5,
                padding: 0.5,
                boxShadow: '0px 4px 10px #108CD629',
                marginLeft: 1,
                color: '#FFF',
              }}
              size="large"
            >
              <VisibilityIcon size="small" />
            </IconButton>
          </Box>
          <Box sx={{
            background: '#F7F7F7', borderRadius: '12px', p: 2, height: 'max-content',
          }}
          >
            <Info label="DATE" value={order?.date && new Date(order?.date).toLocaleDateString('en-GB')} />
            <Info label="DATE D’IMPRESSION" value={order?.printedDate && format(new Date(order?.printedDate), 'dd/MM/yyyy HH:mm')} />
            <Info label="DEPOT" value={order?.repoId === 1 ? 'Bouskoura' : 'Derb Omar'} />
            <Info label="MODE DE PAYMENT" value={order?.paymentMode?.name} />
            <Info label="MODE ‘EXPEDITION" value={order?.expedition?.name} />
            <Info label="N° EXPEDITION" value={order?.numExpedition} />
          </Box>

        </Box>
      </Box>
    </DialogContent>
  </Dialog>
);

export const DispalyFileFormLink = ({ link, open, setOpen }) => (
  <Dialog
    onClose={() => setOpen(false)}
    aria-labelledby="customized-dialog-title"
    maxWidth="lg"
    fullWidth
    fullScreen
    sx={{ height: 'auto' }}
    open={open}
  >
    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      Déclaration D`expédition :
    </DialogTitle>
    <DialogContent>
      <embed src={link} width="100%" height="100%" type="application/pdf" />
    </DialogContent>
  </Dialog>
);

const SageOrders = () => {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [orders, setOrders] = useState({ content: [] });
  const [values, setValues] = useState(
    localStorage.getItem('filters-sage-orders')
      ? JSON.parse(localStorage.getItem('filters-sage-orders') || '{}')
      : DEFAULT_VALUES,
  );
  const [openDetail, setOpenDetail] = useState(false);
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDisplayFile, setOpenDisplayFile] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const classes = useStyles();
  const handleInputChange = (prop) => (event, value) => {
    const newValue = {
      ...values,
      [prop]: event instanceof Date ? event : value || event?.target.value,
    };
    localStorage.setItem('filters-sage-orders', JSON.stringify(newValue));
    setValues(newValue);
  };
  const handleRefresh = () => {
    setValues({ ...values });
  };
  const getOrderList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getOrders(sort, {
          city: values.city,
          client: values?.client?.code,
          expedition: values?.expedition?.code,
          paymentMode: values?.paymentMode?.code,
          status: values?.status?.id,
          startDate: values.startDate
            ? format(
              values.startDate instanceof Date ? values.startDate : new Date(values.startDate),
              'yyyy-MM-dd',
            )
            : undefined,
          endDate: values.endDate
            ? format(
              values.endDate instanceof Date ? values.endDate : new Date(values.endDate),
              'yyyy-MM-dd',
            )
            : undefined,
          type: values.type?.id,
        });
        setOrders(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setOrders, setLoading, values],
  );

  const getBL = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadBL(id);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const getInvoiceCall = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadInvoice(id);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const getBC = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadBC(id);
        setUpdate((prev) => !prev);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setUpdate],
  );

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
    setOpenDetail(true);
  };
  const handleDisplayExpedition = async (link) => {
    setFileLink(link);
    setOpenDisplayFile(true);
  };

  const handleCloseNewOrderModal = () => {
    setOpenNewOrder(false);
  };

  const handleOpenNewOrderModal = () => {
    setOpenNewOrder(true);
  };

  const sageOrders = useMemo(
    () => orders.content.map((order) => ({
      ...order,
      amount: order.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& '),
      client: order.client?.name,
      paymentMode: order.paymentMode?.name,
      expedition: order.expedition?.name,
      status: order.status?.status,
      statusClass:
          classes[
            mapOrderStatus[
              order.status && order.status.status ? order.status.status : 'NON_ASSIGNEE'
            ]?.class
          ],
      click: () => handleSelectOrder(order),
      handleDownloadBl: (event) => {
        event.stopPropagation();
        getBL(order.reference);
      },
      handleInvoice: (event) => {
        event.stopPropagation();
        getInvoiceCall(order.reference);
      },
      handleDisplayCoord1: (link) => (event) => {
        event.stopPropagation();
        handleDisplayExpedition(link);
      },
    })),
    [orders.content, values],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openNewOrder && <NewOrder handleClose={handleCloseNewOrderModal} />}
      <Box style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
        <Filters
          values={values}
          handleInputChange={handleInputChange}
          product
          handleRefresh={handleRefresh}
          type
        />
        <Button
          variant="contained"
          style={{ minWidth: '230px' }}
          color="primary"
          onClick={handleOpenNewOrderModal}
        >
          Ajouter une commande
        </Button>
      </Box>
      <OrderDetail
        open={openDetail}
        setOpen={setOpenDetail}
        order={selectedOrder}
        classes={classes}
        printBC={getBC}
      />
      <DispalyFileFormLink link={fileLink} open={openDisplayFile} setOpen={setOpenDisplayFile} />
      <EnhancedTable
        getData={getOrderList}
        rows={sageOrders}
        headCells={columns}
        update={update}
        count={orders.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default SageOrders;
