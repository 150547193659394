// eslint-disable-next-line import/prefer-default-export
export const ExtraFields = [
  {
    type: 'select',
    id: 'type',
    label: 'Type de contact',
    listName: 'contacts',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'value',
    label: 'Contact',
  },
];

export const Fields = [
  {
    type: 'text',
    id: 'firstName',
    label: 'Nom',
  },
  {
    type: 'text',
    id: 'lastName',
    label: 'Prénom',
  },
  {
    type: 'text',
    id: 'fonction',
    label: 'fonction',
  },
  ...ExtraFields,
];
