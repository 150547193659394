import { makeStyles } from '@mui/styles';

export const useStylesInvoiceDetails = makeStyles(() => ({
  header: {
    background: 'rgba(56, 72, 92, 0.11)',
    borderRadius: 12,
    display: 'flex',
    padding: '10px',
  },
  title: {
    flex: 1 / 3,
    font: 'normal 600 14px Montserrat',
    color: '#707070',
    opacity: 0.88,
    textAlign: 'center',
  },
  body: {
    marginTop: '10px',
    background: '#F7F7F7',
    borderRadius: 12,
    minHeight: 200,
    maxHeight: 270,
    minWidth: 600,
    overflow: 'auto',
  },
  invoice: {
    display: 'flex',
    borderBottom: '1px solid #38485c19',
    padding: '20px',
  },
  invoiceText: {
    flex: 1 / 3,
    font: 'normal 600 14px Montserrat',
    color: '#38485C',
    textAlign: 'center',
    alignContent: 'center',
  },
  btnIcon: {
    color: '#007BC6',
    backgroundColor: '#FFF',
    border: '1px solid #007BC6',
    borderRadius: 12,
    padding: 4,
    boxShadow: '0px 4px 10px #108CD629',
    marginLeft: 9,
  },
  btnIcon2: { color: '#00B894', border: '1px solid #00B894' },
  btnIcon3: { color: '#767676', border: '1px solid #767676' },
}));

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 10px 20px 25px',
    overflowY: 'auto',
    height: 'calc(100vh - 104px)',
  },
  header: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: 12,
    marginTop: 24,
    marginBottom: 24,
    padding: 24,
  },
  noDoc: { font: 'normal 600 28px Montserrat', marginRight: 8 },
  typeDoc: { font: 'normal 600 16px Montserrat', color: '#38485C' },
  title: { font: 'normal 600 10px Montserrat', color: '#707070' },
  subTitle: { font: 'normal 600 16px Montserrat', color: '#38485C' },
  item: {
    borderRight: '1px solid #E7E7E7',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
  headerLine: { display: 'flex', justifyContent: 'space-between' },
  topHeader: { display: 'flex', justifyContent: 'space-between' },
  topHeaderTitle: { display: 'flex', alignItems: 'center' },
  btnIcon: {
    color: '#007BC6',
    backgroundColor: '#FFF',
    border: '1px solid #007BC6',
    borderRadius: 12,
    padding: 4,
    boxShadow: '0px 4px 10px #108CD629',
    marginLeft: 9,
  },
  btnIcon2: { color: '#00B894', border: '1px solid #00B894' },
  btnIcon3: { color: '#767676', border: '1px solid #767676' },
  filterZone: {
    flexGrow: 1,
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    padding: '10px 7px',
    marginBottom: 11,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  filter: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  successMsg: {
    marginTop: '20px',
    textAlign: 'center',
    font: 'normal 600 18px Montserrat',
  },
  successCheck: {
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    backgroundColor: '#00B894',
    margin: 'auto',
    color: '#FFF',
    display: 'flex',
  },
  selectContainer: { position: 'relative', margin: 'auto', marginRight: 16 },
  selectItems: {
    position: 'absolute',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000001C',
    border: '1px solid #F7F7F7',
    borderRadius: '12px',
    marginTop: 11,
    zIndex: 5000,
    height: 300,
    overflow: 'auto',
    right: 0,
  },
  selectItem: {
    '& .MuiTypography-root': {
      font: 'normal normal 600 12px Montserrat',
      color: '#38485C',
      textTransform: 'lowercase',
    },
  },
}));

export default useStyles;
