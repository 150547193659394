import React from 'react';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { importNewProducts, importProductCustoms, importSuppliersReferences } from './importServices';

export const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 3px',
};

export const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  display: 'block',
  minWidth: 130,
};

const getColorByStcockValue = (value) => {
  if (value <= 0) {
    return 'red';
  }
  return 'green';
};

const DisplayValue = ({ value }) => (
  <Typography style={{ ...defaultColumnStyle }} display="inline">
    {value}
  </Typography>
);
export const columns = (onHandleActions, families = []) => [
  {
    id: 'reference',
    label: 'RÉFÉRENCE',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ row, value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 70 }} display="inline">
        {row.iA ? value : (
          <>
            <span style={{ color: '#F86060', fontSize: 14 }}>X </span>
            {value}
          </>
        )}
      </Typography>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 0,
      zIndex: 800,
      background: '#f9fafc',
    },
  },
  {
    id: 'designation',
    label: 'DÉSIGNATION',
    style: {
      ...TitleStyle,
      zIndex: 900,
      position: 'sticky',
      textAlign: 'center',
      left: 73,
    },
    render: ({ value }) => (
      <Typography
        style={{
          ...defaultColumnStyle,
          width: 250,
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        display="inline"
      >
        {value}
      </Typography>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 73,
      zIndex: 800,
      background: '#f9fafc',
    },
  },
  {
    id: 'stock',
    label: 'STOCK ACTUEL',
    style: TitleStyle,
    render: ({ row }) => {
      const { sB = 0, sD = 0 } = row;
      return (
        <Typography
          style={{ ...defaultColumnStyle, color: getColorByStcockValue(sB + sD) }}
          display="inline"
        >
          {sB + sD}
        </Typography>
      );
    },
  },
  {
    id: 'nomenclature',
    label: 'Nomenclature',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'coefVente',
    label: 'Coéf prix de vente',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'iH',
    label: 'Huile',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value === 1 ? 'oui' : 'non'} />,
  },
  {
    id: 'priceAch',
    label: 'Prix achat',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'priceDevise',
    label: 'Prix en devise',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'p',
    label: 'Prix de vente',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'pays',
    label: 'Origine',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'refT',
    label: 'Réf tecdoc',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'rO',
    label: 'Réf origine',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'bId',
    label: 'Marque',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'e',
    label: 'Equivalent',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'information',
    label: 'Information',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'd',
    label: 'Dimension',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'fId',
    label: 'Famille',
    style: TitleStyle,
    render: ({ value }) => {
      const family = families.find((f) => f.id === value)?.name || value;
      return <DisplayValue value={family} />;
    },
  },
  {
    id: 'xxx',
    label: 'Prix de revient',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'xx1',
    label: 'Emplacement',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'europeanRate',
    label: 'Taux européen',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'nonEuropeanRate',
    label: 'Taux  Non européen',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'xx4',
    label: 'Saisonnier',
    style: TitleStyle,
    render: ({ value }) => <DisplayValue value={value} />,
  },
  {
    id: 'action',
    label: '',
    style: {
      ...TitleStyle,
      zIndex: 900,
    },
    render: ({ row }) => (
      <IconButton onClick={(e) => onHandleActions(row)(e)}>
        <MoreHorizIcon />
      </IconButton>
    ),
  },
];

export const UploadOptions = [
  { id: 'PRODUCT_NEW', label: 'Importer Des Nouveaux Produits' },
  { id: 'CUSTOMS_INFO', label: 'Importer Les Info Douaniere' },
  { id: 'PRODUCT_SUPPLIER', label: 'Importer Listes Des Articles Par Fournisseurs' },
];

export const mapApiById = (id) => {
  switch (id) {
    case 'PRODUCT_NEW':
      return importNewProducts;
    case 'CUSTOMS_INFO':
      return importProductCustoms;
    case 'PRODUCT_SUPPLIER':
      return importSuppliersReferences;
    default:
      return null;
  }
};

export const inputsA = [
  { name: 'des', label: 'Designation' },
  { name: 'reference', label: 'Reference' },
  { name: 'fId', label: 'Famille', type: 'select' },
  { name: 'priceAch', label: 'Prix D’achat' },
  { name: 'priceDevise', label: 'Prix En Devis' },
  { name: 'puNet', label: 'Prix De Revient' },
  { name: 'price', label: 'Prix De Vent' },
  { name: 'saisonnier', label: 'Saisonnier/Autres' },
  { name: 'coefVente', label: 'Coéf Prix Vente' },
];
export const inputsC = [
  { name: 'nomenclature', label: 'Nomenclature' },
  { name: 'pays', label: 'Origine' },
  { name: 'europeanRate', label: 'TAUX EUROPÉEN' },
  { name: 'nonEuropeanRate', label: 'TAUX NON EUROPÉEN' },
  { name: 'information', label: 'Information' },
  { name: 'huile', label: 'HUILE:', type: 'check' },
];
export const inputsB = [
  { name: 'bId', label: 'Marque', type: 'select' },
  { name: 'rO', label: 'Reference origine' },
  { name: 'refT', label: 'Ref Tecdoc' },
  { name: 'd', label: 'Dimension' },
  { name: 'e', label: 'Equivalent' },
  {
    name: 'productFloor',
    label: 'Emplacement',
    type: 'select',
    options: [0, 1, 2, 3],
    getOptionLabel: (name) => name,
  },
];

export const inputSuppliers = [
  { name: 'supplier', label: 'Fournisseurs', type: 'select' },
  { name: 'refSupplier', label: 'Reference' },
  { name: 'priceSupplier', label: 'Prix' },
  {
    name: 'devisSupplier',
    label: 'Devis',
    type: 'select',
    options: [{ name: 'MAD' }, { name: 'EURO' }, { name: 'DOLAR' }],
  },
];

export const INIT_ARTICLE_FORM = {
  isOpen: false,
  des: null,
  searchBy: null,
  openSupplierSearch: false,
  suppliers: [],
  supplier: null,
  iA: true,
};

export const STATUT_REF = [{ id: 0, name: 'Tous' }, { id: 1, name: 'Obsoléte' }, { id: 2, name: 'Non Obsoléte' }];

export const filterByStatus = (product = {}, status) => {
  if (!status) {
    return true;
  }
  if (status?.id === STATUT_REF[0].id) {
    return true;
  }

  if (status?.id === STATUT_REF[2].id) {
    return !!product.iA;
  }

  return !product.iA;
};
