import React, {
  useCallback, useRef, useState,
} from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { toast } from 'react-toastify';
import { Box, Typography, IconButton } from '@mui/material';
import useForm from 'Shared/Hooks/useForm';
import RangeDate from 'Shared/RangeDate';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import Input from 'Shared/Input/v2';
import { useHistory } from 'react-router-dom';
import ButtonApp from 'Shared/Button';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BlockIcon from '@mui/icons-material/Block';
import axios from 'axios';
import DialogCmp from 'Shared/Dialog';
import { columns, TYPES_ACHAT } from './constants';
import useStyles from './style';
import {
  convertTo,
  defaultMessage, getAllDocuments, getFolderById, getHeaderById, importDocumentAchatByFile,
} from '../importServices';
import ActionsDocuments from './ActionsDocument/ActionsDocument';
import CalculateActions from './ActionsDocument/CalculateActions';
import useInvoiceDetails from './Detail/useInvoiceDetails';
import VentilationDetail from './Detail/VentilationDetail';
import ConvertActions from './ActionsDocument/ConvertActions';

let cancelToken;
const AchatDocuments = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [documentPage, setDocumentPage] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { values, onChange } = useForm({
    start: null, end: null, type: null, search: '',
  });
  const history = useHistory();
  const fileInputRef = useRef(null);
  const [calculate, setCalculate] = useState(false);
  const [convert, setConvert] = useState(false);
  const {
    onOpenInvoiceDetails, isInvoiceDetailsOpen, isInvoiceLoading, invoices, totalHt,
    totalDocument,
  } = useInvoiceDetails(selectedDocument?.id, true);
  const onHandleCalculate = () => {
    setCalculate(!calculate);
    setOpenActions(false);
  };
  const onHandleConvert = () => {
    setConvert(!convert);
    setOpenActions(false);
  };
  const onHandleClickDoc = (folderId) => () => {
    history.push(`/import/achat-document/${folderId}`);
  };

  const onOpenActions = useCallback((clickedDocument) => (event) => {
    event.stopPropagation();
    setOpenActions(!openActions);
    setAnchorEl(event.currentTarget);
    if (!openActions) {
      setSelectedDocument(clickedDocument);
    }
  }, [openActions, setSelectedDocument, setAnchorEl]);

  const onCloseActions = useCallback(() => {
    setOpenConfirmDelete(true);
    setOpenActions(false);
  }, [setOpenConfirmDelete, setOpenActions]);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await getFolderById(selectedDocument.id);
      toast.success('Document supprimé avec succes');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
      setOpenConfirmDelete(false);
      setUpdateTable(false);
    }
  }, [setLoading, selectedDocument]);

  const getDocumentPage = useCallback(
    async (sortParams) => {
      setLoading(true);
      try {
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Cancel');
        }
        cancelToken = axios.CancelToken.source();
        const data = await getAllDocuments(sortParams, values, { cancelToken: cancelToken.token });
        setDocumentPage({
          ...data,
          content: data.content.map((doc) => ({
            ...doc, click: onHandleClickDoc(doc.id), onOpenActions,
          })),
        });
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [values, onOpenActions],
  );

  const onChangeDate = (value, isStartDate) => {
    onChange({ target: { name: isStartDate ? 'start' : 'end', value } });
  };
  const onChangeType = (event, value) => {
    onChange({ target: { name: 'type', value } });
  };
  const handleUploadDocAchat = () => {
    fileInputRef.current.click();
  };

  const downloadTemplate = useCallback(
    async (event, id, label) => {
      event.stopPropagation();
      try {
        setLoading(true);
        await getHeaderById(id, label);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const handleSubmitFile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const data = await importDocumentAchatByFile(file);
        if (data?.statusCode) {
          toast.error(data?.statusLabel);
        } else {
          toast.success('Ficher chargé avec succès');
        }
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
        // eslint-disable-next-line no-param-reassign
        event.target.value = null;
      }
    }
  };
  const onConvertTo = async (targetType) => {
    try {
      setLoading(true);
      await convertTo(selectedDocument?.id, selectedDocument?.type, targetType);
      toast.success('Converssion valider');
    } catch (error) {
      toast.error(error?.message || error);
    } finally {
      setLoading(false);
      setConvert(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <DialogCmp
        title=""
        maxWidth="sm"
        endIcon={false}
        fullWidth
        open={openConfirmDelete}
        handleClose={() => setOpenConfirmDelete(false)}
        onHandleSubmit={handleDelete}
        labelKo="Non"
        labelOk="Oui"
        actionStyle={{ display: 'flex', justifyContent: 'center' }}
        styleOk={{
          height: '38px',
          background: '#F86060',
          boxSdow: '0px 4px 10px #BEBEBE50',
          border: '1px solid #EBEDED',
          borderRadius: '12px',
          padding: '0 24px',
        }}
        styleKo={{
          height: '38px',
          background: '#FFFFFF',
          boxShadow: '0px 4px 10px #BEBEBE50',
          border: '1px solid #EBEDED',
          borderRadius: '12px',
          padding: '0 24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '80%',
            margin: 'auto',
            gap: '26px',
          }}
        >
          <Box sx={{
            width: '114px',
            height: '114px',
            background: '#F86060',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <BlockIcon fontSize="large" sx={{ color: '#FFF' }} />
          </Box>
          <Typography sx={{
            textAlign: 'center',
            font: 'normal normal medium 28px/38px Montserrat',
            color: '#000000',
          }}
          >
            Êtes-vous sûr de vouloir supprimer ce produit ?
          </Typography>
        </Box>
      </DialogCmp>
      <div style={{ height: '100vh' }}>
        <div className={classes.container}>
          <Box className={classes.header}>
            <Typography className={classes.title}>Documents D’achat</Typography>
            <ButtonApp
              label="Importer"
              startIcon={<CloudUploadOutlinedIcon />}
              onClick={handleUploadDocAchat}
              style={{ color: '#007BC6', background: '#FFF', border: '1px solid #007BC6' }}
              endIcon={(
                <IconButton onClick={(event) => downloadTemplate(event, 'DOC_INFO', 'Informations documents')}>
                  <InsertDriveFileIcon fontSize="small" sx={{ color: '#007BC6' }} />
                </IconButton>
              )}
            />
            <input
              type="file"
              hidden
              ref={fileInputRef}
              /// value={value?.name}
              onChange={handleSubmitFile}
            />
          </Box>
          <Box className={classes.filterZone}>
            <Input
              value={values.search}
              onChange={onChange}
              margin="dense"
              style={{ width: '190px' }}
              label="Recherche"
              name="search"
            />
            <Box className={classes.dates}>
              <RangeDate
                value={values}
                handleChange={onChangeDate}
                text={{ start: 'Date début', end: 'Date fin' }}
              />
            </Box>
            <AutoCompleteCmp
              options={TYPES_ACHAT}
              onChange={onChangeType}
              value={values.type}
              label="Type"
              name="type"
              isPrimaryTheme
              style={{ marginTop: 8, marginBottom: 4, maxWidth: 150 }}
            />
          </Box>
          <EnhancedTable
            headCells={columns}
            update={updateTable}
            rowsPerPageOptions={[10, 15, 20]}
            getData={getDocumentPage}
            rows={documentPage?.content || []}
            count={documentPage.totalElements}
          />
          <ActionsDocuments
            onHandleDelete={onCloseActions}
            onClose={onOpenActions}
            isOpen={openActions}
            anchorEl={anchorEl}
            onHandleCalculate={onHandleCalculate}
            onHandleConvert={onHandleConvert}
            type={selectedDocument?.type}
          />
          <VentilationDetail
            classes={classes}
            totalTH={totalHt}
            // totalTTC={totalTTC}
            totalDocument={totalDocument}
            getAllDetails={() => null}
            isInvoiceDetailsOpen={isInvoiceDetailsOpen}
            onOpenInvoiceDetails={onOpenInvoiceDetails}
            id={selectedDocument?.id}
            isInvoiceLoading={isInvoiceLoading}
            invoices={invoices}
          />
          <CalculateActions
            anchorEl={anchorEl}
            isOpen={calculate}
            onClose={onHandleCalculate}
            onHandleVentile={onOpenInvoiceDetails}
            folderId={selectedDocument?.id}
            folderName={selectedDocument?.name}
            setLoading={setLoading}
          />
          <ConvertActions
            anchorEl={anchorEl}
            isOpen={convert}
            onClose={onHandleConvert}
            onConvertTo={onConvertTo}
            type={selectedDocument?.type}
          />
        </div>
      </div>
    </>
  );
};

export default AchatDocuments;
