import {
  Box, Fade, Paper, Popper, TextField, Typography,
} from '@mui/material';
import EnhancedTable from 'Shared/EnhancedTable';
import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import useForm from 'Shared/Hooks/useForm';
import { useSelector } from 'react-redux';
import { makeSelectBrands, makeSelectFamilies, makeSelectFamiliesByLevel } from 'selectors';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import { toast } from 'react-toastify';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import DialogCmp from 'Shared/Dialog';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import Input from 'Shared/Input/v2';
import { isEmptyOrNull } from 'Utils/ulilities';
import { columns, places } from './constants';
import { addFamily, deleteFamily, updateFamily } from '../importServices';
import useStyles from './styles';

const ActionsFamily = ({
  isOpen, onHandleEdit, onHandleDelete, onClose, anchorEl,
}) => {
  const refActions = useRef();
  useOnClickOutside(refActions, onClose);

  const action = {
    display: 'flex', padding: '10px 15px', cursor: 'pointer', margin: '3px 0',
  };
  const actionLabel = { marginLeft: 5, color: '#38485C', font: 'normal 600 16px Montserrat' };
  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      ref={refActions}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Box onClick={onHandleEdit} sx={action}>
              <EditOutlinedIcon />
              <Typography sx={actionLabel}>Modifier</Typography>
            </Box>
            <Box sx={{ ...action, color: '#F86060' }} onClick={onHandleDelete}>
              <DeleteForeverOutlinedIcon />
              <Typography sx={{ ...actionLabel, color: '#F86060' }}>
                Supprimer
              </Typography>
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

const Families = () => {
  const familiesGrp = useSelector(makeSelectFamilies());
  const familiesTop = useSelector(makeSelectFamiliesByLevel(4));
  const families1 = useSelector(makeSelectFamiliesByLevel(1));
  const brands = useSelector(makeSelectBrands());
  const classes = useStyles();
  const { values, onChange } = useForm({
    page: 0,
    size: 10,
    anchorEl: null,
    isOpenActions: false,
    isOpenConfirm: false,
    isOpenAdd: false,
    canSubmit: false,
    search: '',
  });
  const families3 = useMemo(() => {
    if (!values.target?.family4 || !familiesGrp.list) {
      return [];
    }
    return familiesGrp.list.filter((f3) => f3.parentId === values.target.family4.id);
  }, [familiesGrp, values.target?.family4]);

  const families2 = useMemo(() => {
    if (!values.target?.family3 || !familiesGrp.list) {
      return [];
    }
    return familiesGrp.list.filter((f2) => f2.parentId === values.target.family3.id
    && f2.familyLevel !== 4);
  }, [familiesGrp, values.target?.family3]);
  useEffect(() => {
    const canSubmit = !isEmptyOrNull(values.target?.id) && !isEmptyOrNull(values.target?.name);
    onChange({ target: { name: 'canSubmit', value: canSubmit } });
  }, [values.target]);
  const onChangeSize = (size) => {
    onChange({ target: { name: 'size', value: size } });
  };
  const onChangePage = (page) => {
    onChange({ target: { name: 'page', value: page } });
  };
  const onChangeX = (name, value) => {
    onChange({ target: { name, value } });
  };
  const handleDelete = () => {
    onChangeX('isOpenActions', false);
    onChangeX('isOpenConfirm', !values.isOpenConfirm);
  };
  const onHandleActions = (event, family) => {
    onChangeX('target', { ...family, brand: family.brand ? { id: family.brand, name: family.brand } : family.brand });
    onChangeX('isOpenActions', true);
    onChangeX('anchorEl', event.currentTarget);
  };
  const handleConfirmDelete = async () => {
    try {
      await deleteFamily(values.target.id);
      toast.success(`La famille ${values.target.name} est supprimé !`);
    } catch (error) {
      toast.error(error);
    } finally {
      handleDelete();
    }
  };
  const onHandleAdd = () => {
    onChangeX('target', { isAdd: true });
    onChangeX('isOpenAdd', !values.isOpenAdd);
  };
  const onSubmitFamily = async () => {
    try {
      const body = { ...values.target, brand: values.target.brand ? values.target.brand.id : null };
      await addFamily(body);
      toast.success('Famille est bien ajouté');
      onHandleAdd();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onChangeTargetX = (e) => {
    const { name, value } = e.target;
    if (name === 'family4') {
      onChangeX('target', {
        ...values.target, [name]: value, family3: null, family2: null,
      });
    } else if (name === 'family3') {
      onChangeX('target', { ...values.target, [name]: value, family2: null });
    } else {
      onChangeX('target', { ...values.target, [name]: value });
    }
  };
  const handleEdit = () => {
    onChangeX('isOpenActions', false);
    onChangeX('isOpenAdd', !values.isOpenAdd);
    if (values.target) {
      onChangeX('target', {
        ...values.target,
        family4: values.target.level4?.id ? values.target.level4 : null,
        family3: values.target.level3?.id ? values.target.level3 : null,
        family2: values.target.level2?.id ? values.target.level2 : null,
      });
    }
  };
  const onSubmitFamilyEdit = async () => {
    try {
      const body = { ...values.target, brand: values.target.brand ? values.target.brand.id : null };
      await updateFamily(body);
      toast.success('Famille est bien modifiée');
      handleEdit();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleChangeInput = (event) => {
    onChange({ target: { name: 'page', value: 0 } });
    onChange(event);
  };
  const getFamilyLevels = useCallback((parentId) => {
    if (!parentId) {
      return {};
    }
    const parentOneFamily = familiesGrp.map[parentId];
    if (!parentOneFamily) {
      return {};
    }
    // is level 4 is parent => get only 4
    const isOneF4 = families1.find((f4) => f4.id === parentOneFamily.id);
    if (isOneF4) {
      return { level4: parentOneFamily };
    }
    // is level 3 is parent => get 3 & 4
    const parentUpFamily = familiesGrp.map[parentOneFamily.parentId];
    if (!parentUpFamily) {
      return { [`level${parentOneFamily.familyLevel}`]: parentOneFamily };
    }
    const isUpF4 = families1.find((f4) => f4.id === parentUpFamily.id);
    if (isUpF4) {
      return { level4: parentUpFamily, level3: parentOneFamily };
    }
    // is level 2 is parent => get 2, 3 & 4
    const parentLastFamily = familiesGrp.map[parentUpFamily.parentId];
    const isLastF4 = families1.find((f4) => f4.id === parentLastFamily?.id);
    if (isLastF4) {
      return { level4: parentLastFamily, level3: parentUpFamily, level2: parentOneFamily };
    }

    return {};
  }, [familiesTop, familiesGrp, families1]);

  const families = familiesTop
    .filter((family) => !values.search
    || family.id.toLowerCase().includes(values.search.toLowerCase()))
    .slice(values.page * values.size, values.page * values.size + values.size)
    .map((family) => {
      const { level2, level3, level4 } = getFamilyLevels(family.parentId);
      return {
        ...family, level2, level3, level4, onHandleAction: onHandleActions,
      };
    }) || [];
  return (
    <div style={{ height: '100vh' }}>
      <Box sx={{
        padding: '20px 10px 20px 25px',
        overflowY: 'auto',
        height: 'calc(100vh - 104px)',
      }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ font: 'normal 600 28px Montserrat', mb: 2 }}>List des Familles</Typography>
          <ButtonApp
            label="AJOUTER"
            onClick={onHandleAdd}
            style={{ marginBottom: 13 }}
            startIcon={<AddIcon />}
          />
        </Box>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          background: '#E9EBED 0% 0% no-repeat padding-box',
          borderRadius: 12,
          opacity: 1,
          padding: '10px 7px',
          marginBottom: 2,
        }}
        >
          <TextField
            variant="outlined"
            onChange={handleChangeInput}
            value={values.search}
            label="chercher"
            size="small"
            name="search"
            style={{ width: 200 }}
            className={classes.filter}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
        </Box>

        <EnhancedTable
          rows={families}
          headCells={columns}
          count={familiesTop.length}
          rowsPerPageOptions={[10, 15, 20]}
          onChangeRowsPerPage={onChangeSize}
          onChangePage={onChangePage}
        />
        <ActionsFamily
          onHandleEdit={handleEdit}
          onHandleDelete={handleDelete}
          onClose={() => onChangeX('isOpenActions', false)}
          isOpen={values.isOpenActions}
          anchorEl={values.anchorEl}
        />
        { values.isOpenConfirm && (
        <ConfirmRemoveDialog
          title="Suppresion de famille"
          content={`Voulez vous vraiment supprimer la famille ${values.target?.name} ?`}
          handleClose={handleDelete}
          handleClick={handleConfirmDelete}
        />
        )}
        <DialogCmp
          title={values.target?.isAdd ? 'Ajouter Une Famille' : 'Modification de Famille'}
          endIcon={false}
          fullWidth
          maxWidth="md"
          open={values.isOpenAdd}
          handleClose={onHandleAdd}
          onHandleSubmit={values.target?.isAdd ? onSubmitFamily : onSubmitFamilyEdit}
          isOkDisabled={!values.canSubmit}
          labelOk="Ajouter"
          styleOk={{ height: 30 }}
          styleKo={{ height: 30 }}
        >
          <Box sx={{ borderRadius: '12px', background: '#38485c1c', p: 2 }}>
            <Input
              value={values.target?.id}
              onChange={onChangeTargetX}
              margin="dense"
              style={{ width: '190px' }}
              label="Code"
              name="id"
              disabled={!values.target?.isAdd}
            />
            <Input
              value={values.target?.name}
              onChange={onChangeTargetX}
              margin="dense"
              style={{ width: '395px', maxWidth: '395px' }}
              label="Intitulé"
              name="name"
            />
            <AutoCompleteCmp
              options={places}
              onChange={(e, value) => onChangeTargetX({ target: { name: 'place', value } })}
              value={values.target?.place}
              getOptionLabel={(op) => op}
              label="Emplacement"
              isPrimaryTheme
              style={{
                width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
            <AutoCompleteCmp
              options={brands || []}
              onChange={(e, value) => onChangeTargetX({ target: { name: 'brand', value } })}
              getOptionLabel={(op) => op.name}
              value={values.target?.brand}
              label="Marques"
              isPrimaryTheme
              style={{
                width: '50%', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
            <Input
              value={values.target?.remise}
              onChange={onChangeTargetX}
              margin="dense"
              style={{ width: '50%', maxWidth: '375px' }}
              label="Remise"
              type="number"
              name="remise"
            />
            <AutoCompleteCmp
              options={families1}
              onChange={(e, value) => onChangeTargetX({ target: { name: 'family4', value } })}
              value={values.target?.family4}
              label="Niveau 1"
              isPrimaryTheme
              style={{
                width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
            <AutoCompleteCmp
              options={families3}
              onChange={(e, value) => onChangeTargetX({ target: { name: 'family3', value } })}
              value={values.target?.family3}
              label="Niveau 2"
              isPrimaryTheme
              disabled={!values.target?.family4}
              style={{
                width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
            <AutoCompleteCmp
              options={families2}
              onChange={(e, value) => onChangeTargetX({ target: { name: 'family2', value } })}
              value={values.target?.family2}
              label="Niveau 3"
              isPrimaryTheme
              disabled={!values.target?.family3}
              style={{
                width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
          </Box>

        </DialogCmp>
      </Box>
    </div>
  );
};

export default Families;
