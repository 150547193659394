import api, { secureGet, securePost, secureDelete } from 'config/apiConfig';
import { format } from 'date-fns';
import { generateKey } from 'Utils/ulilities';

export const defaultMessage = 'Erreur système';
export const editCLient = async (client) => {
  const url = '/ci/api/EditClient';
  const { data } = await securePost(url, client);
  return data;
};
export const getCLientByCode = async (code) => {
  const url = `/ci/api/GetClientByCode?code=${code}`;
  const { data } = await secureGet(url);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};
export const addCLientContact = async (contact) => {
  const url = '/ci/api/Client/Contact';
  const { data } = await securePost(url, contact);
  return data;
};
export const deleteCLientContact = async (contactId) => {
  const url = `/ci/api/Client/Contact?id=${contactId}`;
  const { data } = await secureDelete(url);
  return data;
};

export const generateReleve = async (filters) => {
  const url = '/ci/api/client/invoices';
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, filters, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = generateKey('releve', filters.clientCode, format(filters.startDate, 'dd/MM/yyy'));
  link.click();
  link.remove();
  return data;
};

export const generateInvoices = async (reference) => {
  const url = `/ci/api/client/${reference}`;
  const { data } = await api.get(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = reference;
  link.click();
  link.remove();
  return data;
};

export const getRegelementByClient = async (code) => {
  const { data } = await securePost(`/ci/api/GetDashboardByClientCode?client_code=${code}`);
  if (data.statusCode && data.statusCode !== '000') {
    throw new Error(data.statusLabel);
  }
  const {
    nonRegle, impaye, encours, ...other
  } = data;
  const regles = nonRegle.reduce((prev, current) => {
    const next = {
      ...current,
      monthId: format(new Date(current.date), 'MM/yyyy'),
    };
    if (current.commandeId.includes('H')) {
      return { ...prev, nonRegleH: [...prev.nonRegleH, next] };
    }
    return { ...prev, nonReglePm: [...prev.nonReglePm, next] };
  }, { nonReglePm: [], nonRegleH: [] });
  const nonReglePm = Object.groupBy(regles.nonReglePm, ({ monthId }) => monthId);
  const nonRegleH = Object.groupBy(regles.nonRegleH, ({ monthId }) => monthId);
  return {
    ...other, nonReglePm, nonRegleH, encoursList: encours, impayes: impaye,
  };
};

export const addNote = async (body) => {
  const url = '/ci/api/Client/note';
  const { data } = await securePost(url, body);
  return data;
};

export const deleteNote = async (id) => {
  const url = `/ci/api/Client/note/${id}`;
  const { data } = await secureDelete(url);
  return data;
};
