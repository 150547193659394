import { Box, Typography, IconButton } from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import EditIcon from '@mui/icons-material/Edit';
import EnhancedTable from 'Shared/EnhancedTable';
import { useLocation } from 'react-router-dom';
import {
  columns,
  findStartAndEndDate,
  TitleStyle,
  defaultColumnStyle,
} from 'Components/Catalog/ProductsTable/constants';
import { format } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { getDatesInRange } from 'Utils/ulilities';
import ProductContext, { Provider, initialState } from 'Components/Catalog/ProductsTable/Context';
import ProductReducer from 'Components/Catalog/ProductsTable/reducer';
import ConnectedStore from 'Shared/ConnectedStore';
import ReferencialContext from 'Shared/ReferencialContext';
import useForm from 'Shared/Hooks/useForm';
import Input from 'Shared/Input/v2';
import ButtonApp from 'Shared/Button';
import DialogCmp from 'Shared/Dialog';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import Quantity from 'Shared/Quantity';
import { toast } from 'react-toastify';
import useDebounce from 'Shared/Hooks/useDebounce';
import useLocalStorage from 'Shared/Hooks/useLocalStorage';
import useStyles from './style';
import { getPropositions, savePropositions } from '../PropositionServices';
import useTargetProducts from './useTargetProducts';
import PropositionFilters from '../PropositionFilters';
import useRemoveProducts from './useRemoveProducts';
import usePropositionProductNotes from './usePropositionProductNotes';
import Notes from './Notes';
import ActionButton from './ActionButton';
import useNewManualProduct from './useNewManualProduct';

const AddProposition = () => {
  const classes = useStyles();
  const { state: params, search } = useLocation();
  const lastAddedIds = params?.lastAddedIds;
  const checkOptions = params?.checkOptions;
  const quantitiesDates = params?.datesFilter;
  const [editName, setEditName] = useState(true);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [productList, setProductList] = React.useState();
  const queryParams = new URLSearchParams(search);
  const reference = queryParams.get('reference');
  const name = queryParams.get('name') || '';
  const { values, onChange } = useForm({ name });
  const { values: filterValues, onChange: onChangeFilter } = useForm({
    search: '',
    date: null,
    status: '',
    isMiniSearch: true,
    canRemove: false,
  });
  const searchBounce = useDebounce(filterValues.search, 600);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [isPropositionValidated, setIsPropositionValidated] = React.useState(false);
  const [isMineProposition, setIsMineProposition] = React.useState(true);
  const [productDetailList, setProductDetailList] = React.useState([]);
  const [pageProduct] = React.useState(0);
  const [sizeProduct] = React.useState(10);
  const [currentProductNote, setCurrentProductNote] = React.useState('');
  const [openNewProduct, setOpenNewProduct] = useState(false);
  const [user] = useLocalStorage('user');

  const onHandleNewProduct = () => {
    setOpenNewProduct(!openNewProduct);
  };
  const {
    state: { expandedColumnsIds },
  } = useContext(ProductContext);
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const [
    quantityForm, onChangeQyantityForm,
    onChangeQuantity, canSubmitManualProduct, onSubmitManualProduct,
  ] = useNewManualProduct({
    productRef: null, productName: null, quantity: 1,
  }, onHandleNewProduct);
  useEffect(() => {
    if (!productList && referencial.products) {
      setProductList(referencial.products.filter((p) => p.iA));
    }
  }, [referencial.products, productList]);
  useEffect(async () => {
    if (reference && user) {
      const response = await getPropositions({ id: reference });
      setIsPropositionValidated(response.content[0].status === 'VALIDATED');
      setIsMineProposition(response.content[0].creatorId === user.id);
    }
  }, [reference, user]);
  const {
    targetProduct, getProductIdsByProposition, productsProposition,
  } = useTargetProducts(reference, checkOptions);
  const {
    targetDeletionProduct, onAddProducts, onSubmitRemove,
  } = useRemoveProducts(reference, lastAddedIds);
  const {
    isNotesOpen, onHandleNotes, notes, noteLoading, saveNote, deleteNote, propositionProductId,
  } = usePropositionProductNotes();

  const handleNotes = useCallback((row) => {
    setCurrentProductNote(row.reference);
    onHandleNotes(row.productPropositionId);
  });

  const productsFiltred = useMemo(() => {
    const produits = (productList || [])
      .filter((po) => targetProduct.ids.includes(po.productId))
      .map((po) => {
        if (productsProposition?.length > 0) {
          const productP = productsProposition.find((pp) => pp.productId === po.productId);
          return {
            ...po,
            quantityOrder: productP?.quantityOrder,
            productPropositionId: productP?.id,
            hasProductPropositionNotes: productP?.totalNotes > 0,
            productDetails: productP?.productDetails,
          };
        }
        return po;
      });
    if (productsProposition.length > 0) {
      const manualProducts = productsProposition
        .filter((pp) => pp.manualProduct !== null)
        .map((pp) => ({
          quantityOrder: pp.quantity,
          productDetails: pp.productDetails,
          isManualProduct: true,
          productPropositionId: pp.id,
          hasProductPropositionNotes: pp.totalNotes > 0,
          ...pp.manualProduct,
        }));
      return [...produits, ...manualProducts];
    }
    return produits;
  }, [productList, targetProduct, productsProposition]);
  const regularColumns = useMemo(() => {
    const [action, ...rest] = columns(handleNotes);
    return [
      {
        ...action,
        onClick: (event) => {
          setProductDetailList((prev) => prev.map((p) => ({
            ...p,
            checked: event.target.checked,
          })));
          // setAllChecked(event.target.checked);
          // setUnSelectedList([]);
          // setSelectedList([]);
        },
      },
      ...rest,
    ];
  }, []);
  const [columnList, setColumnList] = useState(regularColumns);
  const expandColumn = (index, expand) => {
    const prefix = index === 6 ? 'first' : 'last';
    if (expand) {
      // dispatch(updateColumnIds([...expandedColumnsIds, columnList[index].id].unique()));
      const { startDate, endDate } = findStartAndEndDate({});
      const newColumns = new Set();
      let expandedRowList = [];
      const unique = Date.now();
      productDetailList.forEach((pd) => {
        let expandedRow = {};
        const list = prefix === 'first' ? pd.buyedList : pd.saledList;
        list
          .filter((b) => b.productId === pd.productId)
          .forEach((e) => {
            const key = `${prefix}-${unique}-${format(new Date(e.date), 'MMM yy', { locale: fr })}`;
            newColumns.add(new Date(e.date));
            expandedRow = {
              ...expandedRow,
              [key]: expandedRow[key] ? expandedRow[key] + e.quantity : e.quantity,
            };
          });
        expandedRowList = [...expandedRowList, expandedRow];
      });
      const newColumnList = [
        ...new Set(
          getDatesInRange(startDate, endDate).map((col) => format(new Date(col), 'MMM yy', { locale: fr })),
        ),
      ].map((c) => ({
        id: `${prefix}-${unique}-${c}`,
        label: c,
        style: { ...TitleStyle, textAlign: 'center' },
        expandIndex: prefix,
        render: ({ expandedRowValue }) => (
          <Typography
            style={{ ...defaultColumnStyle, width: 50, textAlign: 'center' }}
            display="inline"
          >
            {expandedRowValue}
          </Typography>
        ),
      }));
      if (expandedRows.length > 0) {
        setExpandedRows(expandedRows.map((e, i) => ({ ...e, ...expandedRowList[i] })));
      } else {
        setExpandedRows([...expandedRowList]);
      }
      setColumnList([
        ...columnList.slice(0, index + 1),
        ...newColumnList,
        ...columnList.slice(index + 1, columnList.length),
      ]);
    } else {
      const newExpandedList = [...expandedColumnsIds];
      newExpandedList.splice(columnList[index].id, 1);
      // dispatch(updateColumnIds(newExpandedList));
      setColumnList(columnList.filter((c) => c.expandIndex !== prefix));
      const expandedRowList = [...expandedRows];
      setExpandedRows(
        expandedRowList
          .map((e) => {
            const clonseE = { ...e };
            Object.keys(e).forEach((k) => {
              if (k.startsWith(prefix)) {
                delete clonseE[k];
              }
            });
            return clonseE;
          })
          .filter((e) => Object.keys(e).length !== 0),
      );
    }
  };

  /* const getProducts = useCallback(({ page, size }) => {
    if (targetProduct.ids.length === 0) {
      return;
    }
    setPageProduct(page || pageProduct);
    setSizeProduct(size || sizeProduct);
    // const productsResp = await getProductDetails({ ids: targetProduct.ids });
    // setProducts(productsResp);
  },
  [targetProduct]); */

  // save proposition
  const canSubmit = isMineProposition && targetProduct.ids.length > 0 && values.name.length > 0;
  const onHandleSave = async () => {
    if (!canSubmit) {
      return;
    }
    setLoading(true);
    try {
      const saveProposition = {
        id: reference || null,
        name: values.name,
        products: targetProduct.ids.map((pId) => ({ productId: pId, quantity: 0 })),
      };
      await savePropositions(saveProposition, quantitiesDates);
      setOpenSuccess(true);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  // remove product from proposition
  const onOpenRemovePopup = () => {
    setOpenRemove(!openRemove);
  };
  const callBackRemoveProduct = () => {
    if (reference) {
      getProductIdsByProposition({});
    }
  };
  const onHandleRemoveProduct = () => {
    setLoading(true);
    try {
      onOpenRemovePopup();
      onSubmitRemove(callBackRemoveProduct);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (reference) {
      getProductIdsByProposition({ search: searchBounce });
    }
  }, [reference, searchBounce]);
  useEffect(() => {
    /* if (products.length === 0) {
      return;
    } */
    const details = productsFiltred
      ?.slice(pageProduct * sizeProduct, (pageProduct + 1) * sizeProduct)
      .map((p) => {
        if (p.isManualProduct) {
          return {
            ...p,
            designation: p.name,
            checked: targetDeletionProduct.ids.includes(p.productPropositionId || p.productId),
            checkProduct: () => onAddProducts([p.productPropositionId || p.productId]),
          };
        }
        // const productDetails = products.find((pd) => pd.id === p.productId);
        return {
          ...p,
          ...p?.productDetails,
          stock: p.sD + p.sB,
          checked: targetDeletionProduct.ids.includes(p.productPropositionId || p.productId),
          buyedCount: p?.productDetails?.buyedList
            ?.filter(
              (b) => b.productId === p.productId,
              // && startDate <= new Date(b.date)
              // && endDate >= new Date(b.date),
            )
            .reduce((prev, curr) => prev + curr.quantity, 0),
          saledCount: p?.productDetails?.saledList
            ?.filter(
              (b) => b.productId === p.productId,
              //    && startDate <= new Date(b.date)
              //    && endDate >= new Date(b.date),
            )
            .reduce((prev, curr) => prev + curr.quantity, 0),
          checkProduct: () => onAddProducts([p.productPropositionId || p.productId]),
        };
      });
    setProductDetailList(details);
  }, [productsFiltred, onAddProducts, targetDeletionProduct]);

  useEffect(() => {
    onChangeFilter({ target: { name: 'canRemove', value: isMineProposition && !isPropositionValidated && targetDeletionProduct.ids.length > 0 } });
  }, [targetDeletionProduct, isPropositionValidated, isMineProposition]);

  const onHandleAddNote = (comment, parentId) => {
    // mark product as has note
    setProductDetailList(
      productDetailList.map((pd) => {
        if (pd.productPropositionId === propositionProductId && !pd.hasProductPropositionNotes) {
          return { ...pd, hasProductPropositionNotes: true };
        }
        return pd;
      }),
    );
    saveNote(comment, parentId);
  };
  const onHandleRemoveNote = (noteId, isEmpty) => {
    // mark product as no note
    if (isEmpty) {
      setProductDetailList(
        productDetailList.map((pd) => {
          if (pd.productPropositionId === propositionProductId) {
            return { ...pd, hasProductPropositionNotes: false };
          }
          return pd;
        }),
      );
    }
    deleteNote(noteId);
  };
  const onHandleSaveManulaProduct = () => {
    try {
      setLoading(true);
      onSubmitManualProduct(reference);
      callBackRemoveProduct();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div style={{ height: '100vh' }}>
      {loading && <GlobalLoader size="100vh" />}
      <DialogCmp
        title="Ajouter A Un Produit Manuel"
        fullWidth={false}
        open={openNewProduct}
        handleClose={onHandleNewProduct}
        labelKo="ANNULER"
        labelOk="ENREGISTRÉ"
        endIcon={<SaveIcon />}
        styleKo={{ padding: '10px' }}
        isOkDisabled={!canSubmitManualProduct}
        onHandleSubmit={onHandleSaveManulaProduct}
      >
        <Box sx={{ display: 'flex', m: '0 12px' }}>
          <Input
            value={quantityForm.productRef}
            onChange={onChangeQyantityForm}
            style={{ width: '190px' }}
            label="RÉFERANCE"
            name="productRef"
          />
          <Input
            value={quantityForm.productName}
            onChange={onChangeQyantityForm}
            style={{ width: '190px' }}
            label="Désignation"
            name="productName"
          />
          <Quantity
            value={quantityForm.quantity}
            quantity={quantityForm.quantity}
            onChangeQuantity={onChangeQuantity}
          />
        </Box>
      </DialogCmp>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        labelKo="ok"
        labelOk="Voir La Proposition"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box className={classes.successCheck}>
            <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            La lists des produits sélectionner a été bien ajouter
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openRemove}
        handleClose={onOpenRemovePopup}
        onHandleSubmit={onHandleRemoveProduct}
        labelKo="Non"
        labelOk="Oui"
        styleOk={{
          background: '#F86060 0% 0% no-repeat padding-box',
          boxShadow: '0px 4px 10px #BEBEBE50',
        }}
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box className={classes.successCheck} style={{ backgroundColor: '#F86060' }}>
            <DeleteIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            Êtes-vous sûr de vouloir supprimer ce produit ?
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title="Note"
        subTitle={`Ref: ${currentProductNote} - Proposition N°${reference}`}
        endIcon={false}
        fullWidth
        open={isNotesOpen}
        handleClose={onHandleNotes}
        withAction={false}
      >
        <Notes
          notes={notes}
          onSubmit={onHandleAddNote}
          onRemove={onHandleRemoveNote}
          loading={noteLoading}
        />
      </DialogCmp>
      <Box className={classes.container}>
        <Box className={classes.title}>
          {editName ? (
            <Box className={classes.nameEdit}>
              <Typography className={classes.name}>
                {values.name !== '' ? values.name : 'Nom du proposition'}
              </Typography>
              <IconButton
                style={{ color: 'black', display: !isMineProposition || isPropositionValidated ? 'none' : 'unset' }}
                aria-label="edit"
                onClick={() => setEditName(false)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Box>
          ) : (
            <Input
              label="Nom de proposition"
              name="name"
              style={{ width: 200 }}
              value={values.name}
              onChange={onChange}
              onBlur={() => setEditName(true)}
            />
          )}
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ActionButton
              id={reference}
              name={name}
              classes={classes}
              onHandleNewProduct={onHandleNewProduct}
              isPropositionValidated={isPropositionValidated}
              isMine={isMineProposition}
            />
          </Box>
        </Box>
        <PropositionFilters
          onHandleRemove={onOpenRemovePopup}
          values={filterValues}
          onChange={onChangeFilter}
          totalSelected={targetDeletionProduct.ids.length}
        />
        <EnhancedTable
          getData={reference && getProductIdsByProposition}
          rows={productDetailList}
          headCells={columnList}
          count={targetProduct.totalElements}
          rowsPerPageOptions={[10, 15, 20]}
          expandColumn={expandColumn}
          expandedRows={expandedRows}
          regularColumns={regularColumns}
        />
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonApp
            onClick={() => null}
            style={{
              background: '#FFF',
              color: 'black',
              marginRight: 18,
              boxShadow: '0px 4px 10px #BEBEBE50',
              height: '40px',
            }}
            label="ANNULER"
          />
          <ButtonApp
            disabled={isPropositionValidated || !canSubmit}
            onClick={onHandleSave}
            label="ENREGISTRÉ"
            endIcon={<SaveIcon />}
            style={{ cursor: canSubmit ? 'pointer' : 'not-allowed', height: '40px' }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ConnectedStore(AddProposition, ProductReducer, initialState, Provider);
