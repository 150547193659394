import { IconButton } from '@mui/material';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const EditSaveActions = ({
  isEdit, onHandleSave, onHandleClose, onHandleEdit,
}) => {
  if (!isEdit) {
    return (
      <IconButton
        onClick={onHandleEdit}
        size="large"
        sx={{
          color: '#007BC6',
          backgroundColor: '#FFF',
          border: '1px solid #007BC6',
          borderRadius: 1.5,
          padding: 0.5,
          boxShadow: '0px 4px 10px #108CD629',
          marginLeft: 1.1,
        }}
      >
        <EditIcon size="small" />
      </IconButton>
    );
  }
  return (
    <>
      <IconButton
        onClick={onHandleSave}
        sx={{
          backgroundColor: '#FFF',
          borderRadius: 1.5,
          padding: 0.5,
          boxShadow: '0px 4px 10px #108CD629',
          marginLeft: 1.1,
          color: '#00B894',
          border: '1px solid #00B894',
        }}
        size="large"
      >
        <SaveIcon size="small" />
      </IconButton>
      <IconButton
        onClick={onHandleClose}
        sx={{
          backgroundColor: '#FFF',
          borderRadius: 1.5,
          padding: 0.5,
          boxShadow: '0px 4px 10px #108CD629',
          marginLeft: 1.1,
          color: '#767676',
          border: '1px solid #767676',
        }}
        size="large"
      >
        <CloseIcon size="small" />
      </IconButton>
    </>
  );
};

export default EditSaveActions;
