import {
  Box, FilledInput, FormControl, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { format } from 'date-fns';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import Input from 'Shared/Input/v2';
import { useSelector } from 'react-redux';
import {
  makeSelectCities, makeSelectExpeditions, makeSelectPaymentModes, makeSelectZones,
} from 'selectors';
import { addCLient } from 'Components/Recovery/RecoveryServices';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import useForm from 'Shared/Hooks/useForm';
import {
  addCLientContact, addNote, deleteCLientContact, editCLient,
} from '../ClientServices';
import {
  BLOCKING, CONTACTS, NOTESTYPE, TYPES,
} from '../constants';

const Edit = ({
  fields, client, onChange, currentMode, codeClient, name, onClose,
}) => {
  const cities = useSelector(makeSelectCities());
  const areas = useSelector(makeSelectZones());
  const expeditions = useSelector(makeSelectExpeditions());
  const paymentModes = useSelector(makeSelectPaymentModes());
  const { values: formNote, onChange: onchangeNote } = useForm({ comment: '' });
  const { values: formContact, onChange: onChangeContact, reset: onResetContact } = useForm({
    firstName: '', lastName: '', type: '', value: '', open: false,
  });
  const [loading, setLoading] = useState(false);
  const isContact = ['addContact', 'editContact'].includes(currentMode);
  const isNote = ['addNote', 'editNote'].includes(currentMode);

  const handleInputChange = (prop) => (event, value) => {
    if (typeof event?.target === 'object' && event?.target.type === 'file') {
      onChange({ target: { name: prop, value: event?.target.files[0] } });
      return;
    }
    onChange({
      target: {
        name: prop,
        value: event instanceof Date
          ? event : value || event?.target.value,
      },
    });
  };

  useEffect(() => {
    onChange({ target: { name: 'expedition', value: typeof client.expedition === 'string' ? (expeditions || []).find((e) => e.name === client.expedition) : client.expedition } });
    onChange({
      target: {
        name: 'payment',
        value:
    typeof client.payment === 'string' ? (paymentModes || [])
      .find((e) => e.name === client.payment) : client.payment,
      },
    });
  }, [expeditions, paymentModes]);

  const lists = useMemo(() => ({
    cities,
    areas,
    expeditions: expeditions || [],
    paymentModes: paymentModes || [],
    types: TYPES,
    blockings: BLOCKING,
    contacts: CONTACTS,
    noteType: NOTESTYPE,
  }),
  [cities, areas, expeditions, paymentModes]);
  const requestApi = (query) => {
    if (!client.id) {
      return addCLient({
        ...client,
        expeditionCode: client.expedition?.code,
        paymentCode: client.payment?.code,
        // eslint-disable-next-line no-nested-ternary
        blocage: name === 'setting' ? client.blocage === 'Automatique' ? 0 : 1 : client.blocage,
        blocageTerme: name === 'setting' && client.blocageTerme ? format(new Date(client.blocageTerme), "yyyy-MM-dd'T'HH:mm:ssXX") : client.blocageTerme,
      });
    }
    switch (query) {
      case 'addContact':
        return addCLientContact({
          ...formContact,
          [formContact.type.toLocaleLowerCase()]: formContact.value,
          clientCode: client.code,
        });
      case 'editContact':
        return addCLientContact({
          ...formContact,
          [formContact.type.toLocaleLowerCase()]: formContact.value,
          clientCode: codeClient,
        });

      case 'deleteContact':
        return deleteCLientContact(client?.code);

      case 'addNote':
        // eslint-disable-next-line no-case-declarations
        const formData = new FormData();
        // if (client.file) { formData.append('attachment', client.file); }
        if (formNote.comment) { formData.append('comment', formNote.comment.trim()); }
        formData.append('clientCode', client.code);
        // if (client.noteId) { formData.append('id', client.noteId); }
        return addNote(formData);

      default:
        return editCLient({
          ...client,
          expeditionCode: client.expedition?.code,
          paymentCode: client.payment?.code,
          // eslint-disable-next-line no-nested-ternary
          blocage: client.blocage === 'Automatique' ? 0 : 1,
          blocageTerme: name === 'setting' && client.blocageTerme ? format(new Date(client.blocageTerme), "yyyy-MM-dd'T'HH:mm:ssXX") : client.blocageTerme,
        });
    }
  };

  const addNewContact = () => {
    if (formContact.open) {
      onResetContact();
    } else {
      onChangeContact({ target: { name: 'open', value: true } });
    }
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      const data = await requestApi(currentMode);
      if (isNote) {
        onChange({ target: { name: 'notes', value: [...client.notes, data] } });
        onchangeNote({ target: { name: 'comment', value: '' } });
      } else if (isContact) {
        onChange({ target: { name: 'contacts', value: [...client.contacts, data] } });
        addNewContact();
      }
      toast.success('la modification est excutée avec succès');
      // close pop up
      onClose();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = (field) => {
    if (!client.id || !field.disabled) {
      return false;
    }
    return typeof field.disabled === 'function' ? field.disabled(client) : field.disabled;
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <ButtonApp
        onClick={handleEdit}
        disabled={!client.code || isContact || isNote}
        label="Enregister"
        style={{
          height: 31, position: 'absolute', top: 33, right: 36,
        }}
      />
      {isContact && !formContact.open && (
        <ButtonApp
          onClick={addNewContact}
          label="Ajouter un contact"
          startIcon={<AddIcon />}
          style={{
            background: 'transparent', boxShadow: 'none', color: '#108CD6', height: 32,
          }}
        />
      )}
      <RenderOnDemandThenHide visible={isNote}>
        <Box style={{ padding: '24px 24px 0px 24px' }}>
          <FormControl variant="filled" fullWidth>
            <FilledInput
              id="component-filled"
              style={{
                backgroundColor: '#fff',
                borderRadius: 4,
                // '& .MuiFilledInput-underline:before': { borderWidth: '0 !important' },
              }}
              disableUnderline={false}
              value={formNote.comment}
              name="comment"
              onChange={onchangeNote}
              // onKeyPress={addComment(0, comment)}
              multiline
              rows={3}
              fullWidth
            />
          </FormControl>
          <ButtonApp
            onClick={handleEdit}
            label="Ajouter"
            style={{ float: 'right', marginTop: 6 }}
            disabled={formNote.comment.trim().length === 0}
          />
        </Box>
      </RenderOnDemandThenHide>
      <Box sx={{ padding: '20px', margin: 0 }}>
        {!isContact && fields.map((field) => (
          field.type === 'select' ? (
            <AutoCompleteCmp
              options={lists[field.listName] || []}
              onChange={handleInputChange(field.id)}
              value={client[field.id]}
              label={field.label}
              getOptionLabel={field.optionLabel}
              // filterOptions={field.filter}
              isPrimaryTheme
              style={{
                width: '240px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
              }}
            />
          ) : (
            <Input
              value={client[field.id]}
              onChange={handleInputChange(field.id)}
              margin="dense"
              style={{ width: '240px', maxWidth: '240px' }}
              label={field.label}
              name={field.id}
              disabled={isDisabled(field)}
            />
          )
        ))}
        {formContact.open && fields.map((field) => (
          <>
            {field.type === 'select' ? (
              <AutoCompleteCmp
                options={lists[field.listName] || []}
                onChange={(e, value) => onChangeContact({ target: { name: field.id, value } })}
                value={formContact[field.id]}
                label={field.label}
                isPrimaryTheme
                getOptionLabel={field.optionLabel}
                // filterOptions={field.filter}
                style={{
                  width: '240px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
                }}
              />
            ) : (
              <Input
                value={formContact[field.id]}
                onChange={onChangeContact}
                margin="dense"
                style={{ width: '240px', maxWidth: '240px' }}
                label={field.label}
                name={field.id}
              />
            )}
          </>
        ))}
        {formContact.open && (
        <Box sx={{ mt: 1, mb: 1, display: 'contents' }}>
          <IconButton
            onClick={handleEdit}
            sx={{
              backgroundColor: '#FFF',
              borderRadius: 1.5,
              padding: 0.5,
              boxShadow: '0px 4px 10px #108CD629',
              color: '#00B894',
              border: '1px solid #00B894',
            }}
            size="large"
          >
            <SaveIcon size="small" />
          </IconButton>
          <IconButton
            onClick={addNewContact}
            sx={{
              backgroundColor: '#FFF',
              borderRadius: 1.5,
              padding: 0.5,
              boxShadow: '0px 4px 10px #108CD629',
              marginLeft: 1,
              color: '#767676',
              border: '1px solid #767676',
            }}
            size="large"
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
        )}
      </Box>
    </>
  );
};

export default Edit;
