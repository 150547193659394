import React from 'react';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { format } from 'date-fns';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};

export const defaultColumns = [
  {
    id: 'name',
    label: 'Intitulé client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
    order: true,
  },
  {
    id: 'code',
    label: 'Code Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
    order: true,
  },
  {
    id: 'city',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'expedition',
    label: 'Transport',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'payment',
    label: 'Mode de règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'encours',
    label: 'Encours Pièces moteur',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'encoursHuile',
    label: 'Encours Huile',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,

  },
];

export const basedColumns = (onHandleActions) => [
  {
    id: 'code',
    label: 'Code Client',
    style: {
      ...TitleStyle,
      position: 'sticky',
      left: 0,
      zIndex: 800,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>{value}</Typography>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 0,
      zIndex: 800,
      background: '#f9fafc',
    },
    order: true,
  },
  {
    id: 'name',
    label: 'Intitulé client',
    style: {
      ...TitleStyle,
      position: 'sticky',
      left: 80,
      zIndex: 800,
    },
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 230 }}>{value}</Typography>
    ),
    parentRowStyle: {
      position: 'sticky',
      left: 80,
      zIndex: 800,
      background: '#f9fafc',
    },
    order: true,
  },
  {
    id: 'city',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'expedition',
    label: 'Transport',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'payment',
    label: 'Mode de règlement',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'zone',
    label: 'Zone',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'createdOn',
    label: 'Date création',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value ? format(new Date(value), 'dd/MM/yyy') : '--'}
      </Typography>
    ),
  },
  {
    id: 'ice',
    label: 'ICE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'type',
    label: 'type',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <IconButton onClick={(e) => onHandleActions(row, e)}>
        <MoreHorizIcon />
      </IconButton>
    ),
  },
];

export const clientCustomColumns = [
  {
    id: 'phone',
    label: 'Numéro de téléphone',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 180 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'nbrJourEcheancePm',
    label: 'Condition de paiement facture',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 100 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'nbrJourEcheanceH',
    label: 'condition de paiement huile',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 100 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'encoursHuile',
    label: 'Encours Huile',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'encours',
    label: 'encours PM',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 100 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'eligibleAuPortPaye',
    label: 'éligible au port payé',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'nbrReleveAuthorise',
    label: 'nombre de relevé autorisé',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'raisonSociale',
    label: 'ID fiscal',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'patente',
    label: 'Patente',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 130 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'rc',
    label: 'R.C',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 100 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'address',
    label: 'Adresse',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 230 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'email',
    label: 'email',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 180 }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'blocked',
    label: 'statu',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, width: 80 }}>
        {value ? 'blocké' : '-'}
      </Typography>
    ),
  },
];

export function moveActionToEnd(arr) {
  return arr.sort((a, b) => {
    if (a.id === 'action') return 1; // Push 'action' to the end
    if (b.id === 'action') return -1; // Push 'action' to the end
    return 0; // Keep other items as they are
  });
}
