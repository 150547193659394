/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import {
  Box, Paper, Popper, TableSortLabel, TextField, Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useContext, useMemo } from 'react';

import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext, { setSelectedBrand, setSelectedFamily } from 'Shared/ReferencialContext';

import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import CustomTablePagination from 'Shared/CustomTablePagination';
import CachedIcon from '@mui/icons-material/Cached';
import Product from './Product';
import ProductDetail from './ProductDetail';
import useStyles from './styles';

const initFilters = {
  brand: [],
  family1: [],
  family2: [],
  family3: [],
  family4: [],
};
const Sort = [
  { name: 'price', label: 'Prix' },
  { name: 'reference', label: 'Réference' },
  { name: 'stock', label: 'Stock' },
];
const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
const Products = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: { referencial, selectedFamily, selectedBrand },
  } = useContext(ReferencialContext);
  const { brands } = referencial;
  const families1 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 1);
  }, [referencial.families]);
  const families2 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 2);
  }, [referencial.families]);
  const families3 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 3);
  }, [referencial.families]);
  const families4 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 4);
  }, [referencial.families]);
  const [values, setValues] = React.useState(initFilters);
  const [open, setOpen] = React.useState(false);
  const [sort, setSort] = React.useState(null);
  const [order, setOrder] = React.useState('asc');
  const [product, setProduct] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [filtredBrands, setFiltredBrands] = React.useState();
  const [filtredFamilies1, setFiltredFamilies1] = React.useState();
  const [filtredFalimies2, setFiltredFalimies2] = React.useState();
  const [filtredFamilies3, setFiltredFamilies3] = React.useState();
  const [filtredFamilies4, setFiltredFamilies4] = React.useState();
  const [productList, setProductList] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(24);
  const [view, setView] = React.useState('card');
  useEffect(() => {
    if (!productList && referencial.products) {
      setProductList(referencial.products.filter((p) => p.iA));
    }
  }, [referencial.products, setProductList, productList]);
  useEffect(() => {
    if (!filtredFamilies1 && families1 && families1.length) {
      setFiltredFamilies1(families1);
    }
  }, [families1, setFiltredFamilies1, filtredFamilies1]);
  useEffect(() => {
    if (!filtredFalimies2 && families2 && families2.length) {
      setFiltredFalimies2(families2);
    }
  }, [families2, setFiltredFalimies2, filtredFalimies2]);
  useEffect(() => {
    if (!filtredFamilies3 && families3 && families3.length) {
      setFiltredFamilies3(families3);
    }
  }, [families3, setFiltredFamilies3, filtredFamilies3]);
  useEffect(() => {
    if (!filtredFamilies4 && families4 && families4.length) {
      setFiltredFamilies4(families4);
    }
  }, [families4, setFiltredFamilies4, filtredFamilies4]);
  useEffect(() => {
    if (!filtredBrands && brands && brands.length) {
      setFiltredBrands(brands);
    }
  }, [brands, setFiltredBrands, filtredBrands]);
  const handleOrderChange = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };
  const handleSortChange = (value) => {
    setSort(value);
  };
  const handleChangeView = () => {
    setView(view === 'card' ? 'list' : 'card');
  };
  const handleClose = () => {
    setOpen(false);
    setProduct(null);
  };
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleInputChange = (prop, fIds = [], extra = []) => (event, value) => {
    let toChange = null;
    if (search) {
      setSearch('');
    }
    if (prop.includes('family')) {
      const reset = fIds
        ? fIds.reduce((prev, curr) => ({ ...prev, [`family${curr}`]: [] }), {})
        : {};
      const resetExtra = extra
        ? extra.reduce((prev, curr) => ({ ...prev, [`${curr}`]: [] }), {})
        : {};
      toChange = {
        ...values,
        // eslint-disable-next-line no-nested-ternary
        [prop]: value || [],
        ...reset,
        ...resetExtra,
      };
      const { products = [] } = referencial;
      const cloneArr = [...products.filter((p) => p.iA)];
      const {
        brand, family4, family1, family2, family3,
      } = toChange;
      const productArr = cloneArr.filter(
        (po) => (!brand.length || brand.map((b) => b.id).includes(po.bId))
          && (!family4.length || family4.map((f4) => f4.id).includes(po.fId))
          && (!family3.length || family3.map((f3) => f3.id).includes(po.fThree))
          && (!family2.length || family2.map((f2) => f2.id).includes(po.fTwo))
          && (!family1.length || family1.map((f1) => f1.id).includes(po.fOne)),
      );
      setFiltredBrands(
        toChange.family1.length
          ? brands.filter((b) => productArr.map((po) => po.bId).includes(b.id))
          : brands,
      );
      setProductList(productArr);
      let filtredFamilyList2 = null;
      let filtredFamilyList3 = null;
      let filtredFamilyList4 = null;
      const familyIds = toChange.brand.length
        ? [
          ...productArr.filter((po) => po.fId).map((po) => po.fId),
          ...productArr.filter((po) => po.fOne).map((po) => po.fOne),
          ...productArr.filter((po) => po.fThree).map((po) => po.fThree),
          ...productArr.filter((po) => po.fTwo).map((po) => po.fTwo),
        ].unique()
        : [];
      if (prop === 'family1') {
        filtredFamilyList2 = families2.filter(
          (family) => (!toChange.family1.length
              || toChange.family1.map((f1) => f1.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFalimies2(filtredFamilyList2);
      }
      if (['family1', 'family2'].includes(prop)) {
        filtredFamilyList3 = families3.filter(
          (family) => ((filtredFamilyList2 || filtredFalimies2).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId))))
            && (!toChange.family2.length
              || toChange.family2.map((f2) => f2.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFamilies3(filtredFamilyList3);
      }
      if (['family3', 'family2', 'family1'].includes(prop)) {
        filtredFamilyList4 = families4.filter(
          (family) => ((filtredFamilyList3 || filtredFamilies3).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId)))
              || ((filtredFamilyList2 || filtredFalimies2)
                .map((f) => f.id)
                .includes(family.parentId)
                && (!toChange.family2.length
                  || toChange.family2.map((f2) => f2.id).includes(family.parentId))))
            && (!toChange.family3.length
              || toChange.family3.map((f3) => f3.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFamilies4(filtredFamilyList4);
      }
    } else {
      toChange = {
        ...values,
        // eslint-disable-next-line no-nested-ternary
        [prop]: value || [],
      };
      const { products = [] } = referencial;
      const cloneArr = [...products.filter((p) => p.iA)];
      const {
        brand, family4, family1, family2, family3,
      } = toChange;
      const productArr = cloneArr.filter(
        (po) => (!brand.length || brand.map((b) => b.id).includes(po.bId))
          && (!family4.length || family4.map((f4) => f4.id).includes(po.fId))
          && (!family3.length || family3.map((f3) => f3.id).includes(po.fThree))
          && (!family2.length || family2.map((f2) => f2.id).includes(po.fTwo))
          && (!family1.length || family1.map((f1) => f1.id).includes(po.fOne)),
      );
      setProductList(productArr);
      setFiltredBrands(brands.filter((b) => productArr.map((po) => po.bId).includes(b.id)));
      const familyIds = toChange.brand.length
        ? [
          ...productArr.filter((po) => po.fId).map((po) => po.fId),
          ...productArr.filter((po) => po.fOne).map((po) => po.fOne),
          ...productArr.filter((po) => po.fThree).map((po) => po.fThree),
          ...productArr.filter((po) => po.fTwo).map((po) => po.fTwo),
        ].unique()
        : [];
      const filtredFamilyList1 = toChange.brand.length
        ? families1.filter((f) => familyIds.includes(f.id))
        : families1;
      if (toChange.family1.length) {
        toChange = {
          ...toChange,
          family1: toChange.family1.filter((f) => filtredFamilyList1.map((f1) => f1.id).includes(f.id)),
        };
      }
      setFiltredFamilies1(filtredFamilyList1);

      const filtredFamilyList2 = families2.filter(
        (family) => (!toChange.family1.length
            || toChange.family1.map((f1) => f1.id).includes(family.parentId))
          && (!toChange.brand.length || familyIds.includes(family.id)),
      );
      if (toChange.family2.length) {
        toChange = {
          ...toChange,
          family2: toChange.family2.filter((f) => filtredFamilyList2.map((f2) => f2.id).includes(f.id)),
        };
      }
      setFiltredFalimies2(filtredFamilyList2);
      const filtredFamilyList3 = families3.filter(
        (family) => ((filtredFamilyList2 || filtredFalimies2).map((f) => f.id).includes(family.parentId)
            || (families1.map((f) => f.id).includes(family.parentId)
              && (!toChange.family1.length
                || toChange.family1.map((f1) => f1.id).includes(family.parentId))))
          && (!toChange.family2.length
            || toChange.family2.map((f2) => f2.id).includes(family.parentId))
          && (!toChange.brand.length || familyIds.includes(family.id)),
      );
      if (toChange.family3.length) {
        toChange = {
          ...toChange,
          family3: toChange.family3.filter((f) => filtredFamilyList3.map((f3) => f3.id).includes(f.id)),
        };
      }
      setFiltredFamilies3(filtredFamilyList3);
      const filtredFamilyList4 = families4.filter(
        (family) => ((filtredFamilyList3 || filtredFamilies3).map((f) => f.id).includes(family.parentId)
            || (families1.map((f) => f.id).includes(family.parentId)
              && (!toChange.family1.length
                || toChange.family1.map((f1) => f1.id).includes(family.parentId)))
            || ((filtredFamilyList2 || filtredFalimies2).map((f) => f.id).includes(family.parentId)
              && (!toChange.family2.length
                || toChange.family2.map((f2) => f2.id).includes(family.parentId))))
          && (!toChange.family3.length
            || toChange.family3.map((f3) => f3.id).includes(family.parentId))
          && (!toChange.brand.length || familyIds.includes(family.id)),
      );
      if (toChange.family4.length) {
        toChange = {
          ...toChange,
          family4: toChange.family4.filter((f) => filtredFamilyList4.map((f4) => f4.id).includes(f.id)),
        };
      }
      setFiltredFamilies4(filtredFamilyList4);
    }
    setValues(toChange);
  };

  const onHandleResetFilters = () => {
    setValues({
      ...initFilters,
    });
    handleSortChange(null);
    handleInputChange('family1', [2, 3, 4], ['brand'])(null, []);
  };
  useEffect(() => {
    if (selectedFamily) {
      setValues({
        brand: [],
        family1: [],
        family2: [],
        family3: [],
        family4: [],
        [`family${selectedFamily.familyLevel}`]: [selectedFamily],
      });
      dispatch(setSelectedFamily(null));
    }
  }, [dispatch, selectedFamily, setValues]);

  useEffect(() => {
    if (selectedBrand) {
      setValues({
        family1: [],
        family2: [],
        family3: [],
        family4: [],
        brand: [selectedBrand],
      });
      dispatch(setSelectedBrand(null));
    }
  }, [dispatch, selectedBrand, setValues]);
  const productsFiltred = useMemo(() => {
    const produits = (productList || []).filter(
      (po) => !search
        || (po.reference && po.reference.toLowerCase().includes(search.toLowerCase()))
        || (po.des && po.des.toLowerCase().includes(search.toLowerCase()))
        || (po.e && po.e.toLowerCase().includes(search.toLowerCase()))
        || (po.eF && po.eF.toLowerCase().includes(search.toLowerCase()))
        || (po.d && po.d.toLowerCase().includes(search.toLowerCase()))
        || (po.dimensions2 && po.dimensions2.toLowerCase().includes(search.toLowerCase()))
        || (po.rO && po.rO.toLowerCase().includes(search.toLowerCase())),
    );
    return !sort
      ? produits
      : produits.sort((a, b) => {
        if (sort.name === 'price') {
          return order === 'asc' ? a.p - b.p : b.p - a.p;
        }
        if (sort.name === 'stock') {
          return order === 'asc' ? a.sB + a.sD - (b.sB + b.sD) : b.sB + b.sD - (a.sB + a.sD);
        }
        return order === 'asc'
          ? a.reference.localeCompare(b.reference)
          : b.reference.localeCompare(a.reference);
      });
  }, [productList, search, sort, order]);
  return (
    <>
      {open && <ProductDetail product={product} handleClose={handleClose} />}
      <Box className={classes.filterContainer}>
        <Box className={classes.filterZone}>
          <TextField
            variant="outlined"
            onChange={handleChangeSearch}
            value={search}
            label="chercher"
            size="small"
            className={classes.filter}
            style={{ width: 200 }}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredBrands || []}
            onChange={handleInputChange('brand')}
            value={values.brand}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marques"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies1 || []}
            onChange={handleInputChange('family1', [2, 3, 4])}
            value={values.family1}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 1"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFalimies2 || []}
            onChange={handleInputChange('family2', [3, 4])}
            value={values.family2}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 2"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies3 || []}
            onChange={handleInputChange('family3', [4])}
            value={values.family3}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 3"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies4 || []}
            onChange={handleInputChange('family4')}
            value={values.family4}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 4"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={Sort}
            value={sort}
            onChange={(event, value) => handleSortChange(value)}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.label}
            ListboxComponent={ListboxComponent}
            filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Trier par"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  readOnly: true,
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
                value=""
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Box
            style={{
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              borderRadius: 12,
              opacity: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 7px',
            }}
          >
            <TableSortLabel
              active
              direction={order}
              onClick={handleOrderChange}
              classes={{ icon: classes.icon }}
            />
          </Box>
          <Box
            sx={{
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              borderRadius: '12px',
              opacity: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px 7px',
              cursor: 'pointer',
              ml: 0.6,
            }}
          >
            <CachedIcon
              onClick={onHandleResetFilters}
              style={{ color: '#0681cc' }}
            />
          </Box>
        </Box>
        <Paper
          style={{
            display: 'flex',
            width: 'fit-content',
            cursor: 'pointer',
            height: 'fit-content',
            background: '#E9EBED 0% 0% no-repeat padding-box',
            borderRadius: 12,
            opacity: 1,
            padding: 10,
          }}
          elevation={0}
        >
          <Box
            style={{
              padding: 5,
              borderRadius: 12,
              background:
                view === 'card'
                  ? 'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box'
                  : 'transparent',
              boxShadow: view === 'card' ? '0px 4px 10px #108CD650' : 'none',
            }}
            onClick={handleChangeView}
          >
            <AppsIcon style={{ color: view === 'card' ? '#FFF' : '#707070' }} />
          </Box>
          <Box
            style={{
              padding: 5,
              borderRadius: 12,
              background:
                view === 'list'
                  ? 'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box'
                  : 'transparent',
              boxShadow: view === 'list' ? '0px 4px 10px #108CD650' : 'none',
            }}
            onClick={handleChangeView}
          >
            <ListIcon style={{ color: view === 'list' ? '#FFF' : '#707070' }} />
          </Box>
        </Paper>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          flexDirection: view === 'card' ? 'row' : 'column',
        }}
      >
        {(productsFiltred || [])
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((po) => (
            <Product product={po} key={po?.id} view={view} />
          ))}
      </Box>
      {!productsFiltred?.length && <Box style={{ height: '80vh', width: '100%' }} />}
      <Box style={{ display: 'flex' }}>
        <Box style={{ flexGrow: 1 }} />
        <CustomTablePagination
          rowsPerPageOptions={[24, 48, 60]}
          component="div"
          count={productsFiltred.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Products;
