import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonApp from 'Shared/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { generateKey, roundPrice } from 'Utils/ulilities';
import { downloadInvoice } from 'Components/Orders/OrdersService';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import {
  columnsIN,
  columnsINDetails, columnsReg, columnsRegDetails, TABS_ID,
} from '../constants';
import { generateReleve } from '../ClientServices';

export const HeaderData = ({ columns = [], sx = { paddingRight: '228px', paddingLeft: '40px' }, noColor = false }) => (
  <Box sx={{
    background: !noColor && '#38485C1A',
    borderBottom: noColor && '1px solid #38485C1A',
    borderRadius: 1.4,
    display: 'flex',
    p: 2.1,
    ...sx,
  }}
  >
    {columns.map((cel) => (
      <Typography
        sx={{ flex: cel.width || 1, color: '#707070', fontWeight: 600 }}
        key={cel.id}
      >
        {cel.label}
      </Typography>
    ))}
  </Box>
);

const RowData = ({
  clientCode, row = {}, columns = [],
  detailColumns = [], isChild = false, regle = false, souche = null,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const generateInvoice = async (isReleve, filters) => {
    try {
      setLoading(true);
      if (isReleve) {
        const [month, year] = filters.monthId.split('/').map(Number);
        const references = filters.details.map((d) => d.commandeId);
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        endDate.setHours(23, 59, 59, 999);
        await generateReleve({
          startDate, endDate, regle: !regle, souche, clientCode, references,
        });
      } else {
        await downloadInvoice(row.commandeId);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box sx={{
        borderBottom: '1px solid #38485C1A',
        p: 2.1,
        display: 'flex',
      }}
      >
        <RenderOnDemandThenHide visible={!isChild}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <RemoveCircleOutlineIcon style={{ color: '#007BC6' }} /> : <AddCircleOutlineIcon />}
          </IconButton>
        </RenderOnDemandThenHide>
        {columns.map((cel) => (
          <Typography
            sx={{ flex: 1, color: '#38485C', fontWeight: 600 }}
            key={`data_${cel.id}`}
          >
            {cel.format ? cel.format(row[cel.id]) : row[cel.id]}
          </Typography>
        ))}
        { (souche !== null || isChild) && (
          <ButtonApp
            style={{ background: 'transparent', color: '#007BC6', boxShadow: 'none' }}
            label={isChild ? 'visualiser facture' : 'visualiser toutes factures'}
            startIcon={loading ? <CircularProgress /> : <VisibilityIcon />}
            disabled={loading}
            onClick={() => generateInvoice(!isChild, row)}
          />
        )}
      </Box>
      <RenderOnDemandThenHide visible={open}>
        <Box sx={{ border: '1px solid #3C88CF', borderRadius: 1 }}>
          <HeaderData columns={detailColumns} sx={{ paddingRight: '228px' }} noColor />
          {row.details?.map((item) => <RowData row={item} columns={detailColumns} isChild />)}
        </Box>
      </RenderOnDemandThenHide>
    </>
  );
};
const FinancialData = ({ tab, clientCode, regData = { nonReglePm: {}, nonRegleH: {} } }) => (
  <Box sx={{ p: 2 }}>
    { [TABS_ID.REG, TABS_ID.NO_REG].includes(tab) && (
      <>
        <HeaderData columns={columnsReg} />
        {Object.keys(regData[tab === TABS_ID.REG ? 'nonReglePm' : 'nonRegleH'] || {})?.map((monthId = '') => {
          const details = regData[tab === TABS_ID.REG ? 'nonReglePm' : 'nonRegleH'][monthId];
          const row = {
            monthId,
            amount: roundPrice(details.reduce((acc, current) => acc + current.amount, 0)),
            dateEcheance: details[0].dateEcheance,
            details,
          };
          return (
            <RowData
              key={generateKey('row', tab, monthId)}
              row={row}
              columns={columnsReg}
              clientCode={clientCode}
              detailColumns={columnsRegDetails}
              regle
              souche={tab === TABS_ID.REG ? 'PM' : 'HUILE'}
            />
          );
        })}
      </>
    )}
    { tab === TABS_ID.IMP && (
      <>
        <HeaderData columns={columnsIN} />
        {regData.impayes?.map(({ echeances: details, ...row }) => (
          <RowData
            key={generateKey('row', tab, row.reference)}
            row={{ details, ...row }}
            columns={columnsIN}
            clientCode={clientCode}
            detailColumns={columnsINDetails}
          />
        ))}
      </>
    )}
    { tab === TABS_ID.IN_REG && (
      <>
        <HeaderData columns={columnsIN} />
        {regData.encoursList?.map(({ echeances: details, ...row }) => (
          <RowData
            key={generateKey('row', tab, row.reference)}
            row={{ details, ...row }}
            columns={columnsIN}
            clientCode={clientCode}
            detailColumns={columnsINDetails}
          />
        ))}
      </>
    )}
  </Box>
);

export default FinancialData;
