import { Button, Grid, Typography } from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import { TYPES_COMMANDES } from 'Shared/constants/order';
import { Fields, REPORTED_STATUS } from './constants';
import { editOrder, getCommerciaux } from '../../RecoveryServices';
import { refreshOrders } from '../../action';
import ControleOrdersContext from '../../context';
import useStyles from './style';

const OrderEdit = ({
  order, update, resetUpdate,
}) => {
  const {
    state: {
      referencial: { clients: clientList, expeditions, paymentModes },
    },
  } = useContext(ReferencialContext);
  const classes = useStyles();
  const { dispatch } = useContext(ControleOrdersContext);
  const history = useHistory();
  const { state: order_ } = useLocation();
  const currentOrder = useMemo(() => order || order_, [order, order_]);
  const [commercials, setCommercials] = useState([]);
  const [values, setValues] = useState({
    ...currentOrder,
    expedition:
        typeof currentOrder.expedition === 'string'
          ? (expeditions || []).find((e) => e.name === currentOrder.expedition)
          : currentOrder.expedition,
    payment:
      typeof currentOrder.payment === 'string'
        ? (paymentModes || []).find((e) => e.name === currentOrder.payment)
        : currentOrder.payment,
  });

  const [loading, setLoading] = useState(false);
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  useEffect(async () => {
    const data = await getCommerciaux();
    setCommercials(data);
  }, []);
  const lists = useMemo(
    () => ({
      expeditions: expeditions || [],
      paymentModes: paymentModes || [],
      sales: commercials || [],
      types: TYPES_COMMANDES,
    }),
    [clientList, expeditions, paymentModes],
  );
  const handleEdit = async () => {
    try {
      setLoading(true);
      const { reported, ...restValues } = values;
      await editOrder({
        ...restValues,
        id: restValues.currentReference,
        commercial: restValues.commercial.id,
        expedition: restValues.expedition?.code,
        paymentMode: restValues.paymentMode?.code,
        reported: reported !== REPORTED_STATUS.REPORTED,
      });
      toast.success('Commande modifié avec succès');
      history.push('/controle-orders', { tab: 3 });
      dispatch(refreshOrders(true));
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
      resetUpdate();
    }
  };
  useEffect(() => {
    if (update && !loading) {
      handleEdit();
    }
  }, [handleEdit, update, loading]);

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Grid
        container
        spacing={2}
        style={{
          padding: order ? 0 : '20px',
          margin: 0,
          width: '100%',
          marginBottom: '26px',
        }}
      >
        {Fields.map((field) => (
          <Grid xs={order ? 2 : 6} item>
            <RenderInput
              styleInput={classes.inputStyle}
              styleLabel={classes.labelStyle}
              input={
                field.type === 'autocomplete'
                  ? { ...field, [field.listName]: lists[field.listName] }
                  : field
              }
              value={values[field.id]}
              handleChange={handleInputChange(field.id)}
            />
            {field.type === 'text' && values[field.id]?.length > 255 && (
              <Typography style={{ color: 'red', fontSize: 11 }}>
                Attention, vous avez atteint la limite de 255 caractères.
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
      {false && (
        <Grid
          container
          spacing={2}
          style={{ padding: '20px', margin: 0, width: '100%' }}
          direction="row-reverse"
        >
          <Button variant="contained" color="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Grid>
      )}
    </>
  );
};

export default OrderEdit;
