import {
  Box, Button, Typography,
} from '@mui/material';
import React, {
  useCallback, useContext, useState,
} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import EnhancedTable from 'Shared/EnhancedTable';
import DialogCmp from 'Shared/Dialog';
import useForm from 'Shared/Hooks/useForm';
import ReferencialContext from 'Shared/ReferencialContext';
import ActionsRow from 'Shared/EnhancedTable/ActionsRow';
import Filters from './Filters/Filters';
import {
  addNewDiscount, defaultMessage, deleteDiscountsById, getDiscounts, updateNewDiscount,
} from './DiscountSettingsServices';
import { columns } from './constants';
import NewDiscount from './NewDiscount';
import { INIT_FORM, isValidNewAdd, TYPES } from './NewDiscount/constants';

const DiscountSettings = () => {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);

  const {
    brands, clients, products, families: allFamilies,
  } = referencial;
  const { values, onChange } = useForm(
    {
      reference: [], brand: [], family: [], client: [],
    },
  );
  const { values: formAdd, onChange: onChangeAdd, reset } = useForm(INIT_FORM);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [resetSort, setResetSort] = useState(false);
  const [discounts, setDiscounts] = useState({});
  const [openActions, setOpenActions] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const getDiscountsCall = useCallback(async (sortParams) => {
    setLoading(true);
    try {
      const discountList = await getDiscounts(sortParams, {
        reference: values.reference?.map((r) => r.reference),
        brand: values.brand?.map((b) => b.id),
        family: values.family?.map((f) => f.id),
        client: values.client?.map((c) => c.code),
      });
      setDiscounts(discountList);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setDiscounts, values]);

  const onHandleAdd = () => {
    setOpenAdd(!openAdd);
    reset(INIT_FORM);
  };
  const onHandleSumbitAdd = async () => {
    if (!isValidNewAdd(formAdd)) {
      toast.error('Remplir les champs obligatoires et vérifier les quantités min et max');
      return;
    }
    try {
      setLoading(true);
      // call service to add new discount
      const resp = formAdd.id ? await updateNewDiscount(formAdd) : await addNewDiscount(formAdd);
      if (resp.statusCode === '400') {
        toast.error(resp.statusLabel);
      } else {
        toast.success(formAdd.id ? 'Remise modifiée avec succès' : 'Remise ajoutée avec succès');
      }
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
      onHandleAdd();
      getDiscountsCall({});
    }
  };
  const handleChange = (e) => {
    onChange(e);
    setResetSort(!resetSort);
  };
  const onHandleActions = (row) => (e) => {
    setOpenActions(!openActions);
    setAnchorEl(e.currentTarget);
    if (!openActions) {
      reset(
        {
          ...row,
          brand: row.brand ? brands?.find((brand) => brand.id === row.brand) : null,
          client: row.client ? clients?.find((cl) => cl.code === row.client) : null,
          family: row.family ? allFamilies?.find((it) => it.id === row.family) : null,
          reference: row.reference
            ? products?.find((cl) => cl.reference.toString() === row.reference.toString())
            : null,
          // eslint-disable-next-line no-nested-ternary
          type: row.brand ? TYPES[1] : (row.family ? TYPES[0] : TYPES[2]),
          discount: row.remise,
        },
      );
    }
  };
  const onHandleEdit = () => {
    setOpenAdd(true);
  };
  const onCloseActions = useCallback(() => {
    setOpenConfirmDelete(true);
    setOpenActions(false);
  }, [setOpenConfirmDelete, setOpenActions]);
  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await deleteDiscountsById(formAdd.id);
      toast.success('Remise supprimé avec succès');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
      setOpenConfirmDelete(false);
      setUpdateTable((prev) => !prev);
    }
  }, [setLoading, formAdd]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <DialogCmp
        title=""
        maxWidth="sm"
        endIcon={false}
        fullWidth
        open={openConfirmDelete}
        handleClose={() => setOpenConfirmDelete(false)}
        onHandleSubmit={handleDelete}
        labelKo="Non"
        labelOk="Oui"
        actionStyle={{ display: 'flex', justifyContent: 'center' }}
        styleOk={{
          height: '38px',
          background: '#F86060',
          boxSdow: '0px 4px 10px #BEBEBE50',
          border: '1px solid #EBEDED',
          borderRadius: '12px',
          padding: '0 24px',
        }}
        styleKo={{
          height: '38px',
          background: '#FFFFFF',
          boxShadow: '0px 4px 10px #BEBEBE50',
          border: '1px solid #EBEDED',
          borderRadius: '12px',
          padding: '0 24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: '80%',
            margin: 'auto',
            gap: '26px',
          }}
        >
          <Box sx={{
            width: '114px',
            height: '114px',
            background: '#F86060',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <DeleteOutlineIcon fontSize="large" sx={{ color: '#FFF' }} />
          </Box>
          <Typography sx={{
            textAlign: 'center',
            font: 'normal normal medium 28px/38px Montserrat',
            color: '#000000',
          }}
          >
            Êtes-vous sûr de vouloir supprimer cette remise ?
          </Typography>
        </Box>
      </DialogCmp>
      <Box sx={{
        height: '100vh', overflowY: 'auto', p: 2, boxSizing: 'border-box',
      }}
      >
        <DialogCmp
          title={formAdd.id ? 'Modifier La Remise' : 'Ajouter Une Remise'}
          endIcon={false}
          fullWidth={false}
          open={openAdd}
          handleClose={onHandleAdd}
          onHandleSubmit={onHandleSumbitAdd}
          labelKo="ANNULER"
          labelOk={formAdd.id ? 'ENREGISTRER' : 'AJOUTER'}
        >
          <NewDiscount
            brands={brands}
            values={formAdd}
            clients={clients}
            isEdit={formAdd.id}
            products={products}
            onChange={onChangeAdd}
            allFamilies={allFamilies}
          />
        </DialogCmp>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Typography sx={{
            font: 'normal normal 600 32px/39px Montserrat',
            color: '#000000',
            flexGrow: 1,
          }}
          >
            Paramètres De Remise
          </Typography>
          <Button
            sx={{
              background: 'transparent linear-gradient(239deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD650',
              borderRadius: '12px',
              font: 'normal normal 600 15px Montserrat',
              color: '#FFFFFF',
              textTransform: 'none',
            }}
            startIcon={<AddIcon sx={{ color: '#FFFFFF' }} />}
            onClick={onHandleAdd}
          >
            Ajouter
          </Button>
        </Box>
        <Filters values={values} onChange={handleChange} />
        <EnhancedTable
          resetSort={resetSort}
          getData={getDiscountsCall}
          rows={discounts?.content || []}
          update={updateTable}
          headCells={columns(onHandleActions)}
          count={discounts?.totalElements || 0}
          rowsPerPageOptions={[10, 15, 20]}
        />
        <ActionsRow
          onHandleDelete={onCloseActions}
          onHandleEdit={onHandleEdit}
          onClose={onHandleActions}
          isOpen={openActions}
          anchorEl={anchorEl}
        />
      </Box>
    </>
  );
};

export default DiscountSettings;
