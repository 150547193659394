import { format } from 'date-fns';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { Typography } from '@mui/material';
import ActionsRow from 'Shared/EnhancedTable/ActionsRow';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';
import useActionsRow from 'Shared/Hooks/useActionsRow';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import { defaultMessage, getPaymentDocuments, removeDocument } from '../PaymentServices';
import { getDateWithoutTime } from '../Utils';
import { columns, PAYEMENT_STATUSES } from './constants';
import Filters from './Filters';
import { Fields } from './Filters/constants';

const PaymentDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [paymentDocuments, setPaymentDocuments] = useState({ content: [] });
  const [openRemove, setOpenRemove] = useState(false);
  const {
    onHandleActions, selectedRow: selectedPayment, openActions, anchorEl,
  } = useActionsRow();
  const history = useHistory();

  const [values, setValues] = useState(
    Fields.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.defaultValue || null }), {}),
  );
  const getPaymentList = useCallback(async (sort) => {
    try {
      setLoading(true);
      const {
        date, echeanceDate, nePasVerse, ...rest
      } = values;
      const additionalFields = Object.keys(rest).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: (rest[curr]?.code ? rest[curr]?.code : rest[curr]) || undefined,
        }), {},
      );
      const data = await getPaymentDocuments(sort,
        {
          nePasVerse: nePasVerse ? nePasVerse === 'Oui' : null,
          startDate: date?.start ? format(getDateWithoutTime(date?.start), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          endDate: date?.end ? format(getDateWithoutTime(date?.end), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          echeanceStartDate: echeanceDate?.start ? format(getDateWithoutTime(echeanceDate?.start), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          echeanceEndDate: echeanceDate?.end ? format(getDateWithoutTime(echeanceDate?.end), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          ...additionalFields,
        });
      setPaymentDocuments(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setPaymentDocuments, setLoading, values]);

  const paymentDocs = useMemo(
    () => paymentDocuments.content
      .map((p) => ({
        ...p,
        date: p.receptionDate,
        clientCode: p.client?.code,
        clientName: p.client?.name,
        statusLabel: PAYEMENT_STATUSES.find((r) => r.code === p.status)?.label,
      })),
    [paymentDocuments.content],
  );

  const actions = [
    {
      label: 'Consulter',
      icon: <VisibilityIcon />,
      onClick: () => history.push(`/payment/${selectedPayment.id}/details`),
    },
    {
      label: 'Supprimer',
      icon: <DeleteForeverOutlinedIcon style={{ color: '#F86060' }} />,
      color: '#F86060',
      onClick: () => setOpenRemove(true),
    },
  ];

  const onRemoveDocument = async () => {
    try {
      setLoading(true);
      await removeDocument(selectedPayment.id);
      const nextDoc = paymentDocuments.content.filter((p) => p.id !== selectedPayment.id);
      setPaymentDocuments({ ...paymentDocuments, content: nextDoc });
      toast.success('Le réglement a été supprimé!');
      setOpenRemove(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Typography sx={{ fontWeight: 600, fontSize: '25px', mb: 2.5 }}>Gestion Des Règlements</Typography>
      <Filters values={values} onChange={setValues} />
      {openRemove && (
      <ConfirmRemoveDialog
        title="Suppresion de famille"
        content={`Voulez vous vraiment supprimer le réglement ${selectedPayment?.reference} ?`}
        handleClose={() => setOpenRemove(false)}
        handleClick={onRemoveDocument}
      />
      )}
      <EnhancedTable
        getData={getPaymentList}
        rows={paymentDocs}
        headCells={columns(onHandleActions)}
        count={paymentDocuments.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
      <ActionsRow
        onClose={onHandleActions}
        isOpen={openActions}
        anchorEl={anchorEl}
        actions={actions}
      />
    </>
  );
};

export default PaymentDocuments;
