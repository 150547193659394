import { Button, Chip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { ROLES } from '../constants';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
export const columns = (onHandleEdit) => [
  {
    id: 'username',
    label: 'Username',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'lastName',
    label: 'Nom',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'firstName',
    label: 'Prénom',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'email',
    label: 'Email',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'role',
    label: 'Role',
    style: TitleStyle,
    render: ({ value }) => value && (
    <Chip label={value} style={ROLES.find((r) => r.label === value)?.style} size="small" />
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Button
        variant="outlined"
        color="primary"
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: '1px solid #38485C',
          borderRadius: '12px',
          width: 40,
          padding: 5,
          minWidth: 'unset',
        }}
        onClick={() => onHandleEdit(row)}
      >
        <EditIcon style={{ color: '#38485C' }} />
      </Button>
    ),
  },
];

export const roles = [
  'ADMIN', 'SALES', 'FACTURATION', 'TRANSFERT', 'APSC', 'GAME_MASTER', 'COMMERCIAL,SERVICE_CLIENT', 'COMPTA', 'ROLE_CLIENT', 'RECOUVREMENT', 'SAV', 'sREGLEMENT', 'BUYER',
  'BUYER_MANAGER',
];
