import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PaymentDocuments from './PaymentDocuments';
import Clients from './Clients/Clients';
import Orders from './Orders';
import PaymentDetails from './PaymentDetails';

const Payment = ({ path }) => (
  <div>
    <Box style={{
      overflowY: 'auto', height: 'calc(100vh - 64px)', paddingLeft: '12px', paddingRight: '12px',
    }}
    >
      <Switch>
        <Route exact path={`${path}`} component={PaymentDocuments} />
        <Route exact path={`${path}/:id/details`} component={PaymentDetails} />
        <Route exact path={`${path}/clients`} component={Clients} />
        <Route exact path={`${path}/orders`} component={Orders} />
      </Switch>
    </Box>
  </div>
);

export default Payment;
