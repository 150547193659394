import { Box, Typography } from '@mui/material';
import React from 'react';

const Bar = ({
  title, currentAmount = 0, amount = 0, color,
}) => {
  const percent = Math.min(amount !== 0 ? currentAmount / amount : 0, 1);

  return (
    <Box sx={{
      background: `${color}45`,
      height: 35,
      borderRadius: 1.5,
      m: 0.7,
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      p: '0 5px',
      position: 'relative',
      justifyContent: 'space-between',
    }}
    >
      <Box sx={{
        background: color, width: percent, position: 'absolute', height: 35, borderRadius: 1.5, zIndex: '-1', left: 0,
      }}
      />
      <Typography sx={{ color: '#FFF', fontWeight: 600 }}>{`${title} - ${Math.ceil(currentAmount * 100) / 100}`}</Typography>
      <Typography sx={{ color: '#FFF', fontWeight: 600 }}>{`${Math.ceil(percent * 100)}%`}</Typography>
      <Typography sx={{ fontWeight: 600 }}>{amount}</Typography>
    </Box>
  );
};

const FinancialBar = ({ clientInfo }) => {
  const {
    plafond, plafondHuile, totalNonRegle,
    totalNonReglePm, totalNonRegleHuile,
    totalImpayeHuile, totalRegEncoursHuile,
    totalImpayePm, totalRegEncoursPm,
    totalImpaye, totalRegEncours,
  } = clientInfo;

  return (
    <Box>
      <Bar title="GLOBAL" currentAmount={totalNonRegle + totalImpaye + totalRegEncours} amount={plafond + plafondHuile} color="#F86060" />
      <Box sx={{ display: 'flex' }}>
        <Bar title="PM" currentAmount={totalNonReglePm + totalImpayePm + totalRegEncoursPm} amount={plafond} color="#00B894" />
        <Bar title="HUILE" currentAmount={totalNonRegleHuile + totalImpayeHuile + totalRegEncoursHuile} amount={plafondHuile} color="#EAC644" />
      </Box>
    </Box>
  );
};

export default FinancialBar;
