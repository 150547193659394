import {
  ListItemIcon, Fade, List, ListItem, Paper, Popper, Typography,
  ListItemText,
} from '@mui/material';
import React, { useRef } from 'react';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';

const defaultActions = [
  { id: 1, label: 'Modifier', icon: <EditOutlinedIcon /> },
  { id: 2, label: 'Voir Situation financeière', icon: <BarChartIcon /> },
  {
    id: 3,
    label: 'Supprimer',
    icon: <DeleteForeverOutlinedIcon style={{ color: '#F86060' }} />,
    color: '#F86060',
  },
];

const ActionsRow = ({
  isOpen, anchorEl, onHandleEdit, onHandleDelete, onClose, onHandleLine, actions = defaultActions,
}) => {
  const refActions = useRef();
  useOnClickOutside(refActions, (e) => onClose({})(e));
  const defaultFun = (action) => {
    if (action.id === 1) return onHandleEdit;
    if (action.id === 2) return onHandleLine;

    return onHandleDelete;
  };
  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      ref={refActions}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <List component="nav" aria-labelledby="nested-list-subheader">
              {actions.map((action) => (
                <RenderOnDemandThenHide
                  key={`action_${action.label}`}
                  visible={!!(action.onClick || defaultFun(action))}
                >
                  <ListItem button onClick={action.onClick || defaultFun(action)}>
                    <ListItemIcon>
                      {action.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={(
                        <Typography sx={{
                          font: 'normal 600 13px Montserrat', color: action.color || '#38485C',
                        }}
                        >
                          {action.label}
                        </Typography>
                  )}
                    />
                  </ListItem>
                </RenderOnDemandThenHide>
              ))}
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ActionsRow;
