import React, { useEffect, useState } from 'react';
import {
  deleteCLientContact,
} from '../ClientServices';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';

const Contact = ({
  client, getClient, editMode = false, onChange, onClose,
}) => {
  const [editedContact, setEditedContact] = useState(null);
  useEffect(() => {
    if (!editMode) {
      getClient();
    }
  }, [editMode]);

  const handleDelete = async (data) => {
    await deleteCLientContact(data.id);
    getClient();
  };

  const handleEdit = ({ id }) => {
    const aa = client?.contacts.find((item) => item.id === id);
    setEditedContact(aa);
  };

  return (
    <>
      {editMode && (
      <Edit fields={Fields} client={editedContact || client} onChange={onChange} codeClient={client?.code} currentMode={editedContact ? 'editContact' : 'addContact'} onClose={onClose} />
      )}
      <View fields={Fields} client={client} currentMode="contactMode" handleDelete={handleDelete} handleEdit={handleEdit} />
    </>
  );
};

export default Contact;
