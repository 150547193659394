import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { getCLientByCode } from 'Components/Client/ClientServices';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Identification from 'Components/Client/Identification';
import FinancialSituation from 'Components/Client/FinancialSituation';
import Settings from 'Components/Client/Settings';
import Contact from 'Components/Client/Contact';
import Note from 'Components/Client/Note';
import CloseIcon from '@mui/icons-material/Close';
import useLocalStorage from 'Shared/Hooks/useLocalStorage';
import useForm from 'Shared/Hooks/useForm';
import ButtonApp from 'Shared/Button';
// import { useDispatch, useSelector } from 'react-redux';
import { BLOCKING } from 'Components/Client/constants';
import { defaultMessage } from '../RecoveryServices';
import useStyles from './style';

const RecoveryView = ({ handleClose, clientCode: id, editMode = false }) => {
  const user = useLocalStorage('user');
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { values: client, onChange, reset: resetClient } = useForm({});
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();
  const getClientData = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      setLoading(true);
      const data = await getCLientByCode(id);
      const blockV = data.blocage ? BLOCKING[0] : BLOCKING[1];
      const blocage = [false, true].includes(data.blocage) ? blockV : data.blocage;
      resetClient({ ...data, blocage });
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    getClientData();
  }, [getClientData]);
  const isNewClient = editMode && !id;

  if (editMode && id && !client.id) {
    return (<>{loading && <GlobalLoader size="100vh" />}</>);
  }

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        open
        PaperProps={{
          style: {
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 24,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogContent dividers style={{ padding: '30px 30px' }}>
          <Paper elevation={0} classes={{ root: classes.paper }}>
            <Box sx={{ display: 'flex' }}>
              <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Typography
                  display="inline"
                  style={{ font: 'normal normal 600 22px Montserrat', color: '#000000' }}
                >
                  {isNewClient ? 'Nouveau Client' : client?.name}
                </Typography>
                <Chip
                  label={client?.zone}
                  color="primary"
                  style={{
                    display: isNewClient && 'none',
                  }}
                />
              </Box>
              { editMode
                ? (
                  <ButtonApp
                    onClick={handleClose}
                    label="Annuler"
                    style={{
                      marginRight: 105, height: 36, background: '#FFF', color: 'black', boxShadow: 'none',
                    }}
                  />
                )

                : (
                  <IconButton
                    style={{
                      background: '#C5C5C5 0% 0% no-repeat padding-box',
                      borderRadius: 24,
                      opacity: 0.53,
                      height: 39,
                      width: 39,
                    }}
                    size="small"
                    onClick={handleClose}
                  >
                    <CloseIcon style={{ color: '#707070' }} />
                  </IconButton>
                )}
            </Box>
            <Typography
              style={{
                font: 'normal normal 600 20px Montserrat',
                color: '#000000',
                textTransform: 'capitalize',
                marginBottom: 16,
                display: isNewClient ? 'none' : 'unset',
              }}
            >
              {`Client Code: ${id}`}
            </Typography>
            <div className={classes.container}>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                // variant="scrollable"
                TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
                size="small"
              >
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Informations"
                  value={0}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Paramètres"
                  value={4}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  sx={{ display: editMode && 'none' }}
                  label="Situation financière"
                  value={3}
                />
                {!isNewClient && (
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  // disabled={isNewClient}
                  label="Contacts"
                  value={2}
                />
                ) }
                {!isCommercialOrSales && !isNewClient && (
                  <Tab
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    // disabled={isNewClient}
                    label="Notes"
                    value={5}
                  />
                )}
              </Tabs>
            </div>
            <Box
              style={{
                background: '#BEBEBE50 0% 0% no-repeat padding-box',
                borderRadius: 12,
              }}
            >
              {tab === 0 && (
                <Box sx={{ maxHeight: '248px', overflow: 'auto' }}>
                  <Identification
                    client={client}
                    getClient={getClientData}
                    editMode={editMode}
                    onChange={onChange}
                    onClose={handleClose}
                  />
                </Box>
              )}
              {tab === 3 && <FinancialSituation client={client} />}
              {tab === 4 && (
                <Box sx={{ maxHeight: '248px', overflow: 'auto' }}>
                  <Settings
                    client={client}
                    getClient={getClientData}
                    editMode={editMode}
                    onChange={onChange}
                    onClose={handleClose}
                  />
                </Box>
              )}
              {tab === 2 && (
                <Box sx={{ maxHeight: '248px', overflow: 'auto' }}>
                  <Contact
                    client={client}
                    getClient={getClientData}
                    editMode={editMode}
                    onChange={onChange}
                    onClose={handleClose}
                  />
                </Box>

              )}
              {tab === 5 && !isCommercialOrSales && (
                <Box sx={{ maxHeight: '248px', overflow: 'auto' }}>
                  <Note
                    client={client}
                    getClient={getClientData}
                    editMode={editMode}
                    onChange={onChange}
                    onClose={handleClose}
                  />
                </Box>
              )}
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecoveryView;
