import api, { secureGet, securePost } from 'config/apiConfig';
import { format } from 'date-fns';

export const defaultMessage = 'Erreur système';

export const editCLient = async (client) => {
  const url = '/ci/api/EditClient';
  const { data } = await securePost(url, client);
  return data;
};
export const editOrder = async (order) => {
  const url = '/ci/api/editOrder';
  const { data } = await securePost(url, order);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};
export const getCommerciaux = async () => {
  const url = '/ci/api/GetCommerciaux';
  const { data } = await securePost(url);
  return data;
};
export const getBlockedOrders = async () => {
  const { data } = await securePost('/ci/api/GetPendingTransactionHistory');
  return data;
};
export const getAllBlockedOrders = async () => {
  const { data } = await secureGet('/ci/api/GetAllPendingTransactionHistory');
  return data;
};
export const getOrders = async (sortParams, filters = {}, conf, isControl = false) => {
  let url = `/ci/api/${
    !isControl ? 'GetOrderHistorybyCriteria' : 'GetOrderHistorybyCriteriaSavControl'
  }?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (![null, undefined, ''].includes(filters[key])) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url, conf);
  return data;
};
export const addOrder = async (order) => {
  const body = {
    city: order.city,
    clientCode: order.client_code,
    comment: order.comment,
    expedition: order.expedition,
    paymentMode: order.paymentMode,
    inventory: order.inventory,
    souche: order.souche,
    channel: 'DESKTOP',
    uuid: Date.now(),
    products: JSON.stringify(
      order.commande.map((a) => ({
        productId: a.productId,
        quantity: parseInt(a.quantity, 10),
      })),
    ),
    id: order.id,
  };
  const { data } = await securePost('/ci/api/AddTransactionSpecialDesktop', body);
  return data;
};
export const downloadBL = async (id, bl) => {
  const url = `${bl ? '/ci/api/getBl/' : '/ci/api/getFacture/'}${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = id;
  link.click();
  link.remove();
  return data;
};

export const setNoAnomalyOrder = async (reference) => {
  const url = `/ci/api/order/${reference}`;
  const { data } = await securePost(url);
  return data;
};

export const downloadBC = async (id) => {
  const url = `/ci/api/getBc/${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};

export const sentToDecompta = async (decompatList, isAll, unselected, values, total) => {
  const {
    startDate, endDate, numCommande, souche = 0,
  } = values;
  const body = isAll ? {
    unselected,
    filters: {
      type: 7,
      startDate: startDate ? format(
        startDate instanceof Date ? startDate : new Date(startDate),
        'yyyy-MM-dd',
      ) : undefined,
      endDate: endDate ? format(
        endDate instanceof Date ? endDate : new Date(endDate),
        'yyyy-MM-dd',
      ) : undefined,
      numCommande,
      souche,
      total,
    },
  } : decompatList.map((id) => ({ orderId: id }));
  const { data } = await securePost(`/ci/api/decomptabilisation${isAll ? '/multiple' : ''}`, body);
  return data;
};

export const sentToTransfo = async (transfoList, isAll, unselected, values, total) => {
  const {
    startDate, endDate, numCommande, transfoDate, souche = 0,
  } = values;
  const transformationDate = format(transfoDate, 'yyyy-MM-dd');
  const body = isAll ? {
    unselected,
    filters: {
      type: 3,
      startDate: startDate ? format(
        startDate instanceof Date ? startDate : new Date(startDate),
        'yyyy-MM-dd',
      ) : undefined,
      endDate: endDate ? format(
        endDate instanceof Date ? endDate : new Date(endDate),
        'yyyy-MM-dd',
      ) : undefined,
      numCommande,
      souche,
      total,
    },
    transformationDate,
  } : transfoList.map((id) => ({ orderId: id, transformationDate }));
  const { data } = await securePost(`/ci/api/transformation${isAll ? '/multiple' : ''}`, body);
  return data;
};
