import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, IconButton, Typography } from '@mui/material';
import EditSaveActions from 'Shared/Button/EditSaveActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import HeaderItem from 'Components/Import/AchatDocuments/Detail/HeaderItem';
import { format } from 'date-fns';
import EnhancedTable from 'Shared/EnhancedTable';
import useForm from 'Shared/Hooks/useForm';
import ActionsRow from 'Shared/EnhancedTable/ActionsRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useActionsRow from 'Shared/Hooks/useActionsRow';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import {
  detachDocumentsToInvoices, getReglementById, getReglementDetailsById,
  updateOrderPaidAmount, updateReglementDocument,
} from '../PaymentServices';
import { columnsDetails, getLibelleName, PAYEMENT_STATUSES } from '../PaymentDocuments/constants';
import EditHeader from './EditHeader';
import AttachOrder from './AttachOrder';

const PaymentDetails = () => {
  const { id } = useParams();
  const [payment, setPayment] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({ content: [], totalElements: 0 });
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPaidAmount, setIsEditPaidAmount] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { values, onChange, reset } = useForm({});
  const [openAttachOrder, setOpenAttachOrder] = useState(false);
  const { values: echeanceForm, onChange: onChangeEcheance } = useForm({ paidAmount: 0 });
  const history = useHistory();
  const {
    onHandleActions, selectedRow: selectedEcheance, openActions, anchorEl,
  } = useActionsRow();

  const loadRegelement = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      setLoading(true);
      const data = await getReglementById(id);
      setPayment(data);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [id]);
  const loadRegelementDetails = useCallback(async (pageable = { page: 0, size: 10 }) => {
    if (!id) {
      return;
    }
    try {
      setLoading(true);
      const data = await getReglementDetailsById(id, pageable);
      setPaymentDetails(data);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadRegelement();
  }, [loadRegelement]);

  const onHandleEdit = () => {
    setIsEdit(!isEdit);
    if (!isEdit) {
      const status = PAYEMENT_STATUSES.find((item) => item.code === payment.status);
      const dueDate = payment.dueDate ? new Date(payment.dueDate) : null;
      const receptionDate = payment.receptionDate ? new Date(payment.receptionDate) : null;
      reset({
        ...payment, nePasVerse: payment.nePasVerse ? 'Oui' : 'Non', status, dueDate, receptionDate,
      });
    }
  };
  const onHandleSaveHeader = async () => {
    try {
      setLoading(true);
      const nePasVerse = values.nePasVerse === 'Oui';
      const data = await updateReglementDocument({ ...values, nePasVerse });
      setIsEdit(false);
      setPayment(data);
      toast.success('Réglement modifié avec succès');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onHandleOpenEditPaidAmount = () => {
    onChangeEcheance({ target: { name: 'paidAmount', value: selectedEcheance.commande?.paidAmount } });
    setIsEditPaidAmount(!isEditPaidAmount);
  };
  const onDetacheDocument = async () => {
    try {
      setLoading(true);
      await detachDocumentsToInvoices(selectedEcheance.id);
      toast.success('la factures a été bien dettachée');
      const updatedDetails = paymentDetails.content.filter((p) => p.id !== selectedEcheance.id);
      setPaymentDetails({ ...paymentDetails, content: updatedDetails });
      setOpenRemove(false);
      loadRegelement();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSavePaidAmount = async () => {
    try {
      setLoading(true);
      await updateOrderPaidAmount(selectedEcheance.commande.id, echeanceForm.paidAmount);
      toast.success('la factures a été bien dettachée');
      const updatedDetails = paymentDetails.content.map((p) => {
        if (p.commande.id === selectedEcheance.commande.id) {
          return { ...p, commande: { ...p.commande, paidAmount: echeanceForm.paidAmount } };
        }
        return p;
      });
      setPaymentDetails({ ...paymentDetails, content: updatedDetails });
      onHandleOpenEditPaidAmount();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onOpenAttachOrder = (needUpdate) => {
    setOpenAttachOrder(!openAttachOrder);
    if (needUpdate) {
      loadRegelement();
      loadRegelementDetails();
    }
  };

  const actions = [
    {
      label: 'Modifier Réglement',
      icon: <EditIcon />,
      onClick: onHandleOpenEditPaidAmount,
    },
    {
      label: 'Détacher',
      icon: <DeleteForeverOutlinedIcon style={{ color: '#F86060' }} />,
      color: '#F86060',
      onClick: () => setOpenRemove(true),
    },
  ];
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openRemove && (
      <ConfirmRemoveDialog
        title="Suppresion de famille"
        content={`Voulez vous vraiment détacher la commande ${selectedEcheance.commande?.reference} ?`}
        handleClose={() => setOpenRemove(false)}
        handleClick={onDetacheDocument}
      />
      )}
      {openAttachOrder && (
      <AttachOrder
        open={openAttachOrder}
        client={payment?.client}
        onHandleClose={onOpenAttachOrder}
        paymentId={payment?.id}
      />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={history.goBack}>
              <ArrowBackIcon style={{ color: 'black' }} />
            </IconButton>
            <Typography sx={{ font: 'normal 600 28px Montserrat', marginRight: 1 }}>Details Du Règlement</Typography>
            <EditSaveActions
              isEdit={isEdit}
              onHandleClose={onHandleEdit}
              onHandleEdit={onHandleEdit}
              onHandleSave={onHandleSaveHeader}
            />
          </Box>
          <Typography sx={{ font: 'normal 600 16px Montserrat', color: '#38485C' }}>
            {`${payment?.client?.name || '--'} - ${payment?.receptionDate && format(new Date(payment?.receptionDate), 'dd/MM/yyy')} - `}
            <span style={{ color: '#4090D7' }}>{`${payment?.amount}Dhs`}</span>
          </Typography>
        </Box>
        <Box>
          <ButtonApp
            label="Ajouter"
            onClick={onOpenAttachOrder}
            startIcon={<AddIcon />}
            style={{
              color: '#007BC6', background: '#FFF', border: '1px solid #007BC6', minHeight: 33,
            }}
          />
        </Box>
      </Box>
      <Box sx={{
        background: '#FFF',
        borderRadius: 1.5,
        boxShadow: '0px 12px 26px #0000000D',
        display: !isEdit && 'flex',
        padding: 1.2,
        mt: 2,
        mb: 2,
      }}
      >
        {isEdit
          ? (
            <EditHeader
              payment={values}
              onChange={onChange}
            />
          ) : (
            <>
              <Box sx={{
                display: 'flex', justifyContent: 'space-between', flex: 1, mt: 1,
              }}
              >
                <HeaderItem title="LIBELLÉ" subTitle={getLibelleName(payment.status)} />
                <HeaderItem title="BANQUE" subTitle={payment.bank} />
                <HeaderItem title="MODE DE RÈGLEMENT" subTitle={payment.type} />
                <HeaderItem title="NUMÉRO DE PIÈCE" subTitle={payment.reference} />
                <HeaderItem title="DATE D’ÉCHÉANCE" subTitle={payment.dueDate && format(new Date(payment.dueDate), 'dd/MM/yyy')} />
                <HeaderItem title="DATE DU RÈGLEMENT" subTitle={payment.receptionDate && format(new Date(payment.receptionDate), 'dd/MM/yyy')} />
                <HeaderItem title="SOLD" subTitle={payment.amount - (payment.attachedAmount || 0)} />
                <HeaderItem title="CODE JOURNAL" subTitle={payment.codeJournal} />
                <HeaderItem title="NE PAS VERSE" subTitle={payment.nePasVerse ? 'Oui' : 'Non'} />
              </Box>
            </>
          )}
      </Box>
      <EnhancedTable
        getData={loadRegelementDetails}
        rows={paymentDetails.content}
        headCells={columnsDetails(onHandleActions, isEditPaidAmount,
          echeanceForm.paidAmount,
          selectedEcheance?.id, onChangeEcheance,
          onHandleOpenEditPaidAmount, onSavePaidAmount)}
        count={paymentDetails.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
      <ActionsRow
        onClose={onHandleActions}
        isOpen={openActions}
        anchorEl={anchorEl}
        actions={actions}
      />
    </>
  );
};

export default PaymentDetails;
