import { Box, Typography } from '@mui/material';
import React from 'react';

const HeaderItem = ({ title, subTitle, formatter = (i) => i }) => (
  <Box sx={{
    borderRight: '1px solid #E7E7E7',
    paddingLeft: 1.8,
    paddingRight: 1.8,
    width: '100%',
  }}
  >
    <Typography sx={{ font: 'normal 600 10px Montserrat', color: '#707070' }}>{title}</Typography>
    <Typography sx={{ font: 'normal 600 16px Montserrat', color: '#38485C' }}>{formatter(subTitle)}</Typography>
  </Box>
);

export default HeaderItem;
