import React from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { toast } from 'react-toastify';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import ButtonMultiDownload from 'Shared/Button/ButtonMultiDownload';
import DuplicateRowPopup from './DuplicateRowPopup';
import { defaultMessage, getExportProposition, importProductOrderQuantitiesByFile } from '../PropositionServices';

const optionsAdd = ['Ajouter Un Produit', 'Ajouter Un Produit Manuel'];

export default function ActionButton({
  id, name, classes, isPropositionValidated, onHandleNewProduct, isMine = true,
}) {
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [targetUpload, setTargetUpload] = React.useState(null);
  const addRef = React.useRef(null);
  const file = React.useRef(null);
  const history = useHistory();

  const handleFireUpload = (idx) => {
    setTargetUpload(idx);
    file.current.click();
  };
  const onHandleUpload = async (event) => {
    event.stopPropagation();
    const f = event.target.files[0];
    try {
      const data = await importProductOrderQuantitiesByFile(f, id, targetUpload === 'PROPOSITION_RELIQUAT');
      if (data.statusCode === '400' && data.statusLabel === 'Duplicated reference') {
        setOpenDuplicate(true);
      }
      if (data.statusCode) {
        throw new Error(data.statusLabel);
      } else {
        toast.success('Fichier importé avec succès');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      file.current.value = '';
    }
  };

  const DownloadProposition = async (event) => {
    event.stopPropagation();
    try {
      await getExportProposition(id, name);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  };

  const options = [
    { label: 'Exporter Proposition', click: DownloadProposition },
    { id: 'PROPOSITION_QUATITY', label: 'Importer Proposition', disabled: isPropositionValidated || !isMine },
    { id: 'PROPOSITION_RELIQUAT', label: 'Importer Reliquat', disabled: isPropositionValidated || !isMine },
  ];
  const onOpenAdd = () => {
    setOpenAdd(!openAdd);
  };
  const handleMenuItemAdd = (event, index) => {
    if (index === 0) {
      history.push(`/catalog?proposition-id=${id}&proposition-name=${name}`);
    }
    if (index === 1) {
      onHandleNewProduct();
    }
    onOpenAdd();
  };

  const handleCloseAdd = (event) => {
    if (addRef.current && addRef.current.contains(event.target)) {
      return;
    }
    onOpenAdd();
  };

  return (
    <>
      {openDuplicate && <DuplicateRowPopup onClose={() => setOpenDuplicate(false)} />}
      <input type="file" ref={file} style={{ display: 'none' }} onChange={onHandleUpload} />
      <ButtonMultiDownload
        uploadItems={options}
        handleUpload={handleFireUpload}
        label=""
        isSync
      />
      <Box className={classes.btn} ref={addRef}>
        <ButtonApp
          label="Ajouter"
          startIcon={<AddIcon />}
          endIcon={<ExpandMoreIcon />}
          onClick={onOpenAdd}
          disabled={isPropositionValidated || !isMine}
        />
      </Box>
      <Popper
        open={openAdd}
        anchorEl={addRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseAdd}>
                <MenuList id="split-button-menu">
                  {optionsAdd.map((option, index) => (
                    <MenuItem
                      key={option}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemAdd(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
