import React, { useRef, useState } from 'react';
import ButtonApp from 'Shared/Button';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Box, Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';

const styleItems = {
  position: 'absolute',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 12px 26px #0000001C',
  border: '1px solid #F7F7F7',
  borderRadius: '12px',
  marginTop: 1.3,
  zIndex: 5000,
  height: 300,
  width: 240,
  overflow: 'auto',
};

const styleItem = {
  '& .MuiTypography-root': {
    font: 'normal normal 600 12px Montserrat',
    color: '#38485C',
    textTransform: 'lowercase',
  },
};

const ColumnList = ({
  customColumns, onChangeColumns, selectedColumns,
}) => {
  const onCheck = (value) => () => {
    onChangeColumns(value);
  };
  return (
    <Box sx={styleItems}>

      <FormGroup>
        {customColumns.map((column) => (
          <FormControlLabel
            key={column.id}
            control={(
              <Checkbox
                checked={selectedColumns.indexOf(column.id) > -1}
                onChange={onCheck(column)}
                style={{ color: '#007BC6' }}
              />
            )}
            label={column.label}
            sx={styleItem}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

const CustomColumn = ({
  customColumns = [],
  selectedCustomColumns = [],
  setSelectedCustomColumns,
}) => {
  const [showList, setShowlist] = useState(false);
  const ref = useRef();
  /* const onChangeColumns = (value) => {
    const index = selectedCustomColumns.findIndex((c) => c.id === value?.id);
    setSelectedCustomColumns((prev) => {
      let clone = [...prev];
      if (index >= 0) {
        clone.splice(index, 1);
      } else {
        clone = [...clone, value];
      }
      return clone;
    });
  }; */
  useOnClickOutside(ref, () => setShowlist(false));

  return (
    <Box sx={{ position: 'relative', margin: 'auto', marginRight: 2 }} ref={ref}>
      <ButtonApp
        onClick={() => setShowlist(true)}
        label="Modifier les Champs"
        startIcon={<EditOutlinedIcon />}
        endIcon={showList ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        // onClick={onHandleCustomColumns}
        style={{ color: '#007BC6', background: '#FFF', border: '1px solid #007BC6' }}
      />
      {showList && (
      <ColumnList
        customColumns={customColumns}
        selectedColumns={selectedCustomColumns}
        onChangeColumns={setSelectedCustomColumns}
      />
      )}
    </Box>
  );
};

export default CustomColumn;
