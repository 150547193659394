import React, { useCallback, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  Box,
  IconButton,
} from '@mui/material';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import { toast } from 'react-toastify';
import { getHeaderById } from 'Components/Import/importServices';
import { defaultMessage } from 'Components/Client/ClientServices';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ButtonApp from '.';

const SimpleColumnList = ({
  items, onClick, getTemplate,
}) => (
  <Box sx={{
    position: 'absolute',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000001C',
    border: '1px solid #F7F7F7',
    borderRadius: '12px',
    marginTop: '11px',
    right: 0,
    zIndex: 5000,
    overflow: 'auto',
  }}
  >
    {items?.map((column) => (
      <MenuItem
        key={column.id}
        onClick={column.click ? column.click : () => onClick(column.id)}
        disabled={column.disabled}
      >
        <ListItemText
          primary={column.label}
          sx={{
            '& .MuiTypography-root': {
              font: 'normal normal 600 12px Montserrat',
              color: '#38485C',
              textTransform: 'lowercase',
            },
          }}
        />
        <RenderOnDemandThenHide visible={column.id}>
          <ListItemIcon>
            <IconButton onClick={(event) => getTemplate(event, column.id, column.label)}>
              <TextSnippetOutlinedIcon fontSize="small" sx={{ color: '#007BC6' }} />
            </IconButton>
          </ListItemIcon>
        </RenderOnDemandThenHide>

      </MenuItem>
    ))}
  </Box>
);

const ButtonMultiDownload = ({
  uploadItems, handleUpload, label, isSync = false,
}) => {
  const [open, setOpen] = useState(false);
  const refx = useRef();
  useOnClickOutside(refx, () => setOpen(false));

  const onHandleUpload = (id) => {
    handleUpload(id);
    setOpen(false);
  };

  const downloadTemplate = useCallback(
    async (event, id, docName) => {
      event.stopPropagation();
      try {
        // setLoading(true);
        await getHeaderById(id, docName);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        // setLoading(false);
      }
    },
    [],
  );
  return (
    <Box sx={{ position: 'relative', margin: 'auto', marginRight: 2 }} ref={refx}>
      <ButtonApp
        label={label}
        startIcon={isSync ? <CloudSyncIcon /> : <CloudUploadOutlinedIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={() => setOpen((prev) => !prev)}
        style={{ color: '#007BC6', background: '#FFF', border: '1px solid #007BC6' }}
      />
      {open && (
      <SimpleColumnList
        items={uploadItems}
        onClick={onHandleUpload}
        getTemplate={downloadTemplate}
      />
      )}
    </Box>
  );
};

export default ButtonMultiDownload;
