import {
  Box, Fade, Paper, Typography,
} from '@mui/material';
import React from 'react';
import { TABS_ID } from '../constants';

const TabFinance = ({
  title, subTitle, onClickTab, isOn = false,
}) => (
  <Paper
    elevation={2}
    sx={{
      cursor: 'pointer', p: '10px 20px', m: '0 5px', position: 'relative', minWidth: 220, flex: 1,
    }}
    onClick={onClickTab}
  >
    <Typography sx={{ textAlign: 'center', fontWeight: 600, color: isOn ? 'black' : '#E4E7EA' }}>{title}</Typography>
    <Typography sx={{ textAlign: 'center', fontWeight: 600, mt: 'revert' }}>
      {`${typeof subTitle === 'number' ? Math.ceil(subTitle * 100) / 100 : 0} DH`}
    </Typography>
    { isOn && (
    <Fade in>
      <Box sx={{
        height: 4,
        background: '#007BC6',
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        '&::before': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '10px solid #007BC6',
          position: 'absolute',
          bottom: -7,
          left: '50%',
          transform: 'translateX(-50%)',
        },
      }}
      />
    </Fade>
    )}
  </Paper>
);
const FinancialTabs = ({ selectedTab, onClickTab, clientInfo }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', margin: '8px 5px' }}>
    <TabFinance
      title="FACTURE NON RÉGLÉ PM"
      subTitle={clientInfo.totalNonReglePm}
      isOn={selectedTab === TABS_ID.REG}
      onClickTab={() => onClickTab(TABS_ID.REG)}
    />
    <TabFinance
      title="FACTURE NON RÉGLÉ HUILE"
      subTitle={clientInfo.totalNonRegleHuile}
      isOn={selectedTab === TABS_ID.NO_REG}
      onClickTab={() => onClickTab(TABS_ID.NO_REG)}
    />
    <TabFinance
      title="IMPAYE"
      subTitle={clientInfo.totalImpaye}
      isOn={selectedTab === TABS_ID.IMP}
      onClickTab={() => onClickTab(TABS_ID.IMP)}
    />
    <TabFinance
      title="REGLEMENT ENCOURS"
      subTitle={clientInfo.totalRegEncours}
      isOn={selectedTab === TABS_ID.IN_REG}
      onClickTab={() => onClickTab(TABS_ID.IN_REG)}
    />
  </Box>
);

export default FinancialTabs;
