import React, { useContext } from 'react';

import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import UserContext from 'Shared/UserContext';
import useStyles from './style';

import Products from './Products';
import ProductsTable from './ProductsTable/ProductsTable';

const CatalogView = () => {
  const classes = useStyles();
  const {
    state: { status, affectStatus },
  } = useContext(ReferencialContext);
  const {
    state: { user },
  } = useContext(UserContext);
  const isBUYER = ['BUYER', 'BUYER_MANAGER'].includes(user.role);
  return (
    <div style={{ height: '100vh' }}>
      {(status === 'loading' || affectStatus === 'loading') && <GlobalLoader size="100vh" />}
      <div className={classes.containerProducts}>
        {!isBUYER && <Products />}
        {isBUYER && <ProductsTable />}
      </div>
    </div>
  );
};
CatalogView.propTypes = {};

export default CatalogView;
