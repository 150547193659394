import { useMemo, useState } from 'react';

const useMultiSelect = (trackId = 'id', columns = []) => {
  const [selectedItem, setSelectedItem] = useState([]);

  const onSelectOne = (tracker) => {
    if (!tracker) {
      return;
    }
    if (selectedItem.includes(tracker[trackId])) {
      const newSelects = selectedItem.filter((item) => item !== tracker[trackId]);
      setSelectedItem(newSelects);
    } else {
      setSelectedItem([...selectedItem, tracker[trackId]]);
    }
  };
  const extendedColumns = useMemo(
    () => columns.filter((col) => selectedItem.includes(col[trackId])),
    [selectedItem, columns],
  );

  return { selectedItem, onSelectOne, extendedColumns };
};

export default useMultiSelect;
