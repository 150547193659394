import Input from 'Shared/Input/v2';
import React from 'react';
import { DateCopima } from 'Shared/RangeDate';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import { useSelector } from 'react-redux';
import { makeSelectExpeditions } from 'selectors';
import { DEPOSITS, INCOTERMS } from '../constants';
import { INTERNATION_TRANSPORT, formatTranportName } from './constants';

const Fields = [{ name: 'date', type: 'date' },
  {
    name: 'incoterme',
    label: 'INCOTERME',
    type: 'select',
    options: INCOTERMS,
    getOptionLabel: (option) => (option),
    filterOptions: (ops, v) => ops
      .filter((o) => !v.inputValue || o.toLowerCase().includes(v.inputValue.toLowerCase())),
  },
  { name: 'datePort', label: 'DATE ARRIVÉE PORT', type: 'date' },
  {
    name: 'repo', label: 'DÉPÔT', type: 'select', options: DEPOSITS,
  },
  {
    name: 'transporteur',
    label: 'TRANSPORTEUR',
    type: 'select',
    options: INTERNATION_TRANSPORT,
    getOptionLabel: formatTranportName,
    filterOptions: (ops, v) => ops
      .filter((o) => !v.inputValue || o.toLowerCase().includes(v.inputValue.toLowerCase())),
  },
  { name: 'dateCopima', label: 'DATE ARRIVÉE COPINA', type: 'date' },
  { name: 'cours', label: 'COURS DEVISE' }, { name: 'poid' },
  { name: 'nbrePalettes', label: 'NOMBRE PALETTES' },
  {
    name: 'importPaymentMode',
    label: 'MODALITÉ DE PAIEMENT',
    type: 'select',
    getOptionLabel: (option) => (option.paymentMode),
    filterOptions: (ops, v) => ops
      .filter((o) => !v.inputValue || o.toLowerCase().includes(v.inputValue.toLowerCase())),
  }];

const EditHeader = ({ document, onChange, importPaymentModes }) => {
  const expeditions = useSelector(makeSelectExpeditions());
  const FieldsUpdated = Fields.map((field) => {
    if (field.name === 'expedition') {
      return { ...field, options: expeditions };
    }

    if (field.name === 'importPaymentMode') {
      return { ...field, options: importPaymentModes };
    }

    return field;
  });
  const onChangeDate = (name) => (date) => onChange({ target: { name, value: date } });

  const onChangeSelect = (name) => (event, value) => {
    onChange(value ? { target: { name, value } } : event);
  };
  return (
    <>
      {FieldsUpdated.map((field) => {
        if (field.type === 'date') {
          return (
            <DateCopima
              label={field.label || field.name.toUpperCase()}
              value={document[field.name] ? new Date(document[field.name]) : null}
              name={field.name}
              onChange={onChangeDate(field.name)}
              sx={{
                width: '190px', border: '1px solid #007BC6', mt: '8px', mb: '4px',
              }}
            />
          );
        }

        if (field.type === 'select') {
          return (
            <AutoCompleteCmp
              options={field.options}
              onChange={onChangeSelect(field.name)}
              value={document[field.name]}
              label={field.label}
              name={field.name}
              getOptionLabel={field.getOptionLabel}
              filterOptions={field.filterOptions}
              isPrimaryTheme
              style={{
                marginTop: 8, marginBottom: 4, maxWidth: 190, display: 'inline-block', border: '1px solid #007BC6',
              }}
            />
          );
        }
        return (
          <Input
            value={document[field.name]}
            onChange={onChange}
            style={{
              marginTop: 8, marginBottom: 4, width: '190px', border: '1px solid #007BC6',
            }}
            label={field.label || field.name.toUpperCase()}
            name={field.name}
          />
        );
      })}
    </>
  );
};

export default EditHeader;
