import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import DialogCmp from 'Shared/Dialog';
import { Box, Typography } from '@mui/material';
import { columnsAttachOrders } from '../PaymentDocuments/constants';
import { attachDocumentsToInvoices, getUnpaidOrders } from '../PaymentServices';

const AttachOrder = ({
  open, paymentId, client, onHandleClose,
}) => {
  const [freeOrders, setFreeOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(async () => {
    if (!client?.code) {
      return;
    }
    try {
      setLoading(true);
      const data = await getUnpaidOrders(client?.code);
      setFreeOrders(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [client]);

  const onChangePaidAmount = ({ target }) => {
    const { value, name } = target;
    setFreeOrders((orders) => orders.map((order) => {
      if (Number(order.id) === Number(name)) {
        return { ...order, paidAmount: value };
      }
      return order;
    }));
  };
  const toAttach = (selectedId) => {
    if (selectedIds.includes(selectedId)) {
      setSelectedIds(selectedIds.filter((s) => s !== selectedId));
    } else {
      setSelectedIds([...selectedIds, selectedId]);
    }
  };
  const onSubmit = async () => {
    try {
      setLoading(true);
      const attachList = freeOrders.filter((o) => selectedIds.includes(o.id))
        .map((curr) => ({ orderId: curr.id, amount: parseFloat(curr.paidAmount) }));

      await attachDocumentsToInvoices({ reglementId: paymentId, details: attachList });
      toast.success("L'attachement est enregitré avec succès");
      onHandleClose(true);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <DialogCmp
        title={(
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginRight: '8px',
          }}
          >
            <Typography sx={{ font: 'normal 600 26px Montserrat' }}>{client?.name}</Typography>
          </Box>
        )}
        subTitle={(
          <Typography sx={{ color: 'black', font: 'normal 600 16px Montserrat' }}>
            {client?.code}
          </Typography>
        )}
        fullWidth={false}
        open={open}
        handleClose={onHandleClose}
        onHandleSubmit={onSubmit}
        isOkDisabled={selectedIds.length === 0}
        labelOk="ENREGISTRÉ"
      >
        <EnhancedTable
          staticData
          rows={freeOrders || []}
          headCells={columnsAttachOrders(onChangePaidAmount, toAttach, selectedIds)}
          count={freeOrders?.length}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </DialogCmp>
    </>
  );
};

export default AttachOrder;
