/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-useless-escape */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import React from 'react';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import RangeDate, { DateCopima } from 'Shared/RangeDate';
import SwitchCmp from 'Shared/SwitchCmp';
import Input from 'Shared/Input/v2';

const RenderInput = ({
  input,
  name,
  value,
  label,
  label2,
  handleChange,
  fullWidth = true,
  styleInput,
  styleLabel,
  style = {},
  sx = {},
}) => (
  <>
    {input.type === 'number' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          type="number"
          value={value}
          name={name}
          label={label}
          onChange={handleChange}
          inputProps={{ min: 0 }}
          classes={{ root: styleInput }}
          disabled={input.disabled}
        />
      </FormControl>
    )}
    {input.type === 'text' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          classes={{ root: styleInput }}
          disabled={input.disabled}
          type="text"
          value={value || ''}
          name={name}
          label={label}
          onChange={handleChange}
        />
      </FormControl>
    )}
    {input.type === 'input-v2' && (
      <Input
        value={value || ''}
        onChange={handleChange}
        margin="dense"
        style={{ maxWidth: '240px', marginTop: 0, ...style }}
        label={input.label}
        name={name}
        disabled={input.disabled}
        type={input.subType}
      />
    )}
    {input.type === 'autocomplete' && (
      <AutoCompleteCmp
        options={input[input.listName]}
        onChange={handleChange}
        name={name}
        label={label}
        value={value || null}
        getOptionLabel={(option) => input.optionLabel(option)}
        filterOptions={(options, v) => options.filter(
          (option) => input.filter(option, v.inputValue.toLowerCase()),
        )}
        styleInput={styleInput}
        styleLabel={styleLabel}
        disabled={input.disabled}
        isPrimaryTheme={input.usePrimaryTheme !== false}
        style={{ ...style }}
      />
    )}
    {input.type === 'file' && (
      <>
        <Button variant="contained" component="label" style={{ marginRight: 10 }}>
          Upload File
          <input
            type="file"
            hidden
            /// value={value?.name}
            name={name}
            onChange={handleChange}
          />
        </Button>
        {value?.name}
      </>
    )}
    {input.type === 'date' && (
      <DateCopima
        disabled={input.disabled}
        fullWidth={fullWidth}
        id={input.nme}
        label={input.label}
        name={name}
        value={value}
        clearable={input.clearble}
        onChange={handleChange}
        sx={{ ...sx }}
      />
    )}
    {input.type === 'date-no-time' && (
      <DatePicker
        clearable
        disabled={input.disabled}
        fullWidth={fullWidth}
        size="small"
        label={input.label}
        inputVariant="outlined"
        value={value}
        name={name}
        format="dd/MM/yyyy"
        onChange={handleChange}
        views={['year', 'month', 'date']}
      />
    )}
    {input.type === 'time' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          classes={{ root: styleInput }}
          disabled={input.disabled}
          type="time"
          value={value || ''}
          name={name}
          onChange={handleChange}
        />
      </FormControl>
    )}
    {input.type === 'date-range' && (
      <RangeDate
        value={value}
        handleChange={handleChange}
        text={{ start: label, end: label2 }}
        // noMarginRightEnd
      />
    )}
    {input.type === 'switch' && (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <Typography style={{
        font: 'normal normal bold 12px Montserrat',
        color: '#007BC6',
      }}
      >
        {label}
      </Typography>
      <SwitchCmp
        checked={value}
        onChange={handleChange}
        name={name}
      />
    </Box>
    )}
  </>
);

export default RenderInput;
