/* eslint-disable max-len */
import {
  getAllDocumentDetails,
  getAllImportPaymentModes,
  saveDocument,
  defaultMessage, getCustomsFile, getDocumentById, updateDocumentDetails,
  deleteAchatProduct,
  addDocumentDetail,
} from 'Components/Import/importServices';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box, TextField, IconButton, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import ButtonApp from 'Shared/Button';
import useForm from 'Shared/Hooks/useForm';
import DialogCmp from 'Shared/Dialog';
import FormBox from 'Components/Import/NewArticle/FormBox';
import Input from 'Shared/Input/v2';
import EnhancedTable from 'Shared/EnhancedTable';
import ReferencialContext from 'Shared/ReferencialContext';
import CheckIcon from '@mui/icons-material/Check';
import CalculateIcon from '@mui/icons-material/Calculate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditSaveActions from 'Shared/Button/EditSaveActions';
import useStyles from './style';
import {
  DEPOSITS, TYPES_COMMANDES, getFolderStatus, inputsA, inputsB,
} from '../constants';
import EditHeader from './EditHeader';
import {
  columns, formaValidation, formatTranportName, getTotalCharge,
} from './constants';
import useInvoiceDetails from './useInvoiceDetails';
import DownloadLastFile from './DownloadLastFile';
import EditDocumentItem from './EditDocumentItem';
import CalculateActions from '../ActionsDocument/CalculateActions';
import VentilationDetail from './VentilationDetail';
import HeaderItem from './HeaderItem';

const Detail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [idToDelete, setIdToDelete] = useState(false);
  const history = useHistory();
  const [selectedCustomColumns, setSelectedCustomColumns] = useState([]);
  const [details, setDetails] = useState({ content: [] });
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenCalculate, setIsOpenCalculate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [importPaymentModes, setImportPaymentModes] = useState([]);
  const { values: headerValues, onChange: onEditHeader, reset: setForm } = useForm({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [updateLastFile, setUpdateLastFile] = useState(1);

  const {
    state: { referencial: { products } },
  } = useContext(ReferencialContext);
  const { values: document, reset: setDocument, onChange } = useForm({ search: '' });
  const { values, onChange: onChangeValues } = useForm({ search: '' });
  const { values: editForm, onChange: onChangeEditForm, reset: initEditForm } = useForm({});
  const classes = useStyles();
  const {
    onOpenInvoiceDetails, isInvoiceDetailsOpen, isInvoiceLoading, invoices, totalHt,
  } = useInvoiceDetails(id);
  const setDetailsFormated = (d, product) => ({
    ...d,
    des: product?.des,
    triggerDeleteProduct: (e) => {
      e.stopPropagation();
      setIdToDelete(d.id);
    },
    triggerEdit: (e) => {
      e.stopPropagation();
      initEditForm({
        ...d,
        des: product?.des,
        repo: DEPOSITS.find((opt) => opt.id === document.repoId) || null,
      });
    },
  });
  const getAllDetails = useCallback(
    async (sort) => {
      setLoading(true);
      try {
        const documentDetails = await getAllDocumentDetails(sort, values.search, id);
        setDetails({
          ...documentDetails,
          content: documentDetails?.content?.map((d) => {
            const product = products?.filter((p) => p.reference === d.productId)?.length > 0
              ? products?.filter((p) => p.reference === d.productId)[0]
              : null;
            return setDetailsFormated(d, product);
          }),
        });
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setDetails, setLoading, values?.search, id, products, setIdToDelete],
  );
  const deleteProductCall = useCallback(async () => {
    setLoading(true);
    try {
      await deleteAchatProduct(idToDelete);
      setOpenSuccess(true);
      setIdToDelete(undefined);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, idToDelete, setOpenSuccess, setIdToDelete]);

  useEffect(() => {
    setForm({
      ...document,
      repo: DEPOSITS.find((opt) => opt.id === document.repoId) || null,
    });
  }, [document]);

  useEffect(async () => {
    if (!id) {
      return;
    }
    try {
      setLoading(true);
      const response = await getDocumentById(id);
      setDocument(response);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(async () => {
    // if import payment mode is null in localhost
    const modes = await getAllImportPaymentModes();
    setImportPaymentModes(modes);
  }, []);

  const onHandleAdditionalData = () => {
    setIsOpen(!isOpen);
  };

  const onSubmitAdditionalData = async () => {
    await saveDocument({ ...document, folderId: id });
    onHandleAdditionalData();
    setRefresh(!refresh);
    // success
    toast.success('Modifications bien enregistrer!');
  };

  const onHandleEdit = (nextValues) => {
    if (nextValues) {
      //  setForm(nextValues);
    }
    setIsEdit(!isEdit);
  };
  const onHandleSaveHeader = async () => {
    const newDoc = {
      ...headerValues,
      // paymentMode: { id: headerValues.paymentMode.code || headerValues.paymentMode.id },
      // expedition: { id: headerValues.expedition?.code || headerValues.expedition?.id },
      repoId: headerValues.repo?.id || null,
      repo: headerValues.repo?.name || '',
    };
    onHandleEdit(document);
    const resp = await saveDocument(newDoc);
    setDocument(resp);
    toast.success('Modifications bien enregistrer!');
  };
  const totalDocument = useMemo(() => getTotalCharge(document), [document]);

  const DownloadCustomsFile = async (event) => {
    event.stopPropagation();
    try {
      setLoading(true);
      await getCustomsFile(id);
      setUpdateLastFile(updateLastFile + 1);
    } catch (e) {
      console.log(e);
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };

  const onCloseEditForm = () => {
    initEditForm({});
  };

  const onHandleSaveEditForm = async () => {
    try {
      setLoading(true);
      const {
        repo, product, isNewLine, ...restAchat
      } = editForm;
      const body = { ...restAchat, repoId: repo?.id || null };
      if (!formaValidation(body)) {
        throw new Error('Produit, N° Facture et depot sont obligatoire !');
      }
      const response = isNewLine ? await addDocumentDetail(id, body) : await updateDocumentDetails(editForm?.id, body);
      const newContents = isNewLine
        ? [...details.content, setDetailsFormated({ ...body, id: response.id }, product)]
        : details.content.map((item) => {
          if (item.id === response.id) {
            const productx = products?.filter((p) => p.reference === item.productId)?.length > 0
              ? products?.filter((p) => p.reference === item.productId)[0]
              : null;
            return setDetailsFormated(response, productx);
          }
          return item;
        });
      setDetails({
        ...details,
        content: newContents,
      });
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    } finally {
      setLoading(false);
      onCloseEditForm();
    }
  };

  const onCloseCalculate = (event) => {
    setIsOpenCalculate(!isOpenCalculate);
    setAnchorEl(event.currentTarget);
  };

  const onHandleAddLine = () => {
    initEditForm({ id: 'X', isNewLine: true });
  };
  return (
    <div style={{ height: '100vh' }}>
      {loading && <GlobalLoader size="100vh" />}
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        onHandleSubmit={() => setOpenSuccess(false)}
        labelKo=""
        labelOk="Ok"
        actionStyle={{ display: 'flex', justifyContent: 'center' }}
        styleOk={{
          width: '141px',
          height: '40px',
        }}
      >
        <Box
          style={{
            margin: '0 20px',
            marginTop: '-34px',
          }}
        >
          <Box className={classes.successCheck}>
            <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            le produit est supprimer avec succés
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={idToDelete}
        handleClose={() => setIdToDelete(undefined)}
        onHandleSubmit={deleteProductCall}
        labelKo="Non"
        labelOk="Oui"
        styleOk={{
          background: '#F86060',
          boxShadow: '0px 4px 10px #BEBEBE50',
          width: '141px',
          height: '40px',
        }}
        styleKo={{
          width: '141px',
          height: '40px',
        }}
        actionStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ margin: '0 20px', mt: '-34px', mb: 1 }}>
          <Box sx={{
            margin: 'auto',
            width: '114px',
            height: '114px',
            background: '#F86060',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <DeleteIcon fontSize="large" sx={{ color: '#FFF' }} />
          </Box>
          <Typography className={classes.successMsg}>
            Êtes-vous sûr de vouloir Supprimer le produit ?
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title="Frais D’achat"
        endIcon={false}
        fullWidth={false}
        open={isOpen}
        handleClose={onHandleAdditionalData}
        onHandleSubmit={onSubmitAdditionalData}
        labelKo="ANNULER"
        labelOk="ENREGISTRER"
      >
        <FormBox title="">
          {inputsA.map((input) => (
            <Input
              value={document[input.name]}
              onChange={onChange}
              margin="dense"
              style={{ width: '200px' }}
              label={input.label}
              name={input.name}
            />
          ))}
        </FormBox>
        <FormBox title="">
          {inputsB.map((input) => (
            <Input
              value={document[input.name]}
              onChange={onChange}
              margin="dense"
              style={{ width: '200px' }}
              label={input.label}
              name={input.name}
            />
          ))}
        </FormBox>
      </DialogCmp>
      <VentilationDetail
        classes={classes}
        totalTH={totalHt}
        // totalTTC={totalTTC}
        totalDocument={totalDocument}
        getAllDetails={getAllDetails}
        isInvoiceDetailsOpen={isInvoiceDetailsOpen}
        onOpenInvoiceDetails={onOpenInvoiceDetails}
        id={id}
        isInvoiceLoading={isInvoiceLoading}
        invoices={invoices}
      />
      <div className={classes.container}>
        <Box className={classes.topHeader}>
          <Box>
            <Box className={classes.topHeaderTitle}>
              <IconButton onClick={history.goBack}>
                <ArrowBackIcon style={{ color: 'black' }} />
              </IconButton>
              <Typography className={classes.noDoc}>{`N° Document ${document.folderCode || document.reference}`}</Typography>
              {getFolderStatus(document.statut)}
              <EditSaveActions
                isEdit={isEdit}
                onHandleClose={onHandleEdit}
                onHandleEdit={onHandleEdit}
                onHandleSave={onHandleSaveHeader}
              />
            </Box>
            <Typography className={classes.typeDoc}>
              {`Type Document ${TYPES_COMMANDES.find((typeDoc) => document.type === typeDoc.id)?.label || 'NA'} - Fournisseurs ${document.fournisseur ? document.fournisseur.name : ''
              } - `}
              <span style={{ color: '#4090D7' }}>{`${totalHt} Dh`}</span>
            </Typography>
          </Box>
          <Box>
            <IconButton
              onClick={onCloseCalculate}
              className={classes.btnIcon}
              style={{ minHeight: 33 }}
              size="large"
            >
              <CalculateIcon size="small" />
            </IconButton>

            <CalculateActions
              isOpen={isOpenCalculate}
              anchorEl={anchorEl}
              onClose={onCloseCalculate}
              onHandleVentile={onOpenInvoiceDetails}
              folderId={id}
              setLoading={setLoading}
              folderName={document.folderCode || document.reference}
            />

            <ButtonApp
              label="Frais D’achat"
              onClick={onHandleAdditionalData}
              style={{
                color: '#007BC6',
                background: '#FFF',
                border: '1px solid #007BC6',
                minHeight: 33,
                marginLeft: 12,
                marginRight: 12,
              }}
            />
            <ButtonApp
              label="Générer Fichier Douane"
              onClick={DownloadCustomsFile}
              style={{
                color: '#007BC6', background: '#FFF', border: '1px solid #007BC6', minHeight: 33,
              }}
            />
            <DownloadLastFile folderId={id} update={updateLastFile} />
            <ButtonApp
              label="Ajouter"
              startIcon={<AddIcon />}
              onClick={onHandleAddLine}
              style={{ marginLeft: 10 }}
            />
          </Box>
        </Box>
        <Box className={classes.header}>
          {isEdit
            ? (
              <EditHeader
                document={headerValues}
                onChange={onEditHeader}
                importPaymentModes={importPaymentModes}
              />
            ) : (
              <>
                <Box className={classes.headerLine}>
                  <HeaderItem title="DATE" subTitle={document.date && format(new Date(document.date), 'dd/MM/yyy')} />
                  <HeaderItem title="INCOTERME" subTitle={document.incoterme} />
                  <HeaderItem title="DATE ARRIVÉE PORT" subTitle={document.datePort && format(new Date(document.datePort), 'dd/MM/yyy')} />
                  <HeaderItem title="DÉPÔT" subTitle={document.repo} />
                  <HeaderItem title="TRANSPORTEUR" subTitle={formatTranportName(document.transporteur)} />
                  <HeaderItem title="DATE ARRIVÉE COPIMA" subTitle={document.dateCopima && format(new Date(document.dateCopima), 'dd/MM/yyy')} />
                  <HeaderItem title="COURS DEVISE" subTitle={document.cours} />
                </Box>
                <Box className={classes.headerLine} style={{ marginTop: 5, width: '50%' }}>
                  <HeaderItem title="POID" subTitle={document.poid} />
                  <HeaderItem title="NOMBRE PALETTES" subTitle={document.nbrePalettes} />
                  <HeaderItem
                    title="MODALITÉ DE PAIEMENT"
                    subTitle={document.importPaymentMode ? document.importPaymentMode.paymentMode : ''}
                  />
                </Box>
              </>
            )}
        </Box>

        <Box className={classes.filterZone}>
          <TextField
            variant="outlined"
            onChange={onChangeValues}
            value={values.search}
            label="chercher"
            size="small"
            name="search"
            className={classes.filter}
            style={{ width: 200 }}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
        </Box>

        {editForm.id
          ? (
            <EditDocumentItem
              achat={editForm}
              onChange={onChangeEditForm}
              onClose={onCloseEditForm}
              onSaveAchat={onHandleSaveEditForm}
              products={products}
            />
          ) : null }

        <EnhancedTable
          getData={getAllDetails}
          rows={details?.content}
          headCells={columns(selectedCustomColumns, setSelectedCustomColumns, classes)}
          count={details.totalElements}
          update={refresh}
          rowsPerPageOptions={[10, 15, 20]}
        />
      </div>
    </div>
  );
};

export default Detail;
