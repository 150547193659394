/* eslint-disable max-len */
import { Box } from '@mui/material';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import Input from 'Shared/Input/v2';
import useDebounce from 'Shared/Hooks/useDebounce';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import ActionsRow from 'Shared/EnhancedTable/ActionsRow';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import { useSelector } from 'react-redux';
import { makeSelectCities, makeSelectZones } from 'selectors';
import ReferencialContext from 'Shared/ReferencialContext';
import { useHistory } from 'react-router-dom';
import { defaultMessage, deleteClient, getClients } from '../RecoveryServices';
import RecoveryView from '../RecoveryView';
import { defaultColumns, basedColumns, moveActionToEnd } from './constants';

const RecoveryList = ({
  isRECOUVREMENT = false, extendedColumns = [], openClient = false, onCloseClientView,
}) => {
  const {
    state: {
      referencial: { clients: clientList },
    },
  } = useContext(ReferencialContext);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [clientData, setClientData] = useState({ content: [] });
  const [values, setValues] = useState({});
  const targetSearch = useDebounce(values?.search, 700);
  const [anchorEl, setAnchorEl] = useState(null);
  const cities = useSelector(makeSelectCities());
  const zones = useSelector(makeSelectZones());
  const history = useHistory();

  const onCloseClient = () => {
    onCloseClientView(false);
    setSelectedClient(null);
  };

  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const onHandleActions = (row, e) => {
    setOpenActions(!openActions);
    if (e) {
      setAnchorEl(e.currentTarget);
    }
    if (row?.id) {
      setSelectedClient(row);
    }
  };
  const handleSelectClient = (client) => () => {
    setSelectedClient(client);
    // history.push(`/client/${client.code}`);
  };
  const onHandleEdit = () => {
    setEditMode(true);
    onCloseClientView(true);
  };
  const onHandleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const onHandleLine = () => {
    history.push(`/client/${selectedClient.code}`);
  };
  const onSubmitDelete = async () => {
    try {
      setLoading(true);
      await deleteClient(selectedClient?.id);
      setClientData({
        ...clientData,
        content: clientData.content.filter((c) => c.id !== selectedClient.id),
      });
      toast.success('Le compte du client a été supprimé !');
      onHandleDelete();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  const getClientList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getClients(sort, {
          city: values.city,
          code: values?.client?.code,
          zone: values?.zone,
          search: isRECOUVREMENT ? targetSearch : null,
        });
        setClientData(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [isRECOUVREMENT, values?.city, values?.client?.code, values?.zone, targetSearch],
  );
  const clients = useMemo(
    () => clientData.content.map((client) => ({
      ...client,
      id: client.code,
      click: handleSelectClient(client),
    })),
    [clientData.content],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openClient && (
      <RecoveryView
        handleClose={onCloseClient}
        clientCode={selectedClient?.code}
        editMode={selectedClient === null || editMode}
      />
      )}
      <Box
        style={{
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          background: '#E9EBED 0% 0% no-repeat padding-box',
          bordeRradius: '0px 0px 12px 12px',
          marginBottom: 16,
        }}
      >
        <RenderOnDemandThenHide visible={isRECOUVREMENT}>
          <Input
            value={values?.search}
            onChange={handleInputChange('search')}
            // style={styleInpt}
            label="Recherche ..."
            name="search"
          />
        </RenderOnDemandThenHide>
        <RenderOnDemandThenHide visible={!isRECOUVREMENT}>
          <AutoCompleteCmp
            options={clientList || []}
            label="Client"
            name="client"
            isPrimaryTheme
            onChange={handleInputChange('client')}
            style={{ maxWidth: 200 }}
            value={values.client}
            size="small"
            getOptionLabel={(option) => (option.code ? `${option.code}: ${option.name}` : '')}
            filterOptions={(options, v) => options.filter(
              (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                  || option.code?.toLowerCase().startsWith(v.inputValue.toLowerCase()),
            )}
          />
          <AutoCompleteCmp
            options={zones || []}
            onChange={handleInputChange('zone')}
            style={{ maxWidth: 200 }}
            isPrimaryTheme
            size="small"
            label="Zone"
            getOptionLabel={(option) => option}
            filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            autoSelect
            value={values?.zone}
          />
          <AutoCompleteCmp
            options={cities || []}
            onChange={handleInputChange('city')}
            style={{ maxWidth: 200 }}
            isPrimaryTheme
            size="small"
            label="Ville"
            getOptionLabel={(option) => option}
            filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            value={values?.city}
          />
        </RenderOnDemandThenHide>
      </Box>
      <EnhancedTable
        getData={getClientList}
        rows={clients}
        headCells={isRECOUVREMENT
          ? moveActionToEnd([...basedColumns(onHandleActions), ...extendedColumns])
          : defaultColumns}
        count={clientData.totalElements}
        rowsPerPageOptions={[20, 30, 40]}
      />
      <ActionsRow
        onHandleDelete={onHandleDelete}
        onHandleEdit={onHandleEdit}
        onClose={() => () => setOpenActions(false)}
        onHandleLine={onHandleLine}
        isOpen={openActions}
        anchorEl={anchorEl}
      />
      {openDelete && (
        <ConfirmRemoveDialog
          title="Suppresion"
          content="Êtes-vous sûr de vouloir supprimer ce client ?"
          handleClose={onHandleDelete}
          handleClick={onSubmitDelete}
        />
      )}
    </>
  );
};

export default RecoveryList;
