import {
  addProductToPropositions,
  getPropositions,
} from 'Components/Proposition/PropositionServices';
import DialogCmp from 'Shared/Dialog';
import EnhancedTable from 'Shared/EnhancedTable';
import React, { useCallback, useState } from 'react';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import RangeDate from 'Shared/RangeDate';
import useForm from 'Shared/Hooks/hooks/useForm';
import { Box, Typography, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import useLocalStorage from 'Shared/Hooks/useLocalStorage';
import { columns } from './constants';
import useStyles from '../style';

const INIT_PROPOSITION = { reference: null, name: null };

const AppendProductsToProposition = ({
  open, onHandleClose, params, datesFilter,
}) => {
  const classes = useStyles();
  const [user] = useLocalStorage('user');
  const [propositions, setPropositions] = useState({ content: [], totalElements: 0 });
  const [loading, setLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [selectedProposition, setSelectedProposition] = useState(INIT_PROPOSITION);
  const { values, onChange } = useForm({ start: null, end: null, name: '' });
  const history = useHistory();

  const getPropositionsData = useCallback(
    async ({ page, size }) => {
      if (!open) {
        return;
      }
      setLoading(true);
      const response = await getPropositions({
        page, size, statusList: ['CREATED'], ...values,
      });
      setPropositions(response);
      setLoading(false);
    },
    [open, values],
  );

  const onHandleChangeRangeDate = (value, isStartDate) => {
    onChange({ target: { name: isStartDate ? 'start' : 'end', value } });
  };

  const onHandleSubmit = async () => {
    const req = params?.productIds.map((productId) => ({ productId, quantity: 1 }));
    await addProductToPropositions(selectedProposition.reference, req, datesFilter);
    setOpenSuccess(true);
  };

  const onHandleSelectProposition = (id, name) => () => {
    setSelectedProposition({ reference: id, name });
  };

  const onClose = () => {
    setSelectedProposition(INIT_PROPOSITION);
    setOpenValidation(false);
    setOpenSuccess(false);
    onHandleChangeRangeDate(null, true);
    onHandleChangeRangeDate(null, false);
    onHandleClose();
  };

  const gotToProposition = () => {
    history.push(
      `proposition/add?reference=${selectedProposition.reference}&name=${selectedProposition.name}`,
      { lastAddedIds: params, datesFilter },
    );
    onClose();
  };

  return (
    <DialogCmp
      title="Ajouter A Une Proposition"
      open={open}
      handleClose={onClose}
      scroll="body"
      labelOk="Enregister"
      onHandleSubmit={() => setOpenValidation(true)}
      isOkDisabled={!selectedProposition.reference}
    >
      <>
        {loading && <GlobalLoader size="100vh" />}
        <Box style={{ marginBottom: '18px' }}>
          <TextField
            style={{
              width: 400,
              borderRadius: 6,
              overflow: 'hidden',
              marginRight: 10,
            }}
            size="small"
            label="Recherche..."
            variant="filled"
            InputProps={{
              disableUnderline: true,
              style: { height: '40px' },
              inputProps: { style: { paddingTop: 11 } },
            }}
            name="name"
            onChange={onChange}
            InputLabelProps={{
              style: { top: -7, color: '#000', fontFamily: 'SFProDisplayMedium' },
            }}
          />
          <RangeDate
            text={{ start: 'date début', end: 'date fin' }}
            value={values}
            handleChange={onHandleChangeRangeDate}
          />
        </Box>
        <DialogCmp
          title=""
          endIcon={false}
          fullWidth={false}
          open={openSuccess}
          handleClose={onClose}
          onHandleSubmit={gotToProposition}
          labelKo="ok"
          labelOk="Voir La Proposition"
        >
          <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
            <Box className={classes.successCheck}>
              <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
            </Box>
            <Typography className={classes.successMsg}>
              La lists des produits sélectionner a été bien ajouter
            </Typography>
          </Box>
        </DialogCmp>
        <DialogCmp
          title=""
          endIcon={false}
          fullWidth={false}
          open={openValidation}
          handleClose={() => setOpenValidation(false)}
          onHandleSubmit={onHandleSubmit}
          labelKo="Annuler"
          labelOk="Valider"
        >
          <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
            <Box className={classes.successCheck}>
              <AddIcon fontSize="large" style={{ margin: 'auto' }} />
            </Box>
            <Typography className={classes.successMsg}>
              Êtes-vous sûr de vouloir Ajouter la selection à la  proposition
              {` ${selectedProposition?.name} ?`}
            </Typography>
          </Box>
        </DialogCmp>
        <EnhancedTable
          getData={getPropositionsData}
          rows={propositions.content}
          headCells={columns(onHandleSelectProposition, selectedProposition.reference, user?.id)}
          count={propositions.totalElements}
          rowsPerPageOptions={[10, 15, 20]}
        />
      </>
    </DialogCmp>
  );
};

export default AppendProductsToProposition;
