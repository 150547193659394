/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled, withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import DEFAULT_PRODUCT from 'Images/default_product.png';
import React, {
  useContext, useMemo, useState, useCallback, useEffect,
} from 'react';
import { toast } from 'react-toastify';
import ReferencialContext from 'Shared/ReferencialContext';
import { transferProduct } from 'Components/Catalog/CatalogServices';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ExternSearchContext from 'Components/ExternSearch/context';
import AffectToOrder from '../AffectToOrder';
import Comments from '../Comments';
import RelatedProduct from '../RelatedProduct';
import useStyles from './styles';
import SuccessDialog from '../SuccessDialog';
import RelatedList from '../../../ExternSearch/ArticleCard/RelatedList';
import CopimaRelatedProducts from './CopimaRelatedProducts';
import NoEquivalent from './NoEquivalent';
import ArticleCard from '../../../ExternSearch/ArticleCard';
import { getArticles } from '../../../ExternSearch/ExternSearchServices';
import RequestProduct from '../RequestProduct';
import TecDocCarsProduct from './TecDocVehicule/TecDocCarsProduct';

const DEPOTS = ['DO', 'BO'];

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'unset',
  },
}))(MuiDialogContent);

const positifValue = (value) => (value > 0 ? value : 0);
const StyledSelect = styled(Select)({
  background: '#F7F7F7 0% 0% no-repeat padding-box',
  borderRadius: 12,
  opacity: 1,
  height: '34px',
  border: 'none',
  '&:focus': {
    backgroundColor: '#fff',
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: '#000',
  },
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-selectMenu': {
    font: 'normal normal 600 14px Montserrat',
  },
});
const noFunc = () => null;
export default function ProductDetail({
  product: localProduct,
  handleClose,
  tabProduct = 0,
  isTecDoc = false,
  onHandleOpenProductDetail = noFunc,
}) {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const classes = useStyles();
  const image = React.createRef();
  const [tab, setTab] = useState(tabProduct);
  const [product, setProduct] = useState(localProduct);
  const [openTransferSuccess, setOpenTransferSuccess] = useState(false);
  const [openAffectOrder, setOpenAffectOrder] = useState(false);
  const [values, setValues] = useState({ quantity: 1 });
  const [relatedTecdocToCopima, setRelatedTecdocToCopima] = useState([]);
  const handleChangeValues = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const changeQuantity = (value) => (event) => {
    setValues({ ...values, quantity: parseInt(value || event.target.value, 10) });
  };
  const handleTransferProduct = async () => {
    try {
      await transferProduct({
        ...values,
        reference: product?.reference || product?.id,
        destination: values.from === 'DO' ? 'Derb Omar' : 'Bouskoura',
        source: values.from === 'DO' ? 'Bouskoura' : 'Derb Omar',
      });
      setOpenTransferSuccess(true);
    } catch (e) {
      toast.error('Erreur systéme');
    }
  };
  const handleOpenTransfer = () => {
    setTab(2);
    setValues({ quantity: 1 });
  };
  const handleOpenRequestProduct = () => {
    setTab(3);
    setValues({ quantity: 1 });
  };
  const handleCloseTransfer = () => {
    setOpenTransferSuccess(false);
  };
  const handleOpenAffectOrder = () => {
    setOpenAffectOrder(true);
  };
  const handleCloseAffectOrder = () => {
    setOpenAffectOrder(false);
    handleClose();
  };
  const handleClick = (po) => () => {
    setProduct(po);
  };
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const relatedProducts = useMemo(() => {
    const { products } = referencial;
    const relatedProductsN1 = products?.filter((po) => (product?.e || product?.equivalents
      ? (product?.e || product?.equivalents).split(';').includes(po.reference)
      : false));
    const relatedProductRefs = relatedProductsN1?.reduce(
      (previous, current) => previous.concat(current.e ? current.e.split(';') : []).unique(),
      product?.e || product?.equivalents ? (product?.e || product?.equivalents)?.split(';') : [],
    );
    return products
      ?.filter(
        (p) => p.reference !== (product.reference || product.id)
          && relatedProductRefs.includes(p.reference),
      )
      .unique();
  }, [referencial, product]);
  const relatedArticles = useMemo(() => localProduct?.oemNumbers || [], [localProduct]);
  const getRelatedTecDocForCopima = useCallback(async () => {
    const data = await getArticles(product.refT || product.refF, null, null);
    setRelatedTecdocToCopima(data?.articles || []);
  }, [product]);

  useEffect(() => {
    if (!isTecDoc) {
      getRelatedTecDocForCopima();
    }
  }, [isTecDoc]);
  const equivNotInDatabase = useMemo(() => {
    const { products } = referencial;
    const equivs = (product?.e || product?.equivalents || '').split(';');
    products?.forEach((po, index) => {
      if (equivs.includes(po.reference)) {
        equivs.splice(index, 1);
      }
    });
    return equivs;
  }, [referencial.products, product]);

  const equivTecDoc = useMemo(() => {
    if (isTecDoc) {
      return product?.oemNumbers
        ?.slice(0, 20)
        ?.map(({ articleNumber }) => articleNumber)
        .join(' ;');
    }
    return '';
  }, [product]);
  const refOrgTecdoc = useMemo(() => {
    if (isTecDoc && product?.oemNumbers?.length > 0) {
      return product.oemNumbers[0].articleNumber;
    }
    return '';
  }, [product]);
  const isValidTransfer = values.from && values.to && values.quantity;
  const disponible = useMemo(
    () => (product?.sD || product?.stockBouskoura
      ? product?.sB
        ? product?.sB - (product?.res || 0)
        : product?.stockBouskoura - (product?.res || 0)
      : 0)
      + (product?.sD || product?.stockDerbOmar
        ? product?.sD
          ? product?.sD - (product?.resD || 0)
          : product?.stockDerbOmar - (product?.resD || 0)
        : 0),
    [product],
  );
  const { state: { relatedList } = {} } = useContext(ExternSearchContext);
  return (
    <div>
      {openTransferSuccess && (
        <SuccessDialog
          handleClose={handleCloseTransfer}
          message="Votre demande a été bien envoyer"
          icon={SendIcon}
        />
      )}
      {openAffectOrder && <AffectToOrder product={product} handleClose={handleCloseAffectOrder} />}
      {!openTransferSuccess && !openAffectOrder && (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          scroll="body"
          fullWidth
          open
          PaperProps={{
            style: {
              background: '#F9FAFC 0% 0% no-repeat padding-box',
              border: '1px solid #C5C5C5',
              borderRadius: 24,
            },
          }}
          BackdropProps={{
            style: {
              background: '#245283 0% 0% no-repeat padding-box',
              boxShadow: '0px 12px 26px #0000000D',
              opacity: 0.67,
            },
          }}
        >
          <Box style={{ display: 'flex', padding: '16px 24px' }}>
            <Typography style={{ font: 'normal normal 600 24px Montserrat', flexGrow: 1 }}>
              {isTecDoc ? 'Detail du produit TecDoc' : 'Detail du produit'}
            </Typography>
            <IconButton
              style={{
                background: '#C5C5C5 0% 0% no-repeat padding-box',
                borderRadius: 24,
                opacity: 0.53,
              }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon style={{ color: '#707070' }} />
            </IconButton>
          </Box>
          <DialogContent>
            <Box style={{ display: 'flex', columnGap: 10, marginBottom: 5 }}>
              <Box style={{ position: 'relative' }}>
                <img
                  ref={image}
                  // eslint-disable-next-line no-return-assign
                  onError={() => (image.current.src = DEFAULT_PRODUCT)}
                  style={{ width: 365, height: 'auto', maxHeight: 362 }}
                  src={
                    isTecDoc
                      ? product?.img
                      : `http://media.copima.ma/products/${product?.img || product?.image}`
                  }
                  alt={product?.fId}
                />
                {(product?.iN === 1 || product?.isNew === 1) && (
                  <div
                    style={{
                      position: 'absolute',
                      width: 53,
                      backgroundColor: '#9FD766',
                      top: 16,
                      left: 16,
                      fontSize: 10,
                      textAlign: 'center',
                      color: 'white',
                      margin: 'auto',
                      padding: 3,
                    }}
                  >
                    <p>NOUVEAU</p>
                  </div>
                )}
                {(product?.iP === 1 || product?.isPromo === 1) && (
                  <div
                    style={{
                      position: 'absolute',
                      height: 16,
                      width: 53,
                      backgroundColor: '#FFC774',
                      top: 16,
                      left: 16,
                      fontSize: 10,
                      textAlign: 'center',
                      color: 'white',
                      padding: 3,
                    }}
                  >
                    <span>PROMO</span>
                  </div>
                )}
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Box>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 600 16px Montserrat',
                      color: '#000000',
                      marginRight: 14,
                    }}
                  >
                    REF
                  </Typography>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 600 16px Montserrat',
                      color: '#007BC6',
                      marginRight: 14,
                    }}
                  >
                    {product?.reference || product?.id}
                  </Typography>
                </Box>
                <Typography
                  style={{
                    font: 'normal normal 600 18px Montserrat',
                    color: '#000',
                    marginBottom: 10,
                  }}
                >
                  {product?.des || product?.designation}
                </Typography>
                {!isTecDoc && (
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    <div style={{ marginRight: 20 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                        }}
                      >
                        Prix Brut
                      </Typography>
                      <Typography
                        style={{
                          font: 'normal normal 600 25px Montserrat',
                          color: '#000',
                        }}
                      >
                        {`${
                          product?.p >= 0 ? product?.p?.toFixed(2) : product?.price?.toFixed(2)
                        } DH`}
                      </Typography>
                    </div>
                    <div style={{ marginRight: 20 }}>
                      <Typography
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                        }}
                      >
                        REMISE APPLIQUÉE
                      </Typography>
                      <Typography
                        style={{
                          font: 'normal normal 600 25px Montserrat',
                          color: '#000',
                        }}
                      >
                        {product?.r || product?.remise}
                        %
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                        }}
                      >
                        Prix Net
                      </Typography>
                      <Typography
                        style={{
                          font: 'normal normal 600 25px Montserrat',
                          color: '#000',
                        }}
                      >
                        {`${
                          product?.p >= 0
                            ? ((product?.p * (100.0 - product?.r)) / 100.0)?.toFixed(2)
                            : ((product?.price * (100.0 - product?.remise)) / 100.0)?.toFixed(2)
                        } DH`}
                      </Typography>
                    </div>
                  </div>
                )}
                <Box style={{ marginBottom: 6 }}>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 500 14px Montserrat',
                      color: '#000000',
                      marginRight: 14,
                    }}
                  >
                    REF ORG
                  </Typography>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 600 14px Montserrat',
                      color: '#007BC6',
                      fontWeight: 'bold',
                    }}
                  >
                    {isTecDoc ? refOrgTecdoc : product?.referenceOrigine || product?.rO}
                  </Typography>
                </Box>
                <Box style={{ marginBottom: 6 }}>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 500 14px Montserrat',
                      color: '#000000',
                      marginRight: 14,
                    }}
                  >
                    MARQUE
                  </Typography>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 600 14px Montserrat',
                      color: '#007BC6',
                      fontWeight: 'bold',
                    }}
                  >
                    {product?.bId || product?.brandName}
                  </Typography>
                </Box>
                <Box style={{ marginBottom: 6 }}>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 500 14px Montserrat',
                      color: '#000000',
                      marginRight: 14,
                      textTransform: 'uppercase',
                    }}
                  >
                    {isTecDoc ? "Réferences d'origines" : 'EQUIVALENTS'}
                  </Typography>
                  <Typography
                    display="inline"
                    style={{
                      font: 'normal normal 600 14px Montserrat',
                      color: '#007BC6',
                      fontWeight: 'bold',
                    }}
                  >
                    {isTecDoc ? equivTecDoc : equivNotInDatabase}
                  </Typography>
                </Box>
                {isTecDoc && (
                  <Box style={{ marginBottom: 6 }}>
                    <Typography
                      display="inline"
                      style={{
                        font: 'normal normal 500 14px Montserrat',
                        color: '#000000',
                        marginRight: 14,
                        transform: 'capitalize',
                      }}
                    >
                      EQUIVALENTS
                    </Typography>
                    <Typography
                      display="inline"
                      style={{
                        font: 'normal normal 600 14px Montserrat',
                        color: '#007BC6',
                        fontWeight: 'bold',
                      }}
                    >
                      {relatedList
                        .slice(0, 20)
                        .reduce((prev, curr) => (prev ? prev + ', ' + curr : curr), '')}
                    </Typography>
                  </Box>
                )}
                {!isTecDoc && (
                  <>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        DIMENSIONS et DIMENSIONS2
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {product?.d
                          ? product?.d + ' , ' + product.dimensions2
                          : product?.dimensions + ' ,' + product?.dimensions2}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        REMISE APPLICABLE
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {product?.r || product?.remise}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        Famille
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {product?.fId
                          ? referencial?.families.find((f) => product?.fId === f.id)?.name
                          : product?.familyName}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        Info supplémentaire
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {product?.eF || product?.extraFields}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        Jeux
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {product?.gameQuantity || ''}
                      </Typography>
                    </Box>
                    <Box style={{ marginBottom: 6 }}>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        STOCK
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                        }}
                      >
                        {'Bouskoura: '
                          + (Object.keys(product).includes('sB')
                            ? product?.sB
                            : product?.stockBouskoura)}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          margin: '0 22px',
                        }}
                      >
                        /
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {'Derb Omar: '
                          + (Object.keys(product).includes('sD')
                            ? product?.sD
                            : product?.stockDerbOmar)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 500 14px Montserrat',
                          color: '#000000',
                          marginRight: 14,
                        }}
                      >
                        Disponible
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {`Bouskoura: ${positifValue(
                          ((Object.keys(product).includes('sB')
                            ? product?.sB
                            : product?.stockBouskoura) || 0) - (product?.res || 0),
                        )}`}
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                          margin: '0 22px',
                        }}
                      >
                        /
                      </Typography>
                      <Typography
                        display="inline"
                        style={{
                          font: 'normal normal 600 14px Montserrat',
                          color: '#007BC6',
                          fontWeight: 'bold',
                        }}
                      >
                        {`Derb Omar: ${positifValue(
                          ((Object.keys(product).includes('sD')
                            ? product?.sD
                            : product?.stockDerbOmar) || 0) - (product?.resD || 0),
                        )}`}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  classes={{ root: classes.tabs }}
                  TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
                >
                  <Tab
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    label={isTecDoc ? 'Équivalent Catalogue TecDoc' : 'Équivalent Catalogue Copima'}
                    value={0}
                  />
                  <Tab
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    label={isTecDoc ? 'Équivalent Catalogue Copima' : 'Équivalent Catalogue TecDoc'}
                    value={-1}
                  />
                  {!isTecDoc && (
                    <Tab
                      classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        wrapper: classes.tabWrapper,
                      }}
                      label="Commentaires"
                      value={1}
                    />
                  )}
                  {!isTecDoc && (
                    <Tab
                      classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        wrapper: classes.tabWrapper,
                      }}
                      label="Véhicules Compatibles"
                      value={4}
                    />
                  )}
                </Tabs>
              </Box>
              <Box
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {!isTecDoc && (
                  <>
                    <IconButton
                      size="small"
                      style={{
                        background:
                          ' transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                        boxShadow: '0px 4px 10px #108CD650',
                        borderRadius: 12,
                        marginRight: 5,
                        height: 48,
                        width: 48,
                      }}
                      onClick={handleOpenAffectOrder}
                    >
                      <AddIcon style={{ color: '#FFF' }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 4px 10px #9B9B9B50',
                        border: '1px solid #EBEDED',
                        borderRadius: 12,
                        width: 48,
                        height: 48,
                        marginRight: 5,
                      }}
                      onClick={handleOpenTransfer}
                    >
                      <SyncAltIcon style={{ color: '#38485C' }} />
                    </IconButton>
                  </>
                )}
                <IconButton
                  size="small"
                  style={{
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: '0px 4px 10px #9B9B9B50',
                    border: '1px solid #EBEDED',
                    borderRadius: 12,
                    width: 48,
                    height: 48,
                  }}
                  onClick={handleOpenRequestProduct}
                >
                  <ShoppingCartOutlinedIcon
                    fontSize="small"
                    style={{ color: '#38485C' }}
                  />
                </IconButton>
              </Box>
            </Box>
          </DialogContent>
          {tab === 0 && (
            <Box className={classes.relatedContainer}>
              {!isTecDoc
                && relatedProducts?.map((po) => (
                  <RelatedProduct
                    product={po}
                    key={po?.id}
                    handleClick={handleClick(po)}
                    isArticle={isTecDoc}
                  />
                ))}
              {isTecDoc && relatedArticles?.length && (
                <RelatedList
                  list={relatedArticles}
                  classes={classes}
                  isArticle
                  saveArticles
                  onHandleOpenProductDetail={onHandleOpenProductDetail}
                />
              )}
              {!(isTecDoc ? relatedArticles : relatedProducts)?.length && <NoEquivalent />}
            </Box>
          )}
          {tab === -1
            && (isTecDoc ? (
              <CopimaRelatedProducts tecdocRef={product.searchedBy || product.reference} />
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  overflow: 'hidden',
                }}
                //  ref={contentWidth}
              >
                {relatedTecdocToCopima?.length > 0 ? (
                  relatedTecdocToCopima.map((article) => (
                    <ArticleCard
                      key={article.articleNumber}
                      imgs={article.images}
                      category=""
                      brand={article.mfrName}
                      name={article.genericArticles}
                      desc={article.articleText}
                      original={article}
                      onHandleOpenProductDetail={onHandleOpenProductDetail}
                    />
                  ))
                ) : (
                  <Box style={{ marginTop: 100, marginBottom: 100, width: '100%' }}>
                    <NoEquivalent />
                  </Box>
                )}
              </Box>
            ))}
          {!isTecDoc && tab === 1 && <Comments product={product} classes={classes} />}
          {tab === 2 && (
            <Box style={{ padding: '25px 20px', backgroundColor: '#FFF' }}>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant="h6"
                  style={{
                    font: 'normal normal 600 25px Montserrat',
                    color: '#000000',
                    marginBottom: 15,
                    minWidth: '50%',
                    flexShrink: 0,
                  }}
                >
                  Direction du transfert:
                </Typography>
                {values.quantity > disponible && (
                <Typography style={{ color: 'red', flexGrow: 1, font: 'normal normal 400 13px Montserrat' }}>
                  La quantité saisie est supérieure à la quantité disponible
                </Typography>
                )}
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <FormControl variant="outlined" style={{ marginRight: 15, width: 200 }}>
                  <InputLabel className={classes.labelSelect}>De...</InputLabel>
                  <StyledSelect
                    value={values.from}
                    onChange={handleChangeValues}
                    label="De..."
                    margin="dense"
                    name="from"
                    IconComponent={ExpandMoreIcon}
                  >
                    {DEPOTS.filter((d) => d !== values.to).map((depot) => (
                      <MenuItem value={depot}>{depot}</MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <FormControl variant="outlined" style={{ marginRight: 15, width: 200 }}>
                  <InputLabel className={classes.labelSelect}>À...</InputLabel>
                  <StyledSelect
                    value={values.to}
                    margin="dense"
                    onChange={handleChangeValues}
                    label="À..."
                    name="to"
                    size="small"
                    IconComponent={ExpandMoreIcon}
                  >
                    {DEPOTS.filter((d) => d !== values.from).map((depot) => (
                      <MenuItem value={depot}>{depot}</MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <Box style={{ flexGrow: 1, paddingLeft: 10, alignItems: 'center' }}>
                  <IconButton
                    style={{
                      background:
                        'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                      boxShadow: '0px 4px 10px #108CD650',
                      borderRadius: 12,
                      color: '#FFF',
                      marginRight: 10,
                      padding: 7,
                    }}
                    onClick={values.quantity - 1 > 0 ? changeQuantity(values.quantity - 1) : null}
                    size="large"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={values.quantity}
                    error={values.quantity > disponible}
                    name="quantity"
                    onChange={handleChangeValues}
                    style={{ width: 80, borderRadius: 16 }}
                    InputProps={{
                      classes: { input: classes.input, root: classes.rootQuantity },
                    }}
                  />
                  <IconButton
                    type="number"
                    inputProps={{ min: 0 }}
                    onClick={changeQuantity(values.quantity + 1)}
                    style={{
                      background:
                        'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                      boxShadow: '0px 4px 10px #108CD650',
                      borderRadius: 12,
                      color: '#FFF',
                      marginLeft: 10,
                      padding: 7,
                    }}
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box style={{ margin: '10px 0' }}>
                <TextField
                  fullWidth
                  id="transfer-commentaire"
                  label="Commentaire"
                  variant="outlined"
                  name="comment"
                  multiline
                  rows={5}
                  onChange={handleChangeValues}
                  value={values.comment}
                  InputProps={{
                    classes: {
                      root: classes.inputContainer,
                      input: classes.comment,
                      notchedOutline: classes.notched,
                    },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                  style={{ background: '#F7F7F7 0% 0% no-repeat padding-box', borderRadius: 12 }}
                />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row-reverse', columnGap: 10 }}>
                <Button
                  variant="contained"
                  onClick={handleTransferProduct}
                  disabled={!isValidTransfer}
                  style={{
                    background: '#007BC6 0% 0% no-repeat padding-box',
                    border: '1px solid #007BC6',
                    color: '#FFF',
                    font: 'normal normal bold 14px Montserrat',
                    borderRadius: 12,
                  }}
                >
                  ENVOYER LA DEMANDE DU TRANSFERT
                  <SendIcon style={{ marginLeft: 10, color: '#FFF' }} />
                </Button>
                <Button
                  onClick={handleClose}
                  variant="text"
                  style={{
                    font: 'normal normal bold 1dpx Montserrat',
                    color: '#38485C',
                  }}
                >
                  ANULLER
                </Button>
              </Box>
            </Box>
          )}
          {tab === 3 && (
            <RequestProduct
              classes={classes}
              openSuccess={setOpenTransferSuccess}
              product={product}
              handleClose={handleClose}
            />
          )}
          {tab === 4 && (
            <TecDocCarsProduct
              productRefTecDoc={product.refT}
            />
          )}
        </Dialog>
      )}
    </div>
  );
}
