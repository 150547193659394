import { Box, Typography, IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { format } from 'date-fns';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import HelpIcon from '@mui/icons-material/Help';
import DialogCmp from 'Shared/Dialog';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import PropositionActions from './Actions';
import { removePropositions, validatePropositions } from './PropositionServices';

const PropositionItem = ({
  reference,
  name,
  status,
  creatorId,
  date,
  productsTotal,
  quantities,
  onUpdateProposition,
  setLoader,
  reload,
}) => {
  const classes = useStyles();
  const [openedAction, setOpenedAction] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const ref = useRef();
  const history = useHistory();
  const onOpenActions = (event) => {
    event.stopPropagation();
    setOpenedAction(!openedAction);
  };
  const navigateToDetail = () => {
    history.push(`proposition/add?reference=${reference}&name=${name}`);
  };
  useOnClickOutside(ref, onOpenActions);
  const onSubmitValidation = async () => {
    setOpenValidation(false);
    setLoader(true);
    await validatePropositions(reference);
    onUpdateProposition(reference, 'VALIDATED');
    setLoader(false);
    setOpenSuccess(true);
  };
  const onOpenRemove = (event) => {
    event.stopPropagation();
    setOpenRemove(!openRemove);
  };
  const onHandleRemove = async (event) => {
    try {
      setLoader(true);
      await removePropositions(reference);
      onOpenRemove(event);
      reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openRemove}
        handleClose={onOpenRemove}
        onHandleSubmit={onHandleRemove}
        labelKo="Non"
        labelOk="Oui"
        styleOk={{ background: '#F86060', boxShadow: '0px 4px 10px #BEBEBE50' }}
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box className={classes.successCheck} style={{ backgroundColor: '#F86060' }}>
            <DeleteIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            Êtes-vous sûr de vouloir Supprimer la proposition ?
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        onHandleSubmit={() => setOpenSuccess(false)}
        labelKo="Ok"
        labelOk="Voir La Proposition"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box className={classes.successCheck}>
            <CheckIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            {`Votre Proposition ${name} a été bien valider`}
          </Typography>
        </Box>
      </DialogCmp>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openValidation}
        handleClose={() => setOpenValidation(false)}
        onHandleSubmit={onSubmitValidation}
        labelKo="Annuler"
        labelOk="Valider"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box className={classes.successCheck}>
            <HelpIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography className={classes.successMsg}>
            Êtes-vous sûr de vouloir Valider la proposition?
          </Typography>
        </Box>
      </DialogCmp>
      <Box className={classes.proposition} onClick={navigateToDetail}>
        <Box>
          <Typography className={classes.propositionTitle}>{name}</Typography>
          <Typography className={classes.propositionSubTitle}>
            {`Propositions N°${reference}`}
          </Typography>
        </Box>
        <Typography
          variant="span"
          className={[
            classes.propositionStatus,
            status === 'CREATED' && classes.propositionCreated,
          ]}
        >
          {status}
        </Typography>
        <Typography className={classes.propositionTitle}>
          Date :
          <Typography variant="span" className={classes.propositionBlue}>
            {` ${date && format(date, 'dd/MM/yyy')}`}
          </Typography>
        </Typography>
        <Typography className={classes.propositionTitle}>
          Nbr de produits:
          <Typography variant="span" className={classes.propositionBlue}>
            {` ${productsTotal}`}
          </Typography>
        </Typography>
        <Typography className={classes.propositionTitle}>
          Qté total:
          <Typography variant="span" className={classes.propositionBlue}>
            {` ${quantities}`}
          </Typography>
        </Typography>
        <Box className={classes.actions}>
          <IconButton aria-label="actions" onClick={onOpenActions} size="large">
            <MoreHorizIcon />
          </IconButton>
          {openedAction && (
          <Box className={classes.actionsPop} ref={ref}>
            <PropositionActions
              id={reference}
              name={name}
              status={status}
              setOpenValidation={setOpenValidation}
              setOpenRemove={onOpenRemove}
              creatorId={creatorId}
            />
          </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PropositionItem;
