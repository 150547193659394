import api, {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getPaymentDocuments = async (sortParams, filters = {}) => {
  const url = `/ci/api/reglements/documents?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  const { data } = await securePost(url, filters);
  return data;
};
export const attachDocumentsToInvoices = async (body) => {
  const url = '/ci/api/reglements/attach';
  const { data } = await securePost(url, body);
  return data;
};

export const updateOrderPaidAmount = async (orderId, paidAmount = 0) => {
  const url = `/ci/api/reglements/${orderId}/order`;
  const { data } = await securePut(url, { paidAmount });
  if (data.statusCode && data.statusCode !== '000') {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const removeDocument = async (paymentId) => {
  const url = `/ci/api/reglements/documents/${paymentId}`;
  const { data } = await secureDelete(url);
  if (data.statusCode && data.statusCode !== '000') {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const detachDocumentsToInvoices = async (echeance) => {
  const url = `/ci/api/reglements/unattach?echeance=${echeance}`;
  const { data } = await securePost(url);
  if (data.statusCode && data.statusCode !== '000') {
    throw new Error(data.statusLabel);
  }
  return data;
};
export const updateReglementDocument = async (payment) => {
  const url = `/ci/api/reglements/${payment.id}`;
  const { data } = await securePut(url, payment);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};
export const getReglementDetailsById = async (id, { page = 0, size = 10 }) => {
  const url = `/ci/api/reglements/${id}/details?page=${page}&size=${size}`;
  const { data } = await secureGet(url);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const getUnpaidOrders = async (clientCode) => {
  const url = `/ci/api/reglements/unpaidOrders?client=${clientCode}`;
  const { data } = await secureGet(url);
  return data;
};
export const getReglementById = async (id) => {
  const url = `/ci/api/reglements/documents/${id}`;
  const { data } = await secureGet(url);
  if (data.statusCode) {
    throw new Error(data.statusLabel);
  }
  return data;
};

export const getReglementKpis = async (filters = {}) => {
  const url = '/ci/api/reglements/kpis';
  const { data } = await securePost(url, filters);
  return data;
};

export const getOrders = async (sortParams, filters = {}, conf, isControl = false) => {
  let url = `/ci/api/${
    !isControl ? 'GetOrderHistorybyCriteria' : 'GetOrderHistorybyCriteriaSavControl'
  }?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (![null, undefined, ''].includes(filters[key])) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url, conf);
  return data;
};

export const downloadBL = async (id, bl, openInTab = false) => {
  const url = `${bl ? '/ci/api/getBl/' : '/ci/api/getFacture/'}${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  if (openInTab) {
    window.open(fileURL, '_blank');
  } else {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = id;
    link.click();
    link.remove();
  }
  return data;
};

export const getCommerciaux = async () => {
  const url = '/ci/api/GetCommerciaux';
  const { data } = await securePost(url);
  return data;
};
